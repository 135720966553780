import type React from "react";
import type { ImageKey } from "./images";

function latLngFromUrl(url: string) {
  const splitUrl = url.split("!3d");
  const latLong = splitUrl[splitUrl.length - 1].split("!4d");

  let lng;
  if (latLong.indexOf("?") !== -1) {
    lng = parseFloat(latLong[1].split("\\?")[0]);
  } else {
    lng = parseFloat(latLong[1]);
  }
  const lat = parseFloat(latLong[0]);

  if (isNaN(lat)) {
    throw new Error("lat doesn't match?");
  } else if (isNaN(lng)) {
    throw new Error("long doesn't match?");
  } else {
    return { lat: lat, lng: lng };
  }
}

export const torontoStart = latLngFromUrl(
  "https://www.google.com/maps/place/Park+Property+Management+Inc/@43.668435,-79.3850485,17z/data=!3m1!4b1!4m6!3m5!1s0x882b350a10f02849:0x749b04eb70d567d!8m2!3d43.668435!4d-79.3828598!16s%2Fg%2F11lglr823m"
);

export type LocationChunk = { lat: number; lng: number };
type MediaChunk = {
  media: ImageKey;
  alt?: string;
  overrideWidth?: number;
  overlay?: ImageKey;
  extraStyles?: React.CSSProperties;
};
export type ChunkItem = { text: string } | MediaChunk | LocationChunk;

const IntroSymbol = Symbol("intro");
type SingleDigit = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
type DateString = `${
  | "January"
  | "February"
  | "March"
  | "April"
  | "May"
  | "June"
  | "July"
  | "September"
  | "August"
  | "October"
  | "November"
  | "December"} ${
  | `0${Exclude<SingleDigit, "0">}`
  | `1${SingleDigit}`
  | `2${SingleDigit}`
  | "30"
  | "31"}, 20${"19" | "20" | "21" | "22" | "23" | "24"}`;
type BlogItem = {
  published: boolean;
  title: string;
  createdDate: DateString;
  updatedDate?: DateString;
  eventDate: DateString | [DateString, DateString] | typeof IntroSymbol;
  chunks: Array<ChunkItem | Array<ChunkItem>>;
  adjustMapPadding?: number;
  shiftMapDown?: number;
};

export const allBlogs: Array<BlogItem> = [
  {
    published: true,
    title: "Intro",
    createdDate: "April 05, 2023",
    eventDate: IntroSymbol,
    chunks: [
      {
        text: `
          👋 Hello, I'm starting this really basic blog to share a little bit of my adventures with this whole van business.
          I'm starting this pretty late, so I'll try to do some summary posts about the trip so far (Toronto -> Halifax -> Vancouver Island)
          but then i will try to post more regularly as I begin my trip north from Vancouver to Tuktoyaktuk, the farthest north you can drive in Canada!
        `,
      },
    ],
  },
  {
    published: true,
    title: "In the beginning",
    createdDate: "April 06, 2023",
    eventDate: ["December 02, 2019", "September 01, 2020"],
    chunks: [
      {
        text: "It all started when I finally pulled the trigger and bought my van, a 2019 Ford Transit cargo van!",
      },
      torontoStart,
      [
        { media: "20191202VernTiktok" },
        {
          media: "20191202Img20191202130428",
          alt: "Vern parked on side of snowy road.",
        },
      ],
      {
        text: "I took Vern back to the family farm, completely empty and ready for building :)",
      },
      latLngFromUrl(
        "https://www.google.com/maps/place/539+Maple+Rd,+Odessa,+ON+K0H+2H0/@44.3072434,-76.7350027,17z/data=!3m1!4b1!4m6!3m5!1s0x4cd2b68523a6483f:0x67cb786427619ea3!8m2!3d44.3072434!4d-76.732814!16s%2Fg%2F11cpgbj9w1"
      ),
      [
        { media: "20191202VanInGarage", alt: "Vern in garage" },
        { media: "20191202EmptyBack", alt: "Empty Vern back" },
        {
          media: "20191202EmptyFront",
          alt: "Empty Vern front with first cargo load of case of beer",
        },
      ],
      {
        text: "After a winter spent in Toronto, and a trip to San Francisco to interview at Apollo, covid was in full swing and progress was being made",
      },
      [
        { media: "20191202BedPlatform", alt: "bed platform progress" },
        { media: "20191202FunnyFace", alt: "Me... making a face" },
        {
          media: "20191202FoldingBed",
          alt: "bed platform folding to reveal counter",
        },
      ],
      {
        text: "Things started to really come together with the final install of the flooring, wall panels and ceiling panels with pot lights and the start of the electrical setup (including the solar panels)",
      },
      [
        {
          media: "20191202FloorAndWalls",
          alt: "Vern walls and ceiling installed",
        },
        { media: "20191202Solar", alt: "Solar panels on Vern's roof" },
        { media: "20191202Electrical", alt: "electrical components in Vern" },
      ],
      {
        text: "My first time boondocking in Vern was a trip to Unique appliances in Oakville to pick of my fridge/freezer. After I picked it up, I was able to finish building out the sink cabinet, mounted the fridge on slides under the sink and installed the sink and tap! Vern wasn't finished, but I now had running water, a bed, and a fridge. Enough for my first long term trip before the end of fall.",
      },
      latLngFromUrl(
        "https://www.google.com/maps/place/Unique+Appliances/@43.4154129,-79.7753197,12z/data=!4m10!1m2!2m1!1sunique!3m6!1s0x882b5df007bfc7bb:0x141d9266fbb50d5d!8m2!3d43.4154129!4d-79.7294311!15sCgZ1bmlxdWWSAQxtYW51ZmFjdHVyZXLgAQA!16s%2Fg%2F1tfnz6t5"
      ),
      [
        { media: "20191202SinkAndTap", alt: "Sink and tap installed in Vern" },
        {
          media: "20191202FirstBoondocking",
          alt: "a pebble beach with my coffee cup at the first place I boondocked",
        },
        {
          media: "20191202FridgeOnSlide",
          alt: "Fridge installed on slides under sink cabinet",
        },
      ],
    ],
  },
  {
    published: true,
    title: "The first trip",
    createdDate: "April 11, 2023",
    eventDate: ["September 04, 2020", "September 24, 2020"],
    chunks: [
      {
        text: "Let the adventure begin! Or at least the first trial of adventure, with winter approaching, and Vern semi liveable, I decided to take a trip north before settling in for another winter. I started by heading north, my first few nights being at a boat ramp, abandoned airstrip, and a beautiful site on crown land.",
      },
      [
        { media: "20200904BoatLaunch", alt: "Vern parked at boat launch" },
        { media: "20200904Airstrip", alt: "Vern parked on abandoned airstrip" },
        {
          media: "20200904CrownLand",
          alt: "Vern parked by lake on crown land",
        },
      ],
      latLngFromUrl(
        "https://www.google.com/maps/place/44%C2%B049'16.5%22N+77%C2%B013'28.0%22W/@44.821267,-77.2245372,20.8z/data=!4m10!1m5!3m4!2zNDTCsDQ5JzE3LjAiTiA3N8KwMTMnMjcuOCJX!8m2!3d44.82138!4d-77.22439!3m3!8m2!3d44.821261!4d-77.22445"
      ),
      latLngFromUrl(
        "https://www.google.com/maps/place/45%C2%B001'52.0%22N+77%C2%B015'48.8%22W/@45.0181901,-77.2817499,14.09z/data=!4m10!1m5!3m4!2zNDTCsDQ5JzE3LjAiTiA3N8KwMTMnMjcuOCJX!8m2!3d44.82138!4d-77.22439!3m3!8m2!3d45.031113!4d-77.263555"
      ),
      latLngFromUrl(
        "https://www.google.com/maps/place/45%C2%B014'40.9%22N+76%C2%B052'48.1%22W/@45.2447081,-76.8803311,17z/data=!4m4!3m3!8m2!3d45.2447!4d-76.880017"
      ),
      {
        text: "After this little trip, I was pretty confident in my setup so I started to head north. The plan was to go explore northern ontario until it got too cold and I needed to head back for the winter. I spent some time Killarney Provincial Park which was absolutely beautiful.",
      },
      [
        { media: "20200904Hike3", alt: "rusty old car in woods" },
        { media: "20200904Hike4", alt: "board walk over lake" },
      ],
      [
        { media: "20200904Hike5", alt: "rolling hills of trees with cliff" },
        {
          media: "20200904Hike6",
          alt: "lake",
        },
      ],
      latLngFromUrl(
        "https://www.google.com/maps/place/ON-637,+Killarney,+ON+P0M+2A0/@46.0155171,-81.3754987,17z/data=!3m1!4b1!4m9!1m2!2m1!1skillarney+ontario+parks!3m5!1s0x4d2e71c35422009d:0xe65015caba84f960!8m2!3d46.0155171!4d-81.3754987!16s%2Fg%2F11ry3rmz3r"
      ),
      [
        {
          text: "I carried on and stumbled into Sudbury where I drove past Canada's largest mural. It was an old hospital that was painted as part of a festival",
        },
        {
          media: "20200904Sudbury",
          alt: "old abandoned building painted as a giant mural",
        },
      ],
      latLngFromUrl(
        "https://www.google.com/maps/place/Canada's+Largest+Mural/@46.4878305,-81.0030576,15z/data=!4m10!1m2!2m1!1ssudbury+mural!3m6!1s0x4d2fab929a98a469:0x8ff604e4d0145830!8m2!3d46.4795117!4d-80.9905162!15sCg1zdWRidXJ5IG11cmFskgESdG91cmlzdF9hdHRyYWN0aW9u4AEA!16s%2Fg%2F11h7_r0yn2"
      ),
      {
        text: "I stayed out on some crown land near Sudbury and had some delightful waffles with wild blueberries.",
      },
      [
        { media: "20200904Prison14", alt: "" },
        { media: "20200904Waffles", alt: "" },
      ],
      {
        text: "I noticed this abandoned prison on google maps and had to hike in to check it out.",
      },
      [
        { media: "20200904Prison1", alt: "" },
        { media: "20200904Prison2", alt: "" },
        { media: "20200904Prison3", alt: "" },
      ],
      [
        { media: "20200904Prison4", alt: "" },
        { media: "20200904Prison5", alt: "" },
        { media: "20200904Prison6", alt: "" },
      ],
      [
        { media: "20200904Prison8", alt: "" },
        { media: "20200904Prison9", alt: "" },
        { media: "20200904Prison15", alt: "" },
      ],
      [
        { media: "20200904Prison7", alt: "" },
        { media: "20200904Prison10", alt: "" },
        { media: "20200904Prison11", alt: "" },
      ],
      [
        { media: "20200904Prison12", alt: "" },
        { media: "20200904Prison13", alt: "" },
      ],
      latLngFromUrl(
        "https://www.google.com/maps/place/Camp+Bison+Prison+Farm/@46.2322596,-80.9233031,12.88z/data=!4m9!1m2!2m1!1sburwash+prison!3m5!1s0x4d2ef3cdc6bc003d:0x262ab347711fac3d!8m2!3d46.2204648!4d-80.9068394!15sCg5idXJ3YXNoIHByaXNvbpIBBnByaXNvbuABAA"
      ),
    ],
  },
  {
    published: true,
    title: "Manitoulin Island",
    createdDate: "April 12, 2023",
    eventDate: ["September 27, 2020", "October 03, 2020"],
    chunks: [
      {
        text: "After Sudbury and exploring the abandoned prison, I continued on. The next stop, Manitoulin Island, an absolutely beautiful place. I got some local bears and chocolates, and a more surprising discovery while I was driving I saw a saw for The Burt Farm Country Meats. I obviously had to stop by and got some sausages and burger patties (not pictured)",
      },
      [
        { media: "20200927Img20200927184241" },
        { media: "20200927Img20200927144638" },
        { media: "20200927Img20200927184328" },
      ],
      latLngFromUrl(
        "https://www.google.com/maps/place/Burt+Farm+Country+Meats/@45.7816653,-83.0556977,9.46z/data=!4m7!3m6!1s0x4d3226dfe0304da5:0xa43122ab664e33a8!8m2!3d45.9150773!4d-82.3678245!15sChhidXJ0IGZhbWlseSBjb3VudHJ5IG1lYXRaGiIYYnVydCBmYW1pbHkgY291bnRyeSBtZWF0kgENZ3JvY2VyeV9zdG9yZZoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VOaE1sbElhV1ZCRUFF4AEA!16s%2Fg%2F11f5htsn9q?coh=164777&entry=tt&shorturl=1"
      ),
      {
        text: "The island has lots of beautiful little hikes. I found one that had a bunch of art installations along it, a lovely waterfall and another rusty car.",
      },
      [
        { media: "20200927Img20200927125312", alt: "" },
        { media: "20200927Img20200927130502", alt: "" },
        { media: "20200927Img20200927131639", alt: "" },
      ],
      {
        text: "One of the hikes that I kept seeing people talking about was Cup and Saucer, I spent a while waiting for some hopefully good weather. The weather didn't really improve so I did it on a rainy day and it still did not disappoint.",
      },
      [
        { media: "20200927Img20200930075417", alt: "" },
        { media: "20200927Img20200930080303", alt: "" },
        { media: "20200927Img20200930080547", alt: "" },
      ],
      latLngFromUrl(
        "https://www.google.com/maps/place/Cup+and+Saucer+Nature+Reserve/@45.8526867,-82.1322704,14.28z/data=!4m6!3m5!1s0x4d321ca195d9fb4b:0x79aae3c394386559!8m2!3d45.8532714!4d-82.1141922!16s%2Fg%2F11gbl2fm1l?coh=164777&entry=tt&shorturl=1"
      ),
      {
        text: "Last stop on the island, Misery Bay and of course another rusting car. I hope to be back soon!",
      },
      [
        { media: "20200927Img20201003163401", alt: "" },
        { media: "20200927Img20200930082214", alt: "" },
        { media: "20200927Img20201003132651", alt: "" },
      ],
      latLngFromUrl(
        "https://www.google.com/maps/place/Misery+Bay+Provincial+Nature+Reserve,+Evansville,+ON+P0P+1E0/@45.7926977,-82.7389875,14z/data=!3m1!4b1!4m6!3m5!1s0x4d33cdc90bbf090f:0x3575d12047ebefe7!8m2!3d45.7921839!4d-82.7354934!16s%2Fg%2F11bvtjg7yq?coh=164777&entry=tt&shorturl=1"
      ),
    ],
  },
  {
    published: true,
    title: "Lake Superior National Park",
    createdDate: "April 13, 2023",
    eventDate: ["October 04, 2020", "October 16, 2020"],
    chunks: [
      {
        text: "After Manitoulin, I continued heading towards Lake Superior National Park, I wanted to at least make it there before turning back around. On the way I made a small stop in my friends home town, Elliot Lake. ",
      },
      [
        { media: "20201004Img20201004122841", alt: "" },
        { media: "20201004Img20201004141957", alt: "" },
        { media: "20201004Img20201004175904", alt: "" },
      ],
      latLngFromUrl(
        "https://www.google.com/maps/place/Elliot+Lake+Fire+Tower/@46.3874648,-82.6306975,15z/data=!3m1!4b1!4m6!3m5!1s0x4d310ee4c152caa1:0xfe8dff23a537ffbe!8m2!3d46.3874653!4d-82.6204192!16s%2Fg%2F11c1q83z99"
      ),
      [
        { media: "20201004Img20201005080924", alt: "" },
        { media: "20201004Img20201005082638", alt: "" },
        { media: "20201004Img20201005082701", alt: "" },
      ],
      latLngFromUrl(
        "https://www.google.com/maps/place/Batchawana+Falls/@46.9361852,-84.5424776,12.15z/data=!4m15!1m8!3m7!1s0x4d37e73685ad0d73:0x8b84012f5cc52957!2sBatchawana+Bay,+ON+P0S+1A0!3b1!8m2!3d46.9279579!4d-84.605911!16s%2Fm%2F05_5wf6!3m5!1s0x4d37dfc76abe3cf9:0xc9dffac7e9791d5a!8m2!3d46.9871789!4d-84.524314!16s%2Fg%2F11gdtbj688"
      ),
      [
        { media: "20201004Img20201005082708", alt: "" },
        { media: "20201004Img20201008185530", alt: "" },
      ],
      { lat: 47.05779, lng: -84.7616 },
      {
        text: "On the way I also did this hike, which had a staircase that just kept going.",
      },
      [
        { media: "20201004Img20201010133506", alt: "" },
        { media: "20201004Img20201010133521", alt: "" },
        { media: "20201004Img20201010133548", alt: "" },
        { media: "20201004Img20201010133626", alt: "" },
      ],
      {
        text: "While staying in the area, I found this beautiful free campsite right on the lake. I stayed here quite a few nights (and I would be back next year).",
      },
      [
        { media: "20201004Img20201010165528", alt: "" },
        { media: "20201004Img20201010185958", alt: "" },
        { media: "20201004Img20201011073239", alt: "" },
      ],
      [
        { media: "20201004Img20201011091005", alt: "" },
        { media: "20201004Img20201011103611", alt: "" },
        { media: "20201004Img20201011181125", alt: "" },
      ],
      {
        text: "On the way I also found this abandoned gas station which had open of the pumps still standing and opened up so you could see what was inside.",
      },
      [
        { media: "20201004Img20201011160909", alt: "" },
        { media: "20201004Img20201011161135", alt: "" },
        { media: "20201004Img20201011161159", alt: "" },
      ],
      {
        text: "And I'll end this post with a stop in a gas station shower, up to this point it had mostly been campground showers, but this was the first time I had the luxury of showering in a gas station, pictured is the lovely imported marble :)",
      },
      [
        { media: "20201004Pxl20201016140040924", alt: "" },
        { media: "20201004Pxl20201016140045745", alt: "" },
      ],
    ],
  },
  {
    published: true,
    title: "Fall build updates",
    createdDate: "April 14, 2023",
    eventDate: ["October 31, 2020", "November 20, 2020"],
    chunks: [
      { text: "I arrived back in Odessa and fall was truly falling." },
      [
        { media: "20201031Pxl20201031132337115" },
        { media: "20201031Pxl20201031165745183", alt: "" },
      ],
      {
        text: "One of the first things I added was a bench/storage. It was severely  needed.",
      },
      [
        { media: "20201031Pxl20201111195658036", alt: "" },
        { media: "20201031Pxl20201111195722196", alt: "" },
      ],
      {
        text: "It was getting awfully cold, so I had the wood stove going in the garage. I was fueling it with some sawdust pucks so there were fun trips over to the barn to fill up my bucket :D",
      },
      [
        { media: "20201031Pxl20201115210303029", alt: "" },
        { media: "20201031Pxl20201115234539724", alt: "" },
        { media: "20201031Pxl20201115223643119", alt: "" },
      ],
      {
        text: "A little bit of a rush trying to get stuff done before it got way to cold and I headed back to toronto for the winter, I was applying finish in the greene houses by headlamp.",
      },
      [
        { media: "20201031Pxl20201118042219540", alt: "" },
        { media: "20201031Pxl20201120025804847", alt: "" },
        { media: "20201031Pxl20201120025817459Mp", alt: "" },
      ],
      {
        text: "And I finally got the upper cabinet and cubbies installed! It's time to let Vern rest, and head to Toronto.",
      },
      [
        { media: "20201031Pxl20201121020932326", alt: "" },
        { media: "20201031Pxl20201121021036068", alt: "" },
      ],
    ],
  },
  {
    published: true,
    title: "A winter waiting",
    createdDate: "April 15, 2023",
    eventDate: ["November 22, 2020", "February 22, 2021"],
    chunks: [
      {
        text: "I spent the winter in Toronto with Adrian and Sennah, we got up to no good... which mostly meant making food.",
      },
      [
        { media: "20201122Pxl20201122193457415", alt: "" },
        { media: "20201122Pxl20201211162242943", alt: "" },
        { media: "20201122Pxl20210102223858998", alt: "" },
      ],
      [
        { media: "20201122Pxl20201122231338014", alt: "" },
        { media: "20201122Pxl20210207225035939", alt: "" },
        { media: "20201122Pxl20201211230247868", alt: "" },
        { media: "20201122Pxl20201209223336600", alt: "" },
      ],
    ],
  },
  {
    published: true,
    title: "A return to Vern",
    createdDate: "April 16, 2023",
    eventDate: ["March 05, 2021", "June 17, 2021"],
    chunks: [
      {
        text: "Vern waking up from hibernation, so much excitement! I came back to Odessa with the intention of finishing up a few last build items, and then be ready to start heading east once it warms up and lock downs are lifted.",
      },
      [
        { media: "20210305Pxl20210305182320965" },
        { media: "20210305Dsc00013" },
      ],
      {
        text: "Main things left were insulating the doors, making a drawer for under the sink, a back for the bench, and then converting a hot water heater to 12v. I also got some winder inserts so I can add some ventilation in the front without letting bugs or rain in.",
      },
      [
        { media: "20210305Pxl20210313213831408", alt: "" },
        { media: "20210305Pxl20210319230233167", alt: "" },
        { media: "20210305Pxl20210321143949983Mp", alt: "" },
        { media: "20210305Pxl20210531221450377", alt: "" },
      ],
      {
        text: "Found new homes for some of the most important items onboard, a snake plant Kari gave me and Lego Vern from Adrian.",
      },
      [
        { media: "20210305Pxl20210412172717497", alt: "" },
        { media: "20210305Pxl20210708224547250" },
      ],
      {
        text: "Right about when I was ready to go, we went into a deeper lockdown and crossing the provincial borders wasn't an option, and even ontario locked down further. After being stuck in Odessa for awhile, I did a little escape to near Algonquin Park. It was good to try things out after the improvements but I was itching to get going! Hopefully my next post will be me heading east :)",
      },
      [
        { media: "20210305Pxl20210510234252284", alt: "" },
        { media: "20210305Pxl20210513002001990Mp", alt: "" },
        { media: "20210305Pxl20210605224449586", alt: "" },
      ],
      latLngFromUrl(
        "https://www.google.com/maps/place/Maynooth,+ON/@45.2044683,-77.9496555,12.66z/data=!4m6!3m5!1s0x4cd46a83fe862e81:0x3a896c7f969eb3d3!8m2!3d45.2309047!4d-77.9386396!16zL20vMGZkNzZu"
      ),
    ],
  },
  {
    published: true,
    title: "And east I go!",
    createdDate: "April 16, 2023",
    eventDate: ["June 19, 2021", "July 24, 2021"],
    chunks: [
      {
        text: "And off I go! I've booked my entry into New Brunswick and Nova Scotia, it will be mostly a quick trip through Quebec and New Brunswick to not risk not making it to Nova Scotia before anymore lock downs.",
      },
      [
        {
          media: "20210619F529034A162B4844B499E8906070Defd1105C",
        },
        { media: "2021061969B1BeceD5F14F6CA5B5E0C02C9036Bb1105C" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Halte+routi%C3%A8re+de+Cap-St-Ignace/@47.0310892,-70.4693506,17z/data=!3m1!4b1!4m6!3m5!1s0x4cbed5e9be64007d:0xe3719efce5f68892!8m2!3d47.0310892!4d-70.4693506!16s%2Fg%2F11k742nsbp`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Miramichi,+NB/@47.01605,-65.4787445,11z/data=!3m1!4b1!4m6!3m5!1s0x4ca1f5385602b0a5:0x94c72c35d46ab43d!8m2!3d47.0295709!4d-65.5059061!16zL20vMDE2eDE1?coh=164777&entry=tt&shorturl=1`
      ),
      {
        text: "On the way I still had time for a stop in some beautiful places, including Kouchibouguac National Park.",
      },
      [
        { media: "20210619D6Dd9065946E4D43Ade664B3085Adc111105C" },
        { media: "20210619Boardwalk" },
        { media: "20210619Spiral" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Kouchibouguac+National+Park/@46.9468902,-65.3221135,9.63z/data=!4m6!3m5!1s0x4ca1b70aa5ecfc09:0xeab90566e8c7347d!8m2!3d46.8103391!4d-64.9611539!16zL20vMDFkdDkz`
      ),
      [
        {
          text: "Of course I had to stop in Alma on my way to Bay of Fundy National Park for a lobster roll.",
        },
        { media: "20210619Alma" },
        { media: "20210619LobsterRoll" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Alma+Lobster+Shop/@45.6011619,-64.9441337,17z/data=!3m1!4b1!4m6!3m5!1s0x4ca75e764a19e229:0x780a285963c110e3!8m2!3d45.6011619!4d-64.9415588!16s%2Fg%2F1hc40vpsv`
      ),
      {
        text: "It was a very short trip to the park, but still had some lovely views.",
      },
      [
        { media: "20210619Camp" },
        { media: "20210619Bridge" },
        { media: `20210619The${"bay"}` },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Point+Wolfe+Covered+Bridge/@45.5517885,-65.0146618,16.68z/data=!4m14!1m7!3m6!1s0x4ca75e764a19e229:0x780a285963c110e3!2sAlma+Lobster+Shop!8m2!3d45.6011619!4d-64.9415588!16s%2Fg%2F1hc40vpsv!3m5!1s0x4ca766d8bb6b7687:0x6c42b75dc091caf8!8m2!3d45.5506352!4d-65.0130165!16s%2Fg%2F121rjlg4`
      ),
      [
        {
          text: "And I made it! Nova Scotia! Still lots of exploring to do, but for now I have to set up a very practical standing desk for the day.",
        },
        /* TODO: fix remove overrides */
        { media: "20210619Working", overrideWidth: 22.2 },
        { media: "20210619River", overrideWidth: 43.8 },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Northport+Beach+Provincial+Park/@45.9258893,-63.8441873,507m/data=!3m1!1e3!4m6!3m5!1s0x4b5f3c4d53b9064b:0x7406e0a8e96fc75a!8m2!3d45.926885!4d-63.84408!16s%2Fg%2F11clgp1lts`
      ),
    ],
  },
  {
    published: true,
    title: "Nova Scotia Part 1",
    createdDate: "April 17, 2023",
    eventDate: ["July 24, 2021", "September 07, 2021"],
    chunks: [
      {
        text: "After arriving in Nova Scotia, I went to Halifax and Hallie and I did a bunch of hikes around the area. I of course also dyed my hair purple.",
      },
      [
        { media: "20210724PurpleHair" },
        { media: "20210724Explore1" },
        { media: "20210724Explore2" },
      ],
      [{ media: "20210724Split" }, { media: "20210724Split2" }],
      [{ media: "20210724Explore3" }, { media: "20210724Foundation" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Cape+Split/@45.3333253,-64.5411993,13z/data=!3m1!4b1!4m6!3m5!1s0x4b58eda0e5220eab:0xb52b7c7a0637820f!8m2!3d45.3333333!4d-64.5!16zL20vMGN4MXRi`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/1270+Queen+St,+Halifax,+NS+B3J+2H4/@44.639615,-63.5755888,17.94z/data=!4m15!1m8!3m7!1s0x4b5a211407dbfac1:0x666be3a6438b2ddc!2sHalifax,+NS!3b1!8m2!3d44.6475811!4d-63.5727683!16s%2Fm%2F02qjb7z!3m5!1s0x4b5a2236913b2489:0xbfab8f5984d89862!8m2!3d44.6404719!4d-63.5746516!16s%2Fg%2F11cs81n_mm`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Peggys+Cove,+NS/@44.4894448,-63.9176462,15.78z/data=!4m15!1m8!3m7!1s0x4b5a02e9d854b0e5:0x6b8306124c808566!2sPeggys+Cove,+NS!3b1!8m2!3d44.4939204!4d-63.9140477!16zL20vMDNoOTFx!3m5!1s0x4b5a02e9d854b0e5:0x6b8306124c808566!8m2!3d44.4939204!4d-63.9140477!16zL20vMDNoOTFx`
      ),
      {
        text: "Next stop, Cape Breton! I've always wanted to go but it never fit in for my previous trips to Nova Scotia. On the way there were still more things to see, including a bunch of ocean side camp spots for cooking over the fire, and coffee in the morning. I also took my first ferry in Vern! always exciting :)",
      },
      [
        { media: "20210724Fire" },
        { media: "20210724Eae65BedA7404184A32A910965078625" },
        { media: "20210724Pxl20210810163518563" },
      ],
      { lat: 44.95829, lng: -62.14665 },
      {
        text: "Working my way up Cape Breton towards the cabot trail, I also took the chance to make some waffles with wild blueberries I had picked. a campground I was staying at also sold oysters... so I had to get some",
      },
      [
        { media: "20210724Pxl20210811094823703" },
        { media: "20210724Waffles1" },
        { media: "20210724Dsc01649" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/46%C2%B031'45.4%22N+60%C2%B025'03.4%22W/@46.5292895,-60.4279197,15z/data=!3m1!4b1!4m4!3m3!8m2!3d46.52929!4d-60.41762`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/46%C2%B053'12.8%22N+60%C2%B027'36.5%22W/@46.8868995,-60.4704297,15z/data=!3m1!4b1!4m4!3m3!8m2!3d46.8869!4d-60.46013`
      ),
      [
        { media: "20210724Pxl20210810170140676" },
        { media: "20210724Pxl20210810204714139" },
        { media: "20210724Pxl20210814193732642" },
      ],
      {
        text: "I drove around the cabot trail, and it was lovely. Lots of views and hikes. The first hike was Franey, starting strong!",
      },
      [
        { media: "2021072432280C015A2A425C9068E15Eeba8D6041105C" },
        { media: "20210724Dsc01782" },
        { media: "20210724Dsc01877" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Franey+Trailhead/@46.6648269,-60.467785,13z/data=!4m12!1m5!3m4!2zNDbCsDM5JzQzLjIiTiA2MMKwMjYnMTkuOSJX!8m2!3d46.662!4d-60.43886!3m5!1s0x4b67abbd7c6f4637:0xed2397c9e649c452!8m2!3d46.6618939!4d-60.415288!16s%2Fg%2F11bz099pfj`
      ),
      [
        { media: "20210724Dsc02015" },
        { media: "20210724Dsc02352" },
        { media: "2021072469E1Dc497D0E46C4B50222C5A09Bd01F1105C" },
      ],
      {
        text: "I did the Skyline trail, which was insanely busy but still worth it. Along the way I saw a whale (rock) and moose!",
      },
      [{ media: "20210724Dsc02445" }, { media: "20210724Dsc02449" }],
      [
        { media: "20210724Dsc02463" },
        { media: "202107249De3562098254Dc185734812Eaf306C11105C" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Skyline+Trail/@46.7415437,-60.9166006,13.21z/data=!4m6!3m5!1s0x4b671943b56930f1:0xc57fe73e41a3eb72!8m2!3d46.741985!4d-60.8809999!16s%2Fm%2F06w7ctn`
      ),
      {
        text: "There were so many wild blueberries everywhere, one night I decided to make bread and blueberry jam on the fire. Turned out pretty well!",
      },
      [
        { media: "20210724Pxl20210816103825063" },
        { media: "20210724Pxl20210817150027988" },
      ],
      {
        text: "I continued up Cape Breton, and went all the way to Meat Cove. The spot I slept was this old foundation on a cliff that looked over the cove, beautiful views. And Meat Cove mountain hike also had some great views.",
      },
      latLngFromUrl(
        `https://www.google.com/maps/place/Meat+Cove,+NS+B0C+1E0/@47.0265311,-60.6007603,13z/data=!3m1!4b1!4m14!1m7!3m6!1s0x4b671943b56930f1:0xc57fe73e41a3eb72!2sSkyline+Trail!8m2!3d46.741985!4d-60.8809999!16s%2Fm%2F06w7ctn!3m5!1s0x4b66ebec4bc2649d:0x7e720f7a208fb37a!8m2!3d47.026539!4d-60.559561!16zL20vMGd5ODYw`
      ),
      [
        { media: "2021072431Ae3E14E1B54Af48F9CDdc0E63B05421105C" },
        { media: "20210724A3Ea4362D38E407F934111Da3C4Dcf911105C" },
        { media: "20210724C3C25607C7394987967B15Db69Bdc5B81105C" },
      ],
      [
        { media: "20210724Dsc02159" },
        { media: "20210724Dsc02166" },
        { media: "20210724Pxl20210814170842326" },
      ],
      {
        text: "I'm leaving Cape Breton now and on my way to PEI, but there were a few more views before I got there.",
      },
      [
        { media: "20210724Dsc02693" },
        { media: "20210724Pxl20210905204142476" },
        { media: "20210724Pxl20210907153022771" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Thomas+Cove+Coastal+Reserve/@45.3447944,-63.9587617,12.45z/data=!4m14!1m7!3m6!1s0x4b671943b56930f1:0xc57fe73e41a3eb72!2sSkyline+Trail!8m2!3d46.741985!4d-60.8809999!16s%2Fm%2F06w7ctn!3m5!1s0x4b59210a103fa8e7:0x1b69b0ed09389e0c!8m2!3d45.3642872!4d-63.902539!16s%2Fg%2F11ght9cwg4`
      ),
    ],
  },
  {
    published: true,
    title: "PEI",
    createdDate: "April 18, 2023",
    eventDate: ["September 10, 2021", "September 23, 2021"],
    chunks: [
      {
        text: "Before PEI, Hallie and I took a quick jaunt over to St. Andrews, NB to spend the weekend with friends at a cottage and see some fireworks, and I broke my fridge slide, boooo.",
      },
      latLngFromUrl(
        `https://www.google.com/maps/place/St.+Andrews,+NB/@45.0842577,-67.1446184,12z/data=!3m1!4b1!4m6!3m5!1s0x4ca8a830fa94391f:0x31d6c03e5f04c291!8m2!3d45.0738432!4d-67.0530897!16zL20vMDJtM2N4`
      ),
      {
        text: "Lets go to PEI! I've only ever been there once, and that was for less then a day. Not sure if you can see the bridge in the background of this first photo, but it sure is long!",
      },
      { lat: 46.31627, lng: -63.81059 },
      { media: "20210910Pxl20210920124009391" },
      {
        text: "Theres a hike thats a floating boardwalk to a beach, and given how much I love trail enhancements I had to check it out!",
      },
      [
        { media: "20210910Dsc02780" },
        { media: "20210910Dsc02798" },
        { media: "20210910Dsc02811" },
      ],
      [{ media: "20210910Dsc02910" }, { media: "20210910Dsc02876" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Thunder+Cove+Beach/@46.5612668,-63.6376086,15z/data=!3m1!4b1!4m6!3m5!1s0x4b5f8d54164dc389:0x91a35a9e069220fd!8m2!3d46.5612673!4d-63.6376086!16s%2Fg%2F11hbtfgndz`
      ),
      {
        text: "Of course Vern is a forever work in progress, so while I was here I added more bungees to the cubbies, much better!",
      },
      [
        { media: "20210910Pxl20210910213257490" },
        { media: "20210910Pxl20210910224616507" },
        { media: "20210910Pxl20210912160231949" },
        { media: "20210910Pxl20210915214335957" },
      ],
      [
        { media: "20210910Pxl20210916125027796" },
        { media: "20210910Dsc02936" },
        { media: "20210910Pxl20210918210818684" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/North+Point+Lightstation/@47.0576435,-63.9969554,17z/data=!3m1!4b1!4m6!3m5!1s0x4c9f8b774568d96b:0x565494176b767d65!8m2!3d47.0576435!4d-63.9969554!16s%2Fg%2F11cs6dpp5_`
      ),
      {
        text: "After more lobster rolls and beaches I headed back to Nova Scotia on the ferry!",
      },
      [
        { media: "20210910Pxl20210919172508916" },
        { media: "20210910Pxl20210922153900038" },
        { media: "20210910Pxl20210923160811424" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Original+Richard's/@46.4248524,-63.2240905,12z/data=!4m7!3m6!1s0x4b5fb63ba7c9fc73:0xdb521b4dfc739084!8m2!3d46.428897!4d-63.14529!15sCgxsb2JzdGVyIHJvbGxaDiIMbG9ic3RlciByb2xskgESc2VhZm9vZF9yZXN0YXVyYW50mgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVVJyTW1KaVdWVm5FQUXgAQA!16s%2Fg%2F1tj9sybl?coh=164777&entry=tt&shorturl=1`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/East+Point+Lighthouse/@46.4524955,-61.9718946,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipMeHA44t1Y22Cqxr-ADRw9Jbh45v_CyV49LujAa!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMeHA44t1Y22Cqxr-ADRw9Jbh45v_CyV49LujAa%3Dw153-h86-k-no!7i3490!8i1960!4m15!1m7!3m6!1s0x4b5fb63ba7c9fc73:0xdb521b4dfc739084!2sOriginal+Richard's!8m2!3d46.428897!4d-63.14529!16s%2Fg%2F1tj9sybl!3m6!1s0x4b5e01eb9c1b6bb7:0xc74d5dfea8034710!8m2!3d46.4524955!4d-61.9718946!10e5!16s%2Fg%2F11bv6wpngg`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Leonhard's/@46.2359324,-63.1270042,17z/data=!3m1!4b1!4m6!3m5!1s0x4b5e52e0d946a277:0xe3ff5d879d71a847!8m2!3d46.2359324!4d-63.1270042!16s%2Fg%2F1tdvjs3q`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Point+Prim+Chowderhouse/@46.0521556,-63.0393972,17z/data=!3m1!4b1!4m6!3m5!1s0x4b5ef7b1d1772b59:0x66d279ab174ee1a7!8m2!3d46.0521556!4d-63.0393972!16s%2Fg%2F1hm68tw0n`
      ),
      { lat: 46.01732, lng: -62.45495 },
    ],
  },
  {
    published: true,
    title: "The end of the first full summer",
    createdDate: "April 19, 2023",
    eventDate: ["October 03, 2021", "March 11, 2022"],
    chunks: [
      {
        text: 'The Cabot Trail round 2, but this time with Hallie! Hallie and I took a road trip around the cabot trail at the start of fall. We timed it perfectly, so we were out of there right before all the colours really changed :P Of course, this was a running joke while we looked out over the rolling green hills with a spot of red, and we commented "Just imagine in a week, when all of this is starts to change colour, it will be beautiful"',
      },
      [
        { media: "20211003Pxl20211003101233872" },
        { media: "20211003Dsc03018" },
      ],
      [{ media: "20211003Dsc03121" }, { media: "20211003Dsc03133" }],
      [
        { media: "20211003Dsc03140" },
        { media: "20211003Dsc03144" },
        { media: "20211003Dsc03146" },
      ],
      [
        { media: "20211003Dsc03248" },
        { media: "20211003Dsc03260" },
        { media: "20211003Dsc03289" },
      ],
      {
        text: "Once we returned I took one last trip a little more south in Nova Scotia, going to Kejimkujik National Park and all the way out to Brier Island which takes 2 ferries down these very thin islands.",
      },
      [{ media: "20211003Dsc03357" }, { media: "20211003Dsc03424" }],
      [
        { media: "20211003Pxl20211025104438840" },
        { media: `20211003${"E"}${"fec"}${"tum"}6478103035834735496` },
      ],
      {
        text: "One of my favourite places I stayed for a few days, it was down a four wheel track that I probably shouldn't have gone down, but it was worth it :)",
      },
      [
        { media: "20211003Pxl20211026103554878" },
        { media: "20211003Pxl20211026103620617" },
        { media: "20211003Pxl20211026213556571" },
      ],
      [
        { media: "20211003Pxl20211029131133895" },
        { media: "20211003Pxl20211029131456124" },
      ],
      {
        text: "Many jokes ensued, I got a new iPad so I drew diagrams for debates Claire and I had, and I think they were really convincing. I also got some of my ingredients back from cooking thanks giving dinner for the people in Halifax, and Claire had labeled all my onions.",
      },
      [
        { media: "20211003Image000000" },
        { media: "20211003Image0000001" },
        { media: "20211003Image0000003" },
      ],
      {
        text: "After I got back to Halifax, I moved into Hallie's old place until December and put Vern in storage. I spent Christmas with Hallie and Claire and knit us all stockings :)",
      },
      [
        { media: "20211003Pxl20211209000542824" },
        { media: "20211003Pxl20211215020221040" },
        { media: "20211003Pxl20211224231244611" },
      ],
      {
        text: "Per tradition, I needed to go back to Toronto for new years to spend it with Adrian and Sennah, I had never taken a train long distance before, so I decided what the heck! lets take a train sleeper car! This trip probably would be better in the summer for the views and longer daylight hours",
      },
      [
        { media: "20211003Pxl20211229163944043" },
        { media: "20211003Pxl20211229171951383" },
        { media: "20211003Pxl20211229203524331Mp" },
      ],
      {
        text: "Back in Halifax, the neighborhood cat would often curl up on the back of the couch, and pictured here is the birthday cake Claire made me, a cake pan went missing so there was some improvisation to complete it, but it is my favourite :) I brought Vern out of storage as well so that I could do some work on the inside over winter :)",
      },
      [
        { media: "20211003Pxl20220118130640066" },
        { media: "20211003Pxl20220121021748961" },
        { media: "20211003Pxl20220129170408897" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Brier+Island+Lighthouse/@44.2441247,-66.4017796,14.78z/data=!4m6!3m5!1s0x4ca9cc1c920e31a1:0xa6336ceff3e4e9de!8m2!3d44.2490715!4d-66.3924735!16s%2Fg%2F11c3n2k566`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Kejimkujik+National+Park+and+National+Historic+Site/@44.2651025,-65.6706918,9.36z/data=!4m6!3m5!1s0x4b5706fac3dda4f3:0x2ee8c2ab7fb4b438!8m2!3d44.435209!4d-65.213655!16zL20vMDFzYzN6`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Balancing+Rock/@44.7091934,-66.9652826,7.86z/data=!4m10!1m2!2m1!1sleaning+rock!3m6!1s0x4ca8322079187921:0xf2cc31859cff9995!8m2!3d44.3629681!4d-66.2242669!15sCgxsZWFuaW5nIHJvY2uSARJ0b3VyaXN0X2F0dHJhY3Rpb27gAQA!16s%2Fg%2F12q4q_0r1`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/1380+Edward+St,+Halifax,+NS+B3H+3H6/@44.6389363,-63.5886804,17z/data=!4m15!1m8!3m7!1s0x4b5a2225501d56eb:0x512c27913738187b!2s1406+Edward+St,+Halifax,+NS+B3H+3H6!3b1!8m2!3d44.6389439!4d-63.5875646!16s%2Fg%2F11c1fzv644!3m5!1s0x4b5a22254e91edcb:0x335c75418faf8e27!8m2!3d44.6386772!4d-63.587443!16s%2Fg%2F11cs72fm25`
      ),
    ],
  },
  {
    published: true,
    title: "West with Claire",
    createdDate: "April 20, 2023",
    eventDate: ["April 19, 2022", "May 25, 2022"],
    chunks: [
      {
        text: `
          Next up was going to be driving from the east coast to the west coast, and lucky for me the first half of my trip I would have company! Claire was heading to the west coast at the same time I was, so we would drive together to Manitoba, then we would part ways and she would fly the rest of the way and I would mosey through the second half of Canada.
        `,
      },
      {
        text: `
          We left Halifax and our first camp spot was down a gravel road, driving along we came across a tree in the middle of it, which we both thought was pretty funny :P
        `,
      },
      [
        { media: "20220419ClaireAndMe" },
        { media: "20220419Barn" },
        { media: "20220419TreeOnRoad" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/MacCormacks+Beach+Provincial+Park/@44.6089665,-63.4918381,16z/data=!4m6!3m5!1s0x4b5a3d3791921741:0x19594009f7a59983!8m2!3d44.6076525!4d-63.4925162!16s%2Fg%2F1tfwjywt`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/MacAusland's+Woollen+Mills/@46.7048048,-64.6915543,9z/data=!4m10!1m2!2m1!1swool!3m6!1s0x4ca015b700000001:0xfd6a1607400ff560!8m2!3d46.7550576!4d-64.1799857!15sCgR3b29sWgYiBHdvb2ySAQRtaWxsmgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVU5vYlUxaFRWVkJFQUXgAQA!16s%2Fg%2F11h2c4s4fc`
      ),
      {
        text: "Next up, back to PEI! First stop was we needed to go back to The Handpie Company to get some real good handpies, and then worked for a bit in a cafe in Charlottetown.",
      },
      [{ media: "20220419Img0288" }, { media: "20220419Img5403" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/The+Handpie+Company/@46.2891437,-63.6400061,15z/data=!3m1!4b1!4m6!3m5!1s0x4b5fa1d961a757ed:0x2c9b1a3cabddf106!8m2!3d46.2891442!4d-63.6297064!16s%2Fg%2F11bc7rq3l1`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Receiver+Coffee+-+The+Brass+Shop/@46.23425,-63.1206766,17z/data=!3m1!4b1!4m6!3m5!1s0x4b5e52e39ba3e5d1:0x4e9da3e71c8e71e8!8m2!3d46.23425!4d-63.1206766!16s%2Fg%2F11f38lf56g`
      ),
      [{ media: "20220419Img0283" }, { media: "20220419Img5431" }],
      {
        text: `A big reason we wanted to come to PEI again was to head to MacAusland Woollen Mills and get a wool blanket and some yarn. And of course while we are at that end of the island, we had to visit the big potato... This was the joke that created a problem of me stopping at far too many large things as a "joke", but did it become to real? I don't know anymore...`,
      },
      [{ media: "20220419Img0315" }, { media: "20220419Img5448" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Canadian+Potato+Museum+%26+Antique+Farm+Machinery+Museum/@46.7036721,-64.2450096,15z/data=!3m1!4b1!4m6!3m5!1s0x4ca06a9dc821f9ad:0x24b892ea12e9c17a!8m2!3d46.7036726!4d-64.2347099!16s%2Fg%2F1tfwjyx9`
      ),
      {
        text: "After returning to New Brunswick, we headed down to explore the coast of the Bay of Fundy, Hopewell rocks was the first stop which was closed for the season, but we parked at the gate and walked in. In the parking lot a fella named Conner sparked up a conversation and joined us while exploring the trail.",
      },
      [
        { media: "20220423Img5453" },
        { media: "20220423Img0342" },
        { media: "20220423Img0348" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Hopewell+Rocks+Provincial+Park/@45.8171447,-64.5887563,15z/data=!3m1!4b1!4m6!3m5!1s0x4b5f5537e6cea179:0x98d8a718a0d196ff!8m2!3d45.8171452!4d-64.5784566!16zL20vMDkzbWhs`
      ),
      {
        text: "In the Bay of Fundy National park we did a few hikes, including Dickson falls which was still partially covered in snow.",
      },
      [
        { media: "20220423Dsc03495" },
        { media: "20220423Dsc03505" },
        { media: "20220423Img0433" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Dickson+Falls+Trailhead/@45.5914347,-64.9803994,15.5z/data=!4m6!3m5!1s0x4ca75df74ebad2f5:0xce48d1492221c888!8m2!3d45.5870903!4d-64.9739063!16s%2Fg%2F11bwym0w8j`
      ),
      [
        {
          text: "We went to Collin's Lobster for some lobsters in Alma and could not have been happier, we bought some extra lobster to have later, that will make an appearance later one :D",
        },
        { media: "20220423Img5578" },
        { media: "20220423Img5581" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Collins+Lobster+Shop/@45.6012083,-64.9534907,15z/data=!3m1!4b1!4m6!3m5!1s0x4ca75e761c58fea5:0x5c2b60d20e92fb6e!8m2!3d45.6012088!4d-64.943191!16s%2Fg%2F1tgxt415`
      ),
      [
        {
          text: 'The big roadside attraction curse continued with a giant... peanut?... maybe a potato? We saw it while just driving down the road, this stuck with me so when I saw the worlds biggest axe on the map I thought I had to stop by for the joke, this was the first time Claire said "I hate you" (I\'m pretty sure she was just joking :D). The giant fiddleheads also just happened to be on the way...',
        },
        { media: "20220423Img5629" },
        { media: "20220423Img5639" },
        { media: "20220423Img0489" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/World's+Largest+Axe/@45.9835032,-67.2479272,15z/data=!4m10!1m2!2m1!1sbig+axe!3m6!1s0x4ca45b664d25ce67:0x8c4149db7d300a15!8m2!3d45.9943491!4d-67.23297!15sCgdiaWcgYXhlWgkiB2JpZyBheGWSARJ0b3VyaXN0X2F0dHJhY3Rpb26aASRDaGREU1VoTk1HOW5TMFZKUTBGblNVTk5jRFJ5ZWpaUlJSQULgAQA!16s%2Fm%2F05syy40`
      ),
      [
        {
          text: 'On a more interesting "Worlds Largest", we stopped by the longest covered bridge! And drove across, on a Wednesday! What excitement!',
        },
        { media: "20220423Img0466" },
        { media: "20220423Img0469" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/World's+Longest+Covered+Bridge/@46.2969569,-67.5276201,17z/data=!3m1!4b1!4m6!3m5!1s0x4ca491ef44eff731:0x10fee9b3dd25151f!8m2!3d46.2969569!4d-67.5276201!16s%2Fg%2F11ghg36tct`
      ),
      {
        text: "This was Claire's favourite camp spot, it was the side of a little winding coastal road with beautiful view of the St. Lawrence.",
      },
      [{ media: "20220423Img0549" }, { media: "20220423Img0556" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Saint-Fabien,+QC/@48.289099,-69.1888647,10z/data=!3m1!4b1!4m6!3m5!1s0x4c95e929881753c1:0xdccbdb1ccf0e9d7c!8m2!3d48.2940739!4d-68.8668978!16s%2Fm%2F0408hbx`
      ),
      { text: "Birds! so many birds!" },
      [{ media: "20220423Img0611" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Saint-Jean-Port-Joli,+QC/@47.2314029,-70.3719967,12z/data=!3m1!4b1!4m6!3m5!1s0x4cbed974c3976e05:0xfb7bf58b1a813d84!8m2!3d47.2153806!4d-70.2694167!16zL20vMDV5cHN2`
      ),

      [
        {
          text: "We stopped in Montreal to visit friends (shout out Franky, Marilou, and Nicole!), stopped by Odessa to see my parents, Colin, and Kari. Then continued on to Toronto for visits with Adrian, Sennah, Caleb, and Lauren. While in toronto we also went to the Aquarium of course :)",
        },
        { media: "20220423Img0619" },
        { media: "20220423Img0676" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Montreal,+QC/@45.5572862,-74.3711978,9z/data=!3m1!4b1!4m6!3m5!1s0x4cc91a541c64b70d:0x654e3138211fefef!8m2!3d45.5018869!4d-73.5673919!16zL20vMDUycDc`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Ripley's+Aquarium+of+Canada/@43.642178,-79.3969019,15z/data=!3m1!4b1!4m6!3m5!1s0x882b34d5d5b6045b:0x8daf1a19298c213d!8m2!3d43.6421785!4d-79.3866022!16s%2Fm%2F0vxfm_5`
      ),
      {
        text: "At work we had an online custard tart class, I of course do not have an oven so I made them on a campfire, the heat didn't work out as well as when I made bread, but I learnt more so next time it will be even better! Normally custard tarts would have that beautiful top, which I couldn't achieve with my method so they are pretty ugly on top, but hot damn they were still delicious!",
      },
      [
        { media: "20220423Img0687" },
        { media: "20220423Img0689" },
        { media: "20220423Img5805" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Serpent+River+Campground/@46.179225,-82.8697987,10.56z/data=!4m9!3m8!1s0x4d31743a94faa3f5:0x864c1df3f80dd4f7!5m2!4m1!1i2!8m2!3d46.211253!4d-82.601435!16s%2Fg%2F1tdp97pw`
      ),
      {
        text: "We ate a whole lot of sourdough with hummus cheese and tomatoes, a reliable classic. We found the big loonie... so sorry Claire.  And then we had lobster rolls with the lobster we got from Collin's in Alma 🦞",
      },
      [
        { media: "20220423Img0696" },
        { media: "20220423Img5822" },
        { media: "20220423Img5832" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Loon+Dollar+Monument/@46.9585863,-96.1993626,4.75z/data=!4m11!1m3!2m2!1sloonie!6e2!3m6!1s0x4d365af0aba25701:0xa19a161e0893f232!8m2!3d46.4884956!4d-84.0735963!15sCgZsb29uaWVaCCIGbG9vbmllkgESdG91cmlzdF9hdHRyYWN0aW9umgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVU4xZWt0WVkwSjNFQUXgAQA!16s%2Fg%2F11dyp4mnhc`
      ),
      {
        text: "Passing along the coast of lake superior, we had to stop back to one of my favourite spots. Unfortunately this time I did get pretty sick, so I spent a lot of time laying down, but there are worse places to be while sick!",
      },
      [{ media: "20220423Img0706" }, { media: "20220423Img0711" }],
      [
        {
          text: "on no... more big things? Yep, Wawa had a big goose, and in the town almost all the businesses had their own big goose. There was also a big Winnie the Pooh, which I took this very bad photo of",
        },
        { media: "20220423Img0712" },
        { media: "20220423Img0714" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Wawa,+ON+P0S+1K0/@47.9920458,-84.7753926,15z/data=!3m1!4b1!4m6!3m5!1s0x4d470a56353969c7:0x633c8d94fc5a0f15!8m2!3d47.992417!4d-84.7710588!16zL20vMDJudDMx`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Winnie+the+Pooh+Memorial/@48.5931828,-85.2864533,15z/data=!3m1!4b1!4m6!3m5!1s0x4d46cc9d269de7db:0xb1c73aef64cf603!8m2!3d48.5931833!4d-85.2761536!16s%2Fg%2F11d_wn68b3`
      ),
      {
        text: "Stopping by Aguasabon Falls, it was certainly a powerful waterfall :O",
      },
      [{ media: "20220423Img0735" }, { media: "20220423Img0736" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Aguasabon+Falls+%26+Gorge/@48.7773233,-87.1216263,14.67z/data=!4m9!1m2!2m1!1z4oCO4oGoVGVycmFjZSBCYXnigaksIOKBqE5vcnRoIFNob3Jl4oGpLCDigahDYW5hZGHigak!3m5!1s0x4d4353bf83cca535:0x355e35a03ac2691c!8m2!3d48.7860998!4d-87.1204214!16s%2Fg%2F1vgqcqvh`
      ),
      {
        text: "Another fun camp spot was this boat launch that had a bunch of old fishing boats.",
      },
      [
        { media: "20220423Dsc03613" },
        { media: "20220423Img0742" },
        { media: "20220423Img5893" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Hurkett+Cove+Dock/@48.8375954,-88.4942697,15.45z/data=!4m15!1m8!3m7!1s0x4d5c0495004240dd:0x66d8f9070a78eb11!2sHurkett,+ON+P0T+1K0!3b1!8m2!3d48.8501959!4d-88.481137!16s%2Fm%2F03m4x5y!3m5!1s0x4d5c04e4c309303d:0xa8618bb7721b401a!8m2!3d48.8402404!4d-88.4885177!16s%2Fg%2F11fx88rwfv`
      ),
      {
        text: "We went to Ouimet Canyon, but it was also closed so we had to park outside and walk in, it was quite the hill to walk in, and the trail hadn't been cleaned up from winter yet but still a lovely hike",
      },
      [{ media: "20220423Dsc03650" }, { media: "20220423Dsc03651" }],
      [{ media: "20220423Dsc03632" }, { media: "20220423Dsc03640" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Ouimet+Canyon+Provincial+Park/@48.5033134,-89.1857015,9z/data=!4m10!1m2!2m1!1scanyon!3m6!1s0x4d5eabd8337be3ef:0x59ecb2e73be580ef!8m2!3d48.7895139!4d-88.6712946!15sCgZjYW55b24iA4gBAVoIIgZjYW55b26SAQpzdGF0ZV9wYXJrmgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVVJDT1V3eVEzRm5SUkFC4AEA!16s%2Fg%2F11bc62rn89`
      ),
      {
        text: "We stopped and saw the Sea Lion which has eroded so it's missing it's head and looks more like an elephant to me now. And then we headed into Thunder Bay where we were going to meat up with Heather.",
      },
      [{ media: "20220423Dsc03659" }, { media: "20220423Img0765" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Sea+Lion+Trail/@48.3392261,-88.8321776,15.23z/data=!4m14!1m7!3m6!1s0x4d59698cf0ebd971:0x4ba14f36eea3363e!2sSea+Lion+Trail!8m2!3d48.3377797!4d-88.8290431!16s%2Fg%2F11hz9l2ds5!3m5!1s0x4d59698cf0ebd971:0x4ba14f36eea3363e!8m2!3d48.3377797!4d-88.8290431!16s%2Fg%2F11hz9l2ds5`
      ),
      {
        text: "We had a great time in Thunder Bay with heather where we had some wine, went for pizza, went to the farmers market and lots of chats. Heather also gifted us some fiddleheads from her back yard so we cooked those up for dinner with some eggs on toast, everything else from the farmers market :)",
      },
      [{ media: "20220423Img0780" }, { media: "20220423Img0782" }],
      {
        text: "To Kakabeka Falls! Another wonderful waterfall, this one was feeding a canyon where it use to be, and even had a smaller waterfall near by.",
      },
      [{ media: "20220423Dsc03689" }, { media: "20220423Dsc03690" }],
      [{ media: "20220423Img0786" }, { media: "20220423Img5959" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Kakabeka+Falls+Riverside+Campground/@48.3972554,-89.6358168,13.78z/data=!4m14!1m7!3m6!1s0x4d59698cf0ebd971:0x4ba14f36eea3363e!2sSea+Lion+Trail!8m2!3d48.3377797!4d-88.8290431!16s%2Fg%2F11hz9l2ds5!3m5!1s0x4d58c02f2c9c1a7d:0x4b34cf6e10ac0759!8m2!3d48.4033112!4d-89.6284594!16s%2Fg%2F11c6qlmxv8`
      ),
      { text: "A big ol' mosquito carrying a person away... checks out..." },
      { text: "And Vern's minnows! We let them keep the minnows for storage." },
      [{ media: "20220423Img6002" }, { media: "20220423Img6004" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Vern's+Minnows/@48.539642,-100.6768477,4.98z/data=!4m6!3m5!1s0x52a233c0e715a3c9:0x8df55f35364f0fc3!8m2!3d49.4171562!4d-91.6639404!16s%2Fg%2F1tdcllvy`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Upsala,+ON+P0T+2Y0/@49.0479172,-90.475533,16.33z/data=!4m6!3m5!1s0x52a0e9d30ff2754f:0xec2b0d7373d806fe!8m2!3d49.0500843!4d-90.4716051!16zL20vMDVscmJs`
      ),
      [
        {
          text: "Last big thing that Claire had the pleasure of being treated to, a big fish! Before that we went to the drive in! I freaking love the drive in :D",
        },
        { media: "20220423Img0796" },
        { media: "20220423Img0804" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Sunset+Drive-In+Theatre/@49.6118915,-93.1453337,10.27z/data=!4m11!1m3!2m2!1sdrive+in+!6e1!3m6!1s0x52a2b2f505d2c9a7:0x28e87f3a91e18582!8m2!3d49.8057746!4d-92.8937225!15sCghkcml2ZSBpbloKIghkcml2ZSBpbpIBDW1vdmllX3RoZWF0ZXKaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVTjFjQzB0VmpGblJSQULgAQA!16s%2Fg%2F11c604skl5`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Husky+the+Muskie/@49.7701796,-94.4972729,17z/data=!3m1!4b1!4m6!3m5!1s0x52be0627ccd473fb:0xfdf130810a5532a8!8m2!3d49.7701796!4d-94.4972729!16s%2Fm%2F0404x_g`
      ),
      {
        text: "And we made it, the centre of canada! This was right before Winnipeg. We had a lovely meal at Clementine Cafe where we learnt Winnipeg has a pretty great food scene. And then there were some sad goodbyes as Claire boarded her plane to start her next adventure, well I continued on the next chapter of mine.",
      },
      [
        { media: "20220423Img0806" },
        { media: "20220423Img0814" },
        { media: "20220423Img0815" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Centre+of+Canada+Park/@49.7714863,-96.8267688,15z/data=!3m1!4b1!4m6!3m5!1s0x52c1d4d478055a05:0x79f1f75bd1419bc!8m2!3d49.7714868!4d-96.8164691!16s%2Fg%2F11g8zsfyv8`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Clementine+Cafe/@49.8989672,-97.1442086,17z/data=!3m1!4b1!4m6!3m5!1s0x52ea715c35847e35:0x2cfb2781ad7c9dde!8m2!3d49.8989672!4d-97.1416337!16s%2Fg%2F11byn11qg7`
      ),
      { lat: 49.61633, lng: -95.98256 },
    ],
  },
  {
    published: true,
    title: "Manitoba",
    createdDate: "April 21, 2023",
    eventDate: ["May 27, 2022", "June 14, 2022"],
    chunks: [
      {
        text: "Now that Claire took off in her plane, I headed out from Winnipeg to explore. First stop... Big things, oh no!",
      },
      [
        { media: "20220527Img0859" },
        { media: "20220527Img0868" },
        { media: "20220527Img0870" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/World's+Largest+Pumpkin/@49.3691857,-97.9509929,17z/data=!4m14!1m7!3m6!1s0x52c241c9cf7c3805:0x91ed90b15269fba8!2sRoland+Giant+Pumpkin!8m2!3d49.3691857!4d-97.948418!16s%2Fg%2F11fl9j5_0q!3m5!1s0x52c241e6f8b3574d:0x196c989f8781ae1b!8m2!3d49.3694339!4d-97.9489856!16s%2Fg%2F11h_67dbw6`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Milne+Park/@49.18948,-98.1111421,19.39z/data=!4m6!3m5!1s0x52c251b909272daf:0x3d9efd7bdc808c26!8m2!3d49.1896151!4d-98.1107626!16s%2Fg%2F12htf67rn`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Morden+Turtle+Statue/@49.1925665,-98.1206994,17z/data=!3m1!4b1!4m6!3m5!1s0x52c251c5479f3cc5:0x9814dca353243bf!8m2!3d49.1925665!4d-98.1206994!16s%2Fg%2F11fd43h4mv`
      ),

      {
        text: "Now that thats out of my system I headed over to the Canadian Fossil Discovery Centre to see some bones 🦴.",
      },
      [{ media: "20220527Img0873" }, { media: "20220527Img0874" }],
      [{ media: "20220527Img0885" }, { media: "20220527Img0894" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Canadian+Fossil+Discovery+Centre/@49.1929565,-98.131323,13.4z/data=!4m14!1m7!3m6!1s0x52c251c5479f3cc5:0x9814dca353243bf!2sMorden+Turtle+Statue!8m2!3d49.1925665!4d-98.1206994!16s%2Fg%2F11fd43h4mv!3m5!1s0x52c251d192fbef2b:0x741bb7e5a92d5401!8m2!3d49.1974877!4d-98.0952065!16s%2Fm%2F03gs7bs`
      ),
      [
        {
          text: "I found another drive in, so I obviously had to go see a movie. This drive in had a fun twist, it was a campground as well! I had stayed at other drive-ins before just in the lot, but this is the first one that was officially setup for it. The next day, the person who sold me my ticket came over with his girlfriend to ask questions about my van.",
        },
        { media: "20220527Img0860" },
        { media: "20220527Img0867" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Stardust+Drive+In+Theatre/@49.1911582,-98.1446075,17z/data=!3m1!4b1!4m6!3m5!1s0x52c251b0a8f9caf5:0x60cffc0e6554ab9!8m2!3d49.1911582!4d-98.1446075!16s%2Fg%2F1wrtchw3`
      ),

      {
        text: "There was a park with sand dunes that I had to go for a hike at.",
      },
      [
        { media: "20220527Img0899" },
        { media: "20220527Img0901" },
        { media: "20220527Dsc03741" },
      ],
      [{ media: "20220527Dsc03721" }, { media: "20220527Img0912" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Spruce+Woods+Provincial+Park/@49.5549506,-99.2796082,17z/data=!3m1!4b1!4m6!3m5!1s0x52e7ffe23c63c5dd:0xbc3a5a88241620e9!8m2!3d49.5549506!4d-99.2796082!16s%2Fm%2F02z85d5`
      ),

      {
        text: "Heading up to Riding Mountain National Park, I wanted to go to the Bison enclosure, but it turned out to be closed. I ended up doing this lovely hike that had a community canoe on it for hikers to go for a paddle.",
      },
      [{ media: "20220527Dsc03752" }, { media: "20220527Dsc03756" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Brule+Trail/@50.6794194,-99.8923409,15.19z/data=!4m14!1m7!3m6!1s0x52e610cfe3f8e411:0x2df5db5b18261bb5!2sKinosao+Lake!8m2!3d50.6871901!4d-99.8732799!16s%2Fg%2F11clgn5_cg!3m5!1s0x52e6112db5b7aaab:0x2e649501512a54fb!8m2!3d50.6793871!4d-99.8940726!16s%2Fg%2F11d_trxws4`
      ),

      {
        text: "Driving around exploring the park, I saw a bunch of bears, and pelicans! fun :)",
      },
      [{ media: "20220527Dsc03766" }, { media: "20220527Dsc03812" }],
      [{ media: "20220527Dsc03804" }, { media: "20220527Dsc03794" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Lake+Audy+Dam/@50.7398649,-100.2654979,14z/data=!4m15!1m8!3m7!1s0x52e66d7e6b2f8fb7:0xe05b11653709b1f7!2sLake+Audy+Rd,+Manitoba+R0J+0Z0!3b1!8m2!3d50.7357884!4d-100.0988887!16s%2Fg%2F11b62v7lxj!3m5!1s0x52e6703aa0b424bb:0x5334e11b63111dea!8m2!3d50.7398649!4d-100.251374!16s%2Fg%2F11c5qr5cd_`
      ),

      {
        text: "I spent more time then I maybe wanted to in Foxwarren because I was waiting for some packages. But they had this beautiful free campsite with free power!",
      },
      [{ media: "20220527Img1008" }, { media: "20220527Img1040" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Foxwarren+Campground/@50.5170666,-101.1624355,16z/data=!4m15!1m8!3m7!1s0x52e6b7ac13991417:0xa59ba2066eaf263e!2sFoxwarren,+MB+R0J+0R0!3b1!8m2!3d50.5204204!4d-101.1540591!16zL20vMDl5c3hj!3m5!1s0x52e6b7f88f133fa1:0x390e62d7241ce7de!8m2!3d50.5195648!4d-101.1546736!16s%2Fg%2F11nx39kkg0`
      ),

      [
        {
          text: `Hey! Ontario?! Whats going on here? I realized when I renewed my license they put in the wrong address, so I had to drive back to Kenora to find a Service Ontario to update the address again so I could get a new one before mine expired. 
          
          Along the way I needed to entertain myself, and I had this hook that Claire and I found while hiking. I got a magnet and a bolt to screw into the hook, and then I stuck it to my partition to make this lovely coat or hat hanger :)`,
        },
        { media: "20220527Img1076" },
        { media: "20220527Img1024" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Kenora,+ON/@49.8226377,-94.5490166,12z/data=!3m1!4b1!4m6!3m5!1s0x52bdeb0c22a8375b:0xaa577b8fae4952c!8m2!3d49.7670416!4d-94.4893924!16zL20vMDE4Zjh0`
      ),

      [
        {
          text: "The package! Another team building exercise with work, we did a chocolate tasting. They used this adorable paperclip that I will cherish forever :P Thats it for Manitoba! Saskatchewan here I come!",
        },
        { media: "20220527Img1130" },
        { media: "2022052767695140852639042128A6A49B8813AFaac58Ff49Dc" },
      ],
    ],
  },
  {
    published: true,
    title: "Saskatchewan",
    createdDate: "April 22, 2023",
    eventDate: ["June 15, 2022", "July 12, 2022"],
    chunks: [
      {
        text: "People make jokes about Saskatchewan, but I was determined to appreciate the province. Given that I had time to travel off the highway, I thought I could definitely find the beauty here! The first place was the Qu'Appelle Valley.",
      },
      [
        { media: "20220527Dsc03817" },
        { media: "20220527Img1154" },
        { media: "20220527Img1156" },
      ],
      [
        { media: "20220527Dsc03850" },
        { media: "20220527Img1159" },
        { media: "20220527Img1165" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Tantallon,+SK+S0A+4B0,+Canada/@50.5356948,-101.8459863,16z/data=!3m1!4b1!4m6!3m5!1s0x52e168fdd769302f:0x9788d42abc0a21f8!8m2!3d50.5367437!4d-101.8421169!16s%2Fm%2F09gddkp`
      ),
      {
        text: "Next thing I found was not nature, but still fun! It was Old George's Hidden Village. He had setup in the field behind the house a little wild west with old shanties from the area, and in the house was collections of old stuff.",
      },
      [
        { media: "202205273Faf21E94Fd44554909308E3825Fee76" },
        { media: "20220527Img1195" },
        { media: "20220527Img1194" },
      ],
      [{ media: "20220527Img1193" }, { media: "20220527Img1197" }],
      [{ media: "20220527Img1201" }, { media: "20220527Img1210" }],
      [
        { media: "20220527Img1217" },
        { media: "20220527Img1219" },
        { media: "20220527Img1221" },
      ],
      [
        { media: "20220527Img1230" },
        { media: "20220527Img1231" },
        { media: "20220527Img1233" },
      ],
      [
        { media: "20220527Img1235" },
        { media: "20220527Img1237" },
        { media: "20220527Img1246" },
      ],
      [
        { media: "20220527Img1250" },
        { media: "20220527Img1264" },
        { media: "20220527Img1269" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Old+George's+Hidden+Village+Antiques+%26+Art/@50.339815,-102.5013344,8.43z/data=!4m9!1m2!2m1!1z4oCO4oGoVGFudGFsbG9u4oGpLCDigahSZWQgUml2ZXIgVmFsbGV54oGpLCDigahDYW5hZGHigak!3m5!1s0x52e1a229853937d1:0xde30ee0414d2f1f4!8m2!3d50.3387572!4d-102.2701696!16s%2Fg%2F1tykv0th`
      ),

      [
        {
          text: "I found another drive in, they let me stay the night as well and lego vern was still waiting for the next movie in the morning.",
        },
        { media: "20220527Img1297" },
        { media: "20220527Img1305" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Twilite+Drive-in+Theatre/@50.4229899,-103.3113,14z/data=!4m15!1m8!3m7!1s0x531e04d1f5345aff:0xc26311639f7b81a9!2sWolseley,+SK+S0G+5H0,+Canada!3b1!8m2!3d50.4240404!4d-103.2769906!16zL20vMDI2MGRs!3m5!1s0x531e04d3e4bfb225:0x9faa808622c3ed00!8m2!3d50.425187!4d-103.29783!16s%2Fg%2F1pty4cmj2`
      ),

      { text: "A wee church on the side of the Qu'Appelle Valley." },
      [{ media: "20220527Dsc03877" }, { media: "20220527Dsc03868" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Lebret+-+chapel+shrine/@50.7553592,-103.7108958,15.63z/data=!4m9!1m2!2m1!1z4oCO4oGoTGVicmV04oGpLCDigahSZWQgUml2ZXIgVmFsbGV54oGpLCDigahDYW5hZGHigak!3m5!1s0x531de9e358a97e17:0x4f572ebdb50ee3d2!8m2!3d50.7596925!4d-103.7049572!16s%2Fg%2F11h2q6bwft`
      ),

      [
        {
          text: "Feels like grain elevator are an important stop while passing through, this was an abandoned one I went exploring.",
        },
        { media: "20220527Dsc03896" },
      ],
      [{ media: "20220527Dsc03887" }, { media: "20220527Img1358" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Moreland+Grain+Elevator/@49.7232583,-104.5991902,18.19z/data=!4m15!1m8!3m7!1s0x5318d39bcc3aec71:0xabecd87c9d1b9dfc!2sPangman,+SK+S0C+2C0,+Canada!3b1!8m2!3d49.6468294!4d-104.6581797!16s%2Fm%2F026kdlz!3m5!1s0x5318e19ed38f6bbd:0xfbbcd3c961ee5a5e!8m2!3d49.7241106!4d-104.596479!16s%2Fg%2F11lgxg4nfn`
      ),

      {
        text: "Heading south to the badlands, I started to find some landscapes that were very new to me! This was big muddy, it started to rain while I was exploring, and oof, the roads sure do get slippery out here!",
      },
      [{ media: "20220527Dsc03927" }, { media: "20220527Img1392" }],
      [{ media: "20220527Img1366" }, { media: "20220527Dsc03932" }],
      [{ media: "20220527Img1417" }, { media: "20220527Img1402" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Castle+Butte+(Big+Muddy)/@49.217562,-105.2190685,17z/data=!3m1!4b1!4m6!3m5!1s0x531889aa82b5c26f:0xa0a1753f9ea12181!8m2!3d49.217562!4d-105.2190685!16s%2Fg%2F11bbrm1g6p`
      ),

      {
        text: "Not an attraction, but I had to stop to take some photos of this little shed on the side of the road :)",
      },
      [{ media: "20220527Dsc04028" }, { media: "20220527Dsc04037" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B026'12.2%22N+107%C2%B000'53.7%22W/@49.43672,-107.01492,17z/data=!3m1!4b1!4m4!3m3!8m2!3d49.43672!4d-107.01492`
      ),

      {
        text: "I made it to the grasslands national park! This park has two completely separate sections the east block and the west block. I am very much a mountains and trees person, but there was something absolutely stunning about standing in the park. With all the stillness there was still so much life and activity. ",
      },
      [{ media: "20220527Dsc03960" }, { media: "20220527Img1548" }],
      [{ media: "20220527Img1536" }, { media: "20220527Dsc03939" }],
      [{ media: "20220527Dsc03981" }, { media: "20220527Dsc03961" }],
      [{ media: "20220527Dsc03982" }, { media: "20220527Dsc04125" }],
      [{ media: "20220527Dsc04203" }, { media: "20220527Dsc04238" }],
      [
        {
          text: "Everything gets very slippery when it rains, these were some stairs on a hike I did that turned into more of a slide for me.",
        },
        { media: "20220527Img1489" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Red+Buttes+Trailhead/@49.0442776,-106.5601727,12.28z/data=!4m14!1m7!3m6!1s0x5317c113860ea893:0x345717444d52403e!2sGrasslands+National+Park+East+Block!8m2!3d49.071724!4d-106.529783!16s%2Fg%2F11c208gd76!3m5!1s0x5317eb5a71ccc76f:0x65661045252d2c66!8m2!3d49.073994!4d-106.534953!16s%2Fg%2F11ny8s4grh`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Val+Marie+Campground/@49.1661984,-107.7826291,12z/data=!4m11!1m3!2m2!1scampground!6e1!3m6!1s0x53166195b3866df7:0x23b1a6f8aeed398b!8m2!3d49.2428461!4d-107.7318352!15sCgpjYW1wZ3JvdW5kWgwiCmNhbXBncm91bmSSAQpjYW1wZ3JvdW5kmgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVVE0ZVdWbFdGVlJFQUXgAQA!16s%2Fg%2F11fmqby1pc`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B012'06.3%22N+107%C2%B033'59.6%22W/@49.173625,-107.5512113,12.71z/data=!4m4!3m3!8m2!3d49.20174!4d-107.56656`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Frenchman+Valley+Campground/@49.1482045,-107.5361384,12.9z/data=!4m10!1m2!2m1!1scampground!3m6!1s0x5316436c0769b3f1:0x4cc094130d3f617d!8m2!3d49.1503102!4d-107.5104042!15sCgpjYW1wZ3JvdW5kkgEKY2FtcGdyb3VuZOABAA!16s%2Fg%2F11b8_mfmxx`
      ),

      { text: "So many prairie dogs!" },
      [{ media: "20220527Dsc04043" }, { media: "20220527Dsc04054" }],

      {
        text: "I didn't come at the right time for buffalo, but on my way out of the park thinking that I wasn't going to see any, this fella was grazing beside the road!",
      },
      [{ media: "20220527Dsc04165" }, { media: "20220527Dsc04187" }],

      {
        text: "Now off to Moose Jaw! I did the tunnels of moose jaw tour, which maybe was a mistake. But I also found this cute cafe. I worked there one day and it turned into an open mic, and another night while I was leaving it happened to be the time of the parade, first one since covid.",
      },
      [{ media: "20220527Img1569" }, { media: "20220527Img1570" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/THE+HIVE/@50.3904354,-105.535096,19.13z/data=!4m15!1m8!3m7!1s0x531bdd4edc8ff711:0x63139fda10d31d1a!2sMoose+Jaw,+SK,+Canada!3b1!8m2!3d50.3915811!4d-105.5348562!16zL20vMDF3cHNs!3m5!1s0x531bdc2635401219:0x5fd5e24ef568a589!8m2!3d50.390211!4d-105.5342826!16s%2Fg%2F11h6s0g5y1`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Glitchy's+Oddities+Museum/@52.1440128,-106.6768489,17z/data=!3m1!4b1!4m6!3m5!1s0x5304f7ef37a61d27:0x1f8f3d83e61e110c!8m2!3d52.1440128!4d-106.6768489!16s%2Fg%2F11ffwb3hsj`
      ),

      { text: "headed to Prince Albert national park and saw more wildlife" },
      [
        { media: "20220527Img1634" },
        { media: "20220527Dsc04317" },
        { media: "20220527Dsc04329" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Prince+Albert+National+Park,+SK,+Canada/@53.946219,-106.7187715,10z/data=!3m1!4b1!4m6!3m5!1s0x5300f2048ece3fa9:0xfe88543255f4f6b0!8m2!3d53.9256904!4d-106.3888857!16s%2Fg%2F1tf6lw5w`
      ),

      [
        {
          text: "And of course had the treat of cracking my windshield, the last chip I fixed myself, something about this one felt like it might need a little more attention.",
        },
        { media: "20220527Img1645" },
      ],

      {
        text: "I was passing through just as the large flooding was starting to come down, but it still was causing problems.",
      },
      [{ media: "20220527Img1654" }, { media: "20220527Dsc04351" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Big+River,+SK+S0J+0E0,+Canada/@53.8070448,-107.1391879,10.64z/data=!4m6!3m5!1s0x530734a65bd39869:0xae7e3bf27faaa734!8m2!3d53.8334823!4d-107.0312225!16zL20vMGZ2Nm5i`
      ),
      [{ media: "20220527Dsc04354" }, { media: "20220527Dsc04358" }],

      {
        text: "Alberta! I made it, I stopped by Elk Island National Park and of course this UFO landing pad in St. Paul ",
      },
      latLngFromUrl(
        `https://www.google.com/maps/place/Elk+Island+National+Park/@53.6052942,-112.82414,11.67z/data=!4m6!3m5!1s0x53a0687323433ebf:0x23f0ebaeca812a6d!8m2!3d53.6028795!4d-112.8637815!16zL20vMDFreHJx`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/UFO+Landing+Pad/@53.986918,-111.324379,13.12z/data=!4m6!3m5!1s0x53a7390404f3d2cf:0xcb10bbfef8bbd12c!8m2!3d53.990986!4d-111.3054204!16s%2Fg%2F11c1tbj1ns`
      ),
      [
        { media: "20220527Img1683" },
        { media: "20220527Img1701" },
        { media: "20220527Img1731" },
      ],
      [
        { media: "20220527Img1738" },
        { media: "20220527Img1745" },
        { media: "20220527Img1748" },
      ],
      [
        {
          text: "Wind shield repaired, I parked on a ridiculous angle to try to get a little more out of my solar panels to charge up before heading to - dun dun dun - the rockies!!",
        },
        { media: "20220527Img1770" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Riverside+Nature+Trail/@53.6281382,-113.3232869,15.73z/data=!4m6!3m5!1s0x53a03d15a79f48d5:0x322b35b658575f7f!8m2!3d53.6286814!4d-113.3198878!16s%2Fg%2F11g4kz3qb_`
      ),
    ],
    adjustMapPadding: 70,
  },
  {
    published: true,
    title: "The Rockies in July",
    createdDate: "April 23, 2023",
    eventDate: ["July 12, 2022", "July 26, 2022"],
    chunks: [
      latLngFromUrl(
        `https://www.google.com/maps/place/Robb,+AB+T0E+1X0,+Canada/@53.5652494,-116.7462443,14.88z/data=!4m6!3m5!1s0x539c56ddefc7ca93:0x46d4e6c39d2c631f!8m2!3d53.233578!4d-116.9744825!16s%2Fm%2F026rl_m`
      ),
      {
        text: 'I had only ever been west of Ontario once before this adventure, and that was for a 10 day road trip through the rockies over 7 years ago. When we drove from Calgary into Banff, and the mountains came up on the horizon I cried. And this time was no less impactful. I came from Edmonton into Jasper this time, when the mountains first came up I was on the phone with Claire, and immediately said "I gotta go", pulled over and just sat with it. I came into Jasper and stayed at the overflow campground the first night, surrounded by mountains, and elk roaming through the campground grazing. I made it :)',
      },
      [{ media: "20220712Img1781" }, { media: "20220712Dsc04396" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Br%C3%BBl%C3%A9,+AB+T0E+0C0,+Canada/@53.2568982,-118.2614504,9.96z/data=!4m6!3m5!1s0x539cbe3d9a9eea1f:0x61bb72decf69a876!8m2!3d53.3087423!4d-117.8709636!16s%2Fm%2F02x4b5r`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Overflow+Camping+Auxiliaire/@52.9939773,-118.0855185,14.66z/data=!4m15!1m8!3m7!1s0x539cbe3d9a9eea1f:0x61bb72decf69a876!2zQnLDu2zDqSwgQUIgVDBFIDBDMCwgQ2FuYWRh!3b1!8m2!3d53.3087423!4d-117.8709636!16s%2Fm%2F02x4b5r!3m5!1s0x539ccdef7c7d960b:0xf727b29e3c55608e!8m2!3d53.015545!4d-118.085034!16s%2Fg%2F11jkv7pt7r`
      ),

      {
        text: "The first little hike I did was just a small one to the top of a hill, absolutely stunning views none the less. I chatted for awhile with a guy at the top who was taking a group of youth around to teach them about nature and culture, apparently the night before they had woken him up because there was a bear in the truck, it had climbed in the back window and got stuck! :O",
      },
      [{ media: "20220712Dsc04428" }, { media: "20220712Dsc04433" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/53%C2%B005'04.0%22N+118%C2%B001'21.1%22W/@53.0849962,-118.0359005,14.55z/data=!4m4!3m3!8m2!3d53.08444!4d-118.02254`
      ),
      {
        text: "driving farther into Jasper, I stopped at this rest stop and noticed one of the names of the mountains, Colin! He has his own mountain here. Turns out, the Colin peak is in the Colin Range! Jealous :P",
      },
      [{ media: "20220712Dsc04452" }, { media: "20220712Dsc04460" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/52%C2%B056'34.9%22N+118%C2%B002'20.6%22W/@52.9431996,-118.0414759,17.09z/data=!4m4!3m3!8m2!3d52.94303!4d-118.03905`
      ),
      {
        text: "The next hike was Edith Cavell, absolutely stunning. I thought the trail was only open until this little glacier, but it turned out to be fully open so I got to hike to the alpine meadow as well!",
      },
      latLngFromUrl(
        `https://www.google.com/maps/place/Mount+Edith+Cavell+Parking+Lot,+Cavell+Road,+Jasper,+AB+T0E+1E0/@52.6874286,-118.064491,15.24z/data=!4m6!3m5!1s0x5382d0fa2e895991:0x78cfa5325f6d1344!8m2!3d52.6873322!4d-118.0557847!16s%2Fg%2F11bvtb9q46`
      ),
      [{ media: "20220712Dsc04467" }, { media: "20220712Dsc04484" }],
      [{ media: "20220712Dsc04517" }, { media: "20220712Dsc04520" }],
      [{ media: "20220712Dsc04539" }, { media: "20220712Img1951" }],

      {
        text: "The drives too and from hikes often had just as much excitement :)",
      },
      [{ media: "20220712Dsc04578" }, { media: "20220712Dsc04765" }],

      {
        text: "I went and did a hike near Maligne Lake, it had a beautiful view of the lake but more importantly at the summit there were a bunch of Marmots chilling.",
      },
      latLngFromUrl(
        `https://www.google.com/maps/place/Bald+Hills+Trail,+Improvement+District+No.+12,+AB+T0E+0E0/@52.724776,-117.6631483,14z/data=!4m15!1m8!3m7!1s0x539d31f7227ec4d1:0x6b7ac4816eeceb26!2sBald+Hills+Trail,+Improvement+District+No.+12,+AB+T0E+0E0!3b1!8m2!3d52.719789!4d-117.671536!16s%2Fg%2F1td6g4n9!3m5!1s0x539d31f7227ec4d1:0x6b7ac4816eeceb26!8m2!3d52.719789!4d-117.671536!16s%2Fg%2F1td6g4n9`
      ),
      [{ media: "20220712Dsc04597" }, { media: "20220712Dsc04676" }],
      [{ media: "20220712Dsc04693" }, { media: "20220712Dsc04700" }],
      [{ media: "20220712Dsc04720" }],

      {
        text: "The continuation of the animals everywhere, the time of year I was there was right when the elk were super active, there were so many, everywhere! This was a playground in the campground that they were making great use of.",
      },
      {
        text: "Another upgrade to my setup, I got starlink, expanding the places I can work!",
      },
      latLngFromUrl(
        `https://www.google.com/maps/place/Wapiti+Campground/@52.8291034,-118.0765152,13.68z/data=!4m9!3m8!1s0x53832a687482e269:0xc7145a3b291dfcac!5m2!4m1!1i2!8m2!3d52.8338471!4d-118.0619156!16s%2Fg%2F11g_cg4dm`
      ),
      [{ media: "20220712Dsc04778" }, { media: "20220712Img1832" }],
      [
        {
          text: "And to end July, I unfortunately got covid. It was during a heat wave and I didn't really have any options but to stay in this campground (It was nice and sparse so I could stay isolated very easily).  It certainly wasn't a comfortable period of time, but there sure are worse views to have while you're sick!",
        },
        { media: "20220712Img2059" },
        { media: "20220712Dsc04786" },
      ],
    ],
    adjustMapPadding: 67,
  },
  {
    published: true,
    title: "The Rockies in August",
    createdDate: "April 24, 2023",
    eventDate: ["August 04, 2022", "August 24, 2022"],
    chunks: [
      [
        {
          text: "Now that I had recovered, I was ready to get back into the mountains and explore! I wanted to start driving the icefields parkway, but first there were a few stops in Jasper left!",
        },
        { media: "20220804Dsc04806" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/52%C2%B052'14.7%22N+118%C2%B003'45.6%22W/@52.87075,-118.0652503,17z/data=!3m1!4b1!4m4!3m3!8m2!3d52.87075!4d-118.06267?hl=en-CA`
      ),

      {
        text: "The first hike I did after I recovered was Whistlers Peak, it involved a gondola most of the way and then a fairly steep hike to the peak. I figured it would be a good way of easing back into it. It felt so good to be back out hiking!",
      },
      latLngFromUrl(
        `https://www.google.com/maps/place/Whistlers+Peak/@52.8228645,-118.1327601,13.19z/data=!4m6!3m5!1s0x5383298d74c37003:0xd75ecf6fc2edfa7b!8m2!3d52.8266666!4d-118.1322222!16s%2Fg%2F11c3w65dnh?hl=en-CA`
      ),
      [
        { media: "20220804Img2139" },
        { media: "20220804Img2140" },
        { media: "20220804Img2141" },
      ],
      [{ media: "20220804Dsc04895" }, { media: "20220804Dsc04881" }],
      [{ media: "20220804Dsc04897" }, { media: "20220804Dsc04930" }],

      { text: "Then I headed over to Maligne Canyon for a quick little hike" },
      latLngFromUrl(
        `https://www.google.com/maps/place/Maligne+Canyon/@52.9201888,-118.0010434,17z/data=!3m1!4b1!4m6!3m5!1s0x53832b085f7d51eb:0x9e4f023dd089da8!8m2!3d52.9201888!4d-117.9984631!16s%2Fm%2F0h3swb4?hl=en-CA`
      ),
      [{ media: "20220804Dsc04986" }, { media: "20220804Dsc04967" }],
      [{ media: "20220804Dsc04953" }, { media: "20220804Dsc04950" }],

      { text: "Thats all for Jasper, now it's time to start heading out." },
      [{ media: "20220804Dsc04865" }],

      {
        text: "Stopped at Athabasca Falls, super busy place because it's so accessible, but also definitely worth facing the crowds! I also found a geocache here :P",
      },
      latLngFromUrl(
        `https://www.google.com/maps/place/Athabasca+Falls/@52.6642929,-117.8841383,19.16z/data=!4m6!3m5!1s0x5382cd9199a9831f:0x92bd3ba3cc08040e!8m2!3d52.664437!4d-117.8837673!16s%2Fg%2F1q5bl1cs6?hl=en-CA`
      ),
      [{ media: "20220804Dsc04808" }, { media: "20220804Img2124" }],
      [{ media: "20220804Dsc04847" }, { media: "20220804Dsc04813" }],

      { text: "Campsite views and campsite wildlife" },
      latLngFromUrl(
        `https://www.google.com/maps/place/Whistlers+Campground/@52.8468163,-118.0910622,14.94z/data=!4m14!1m7!3m6!1s0x53832b085f7d51eb:0x9e4f023dd089da8!2sMaligne+Canyon!8m2!3d52.9201888!4d-117.9984631!16s%2Fm%2F0h3swb4!3m5!1s0x53832a44b8713483:0x2df76af36e834b7c!8m2!3d52.8504957!4d-118.0774499!16s%2Fg%2F11gdqc7pk1?hl=en-CA`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Mount+Kerkeslin+Campground/@52.6353764,-117.8725792,15.98z/data=!4m14!1m7!3m6!1s0x53832b085f7d51eb:0x9e4f023dd089da8!2sMaligne+Canyon!8m2!3d52.9201888!4d-117.9984631!16s%2Fm%2F0h3swb4!3m5!1s0x5382cdb68f13d0ab:0x135f78800b89d9f7!8m2!3d52.6339322!4d-117.8669858!16s%2Fg%2F12hsx3cyv?hl=en-CA`
      ),
      [
        { media: "20220804Img2196" },
        { media: "20220804Img2204" },
        { media: "20220804Dsc05012" },
      ],

      {
        text: "The Barber in Jasper had recommended the Geraldine Lakes hike so I had to do it, absolutely gorgeous hike, I ended up continuing up a scramble way farther than I was suppose to which made this a much longer day.",
      },
      latLngFromUrl(
        `https://www.google.com/maps/place/Geraldine+Lakes+Trailhead+Parking/@52.6373115,-117.9325621,14.21z/data=!4m14!1m7!3m6!1s0x5382cd92749d2901:0x7320bd1f98d28700!2sGeraldine+Lakes+trailhead!8m2!3d52.6652033!4d-117.8891082!16s%2Fg%2F11dxnp36k2!3m5!1s0x5382cf0d2de337fd:0xf46bb9a33c8fae26!8m2!3d52.6283643!4d-117.9143147!16s%2Fg%2F11fkqb8g1g?hl=en-CA`
      ),
      [{ media: "20220804Dsc05038" }, { media: "20220804Dsc05058" }],
      [{ media: "20220804Dsc05084" }, { media: "20220804Dsc05071" }],
      { text: "A stop for dinner at the top of the waterfall :)" },
      [{ media: "20220804Img2276" }, { media: "20220804Img2280" }],
      [{ media: "20220804Dsc05114" }, { media: "20220804Dsc05096" }],

      {
        text: "Continuing down the highway, there were just endless lookouts with breathtaking views!",
      },
      latLngFromUrl(
        `https://www.google.com/maps/place/Goats+%26+Glacier+Lookout/@52.6170446,-117.8491246,17z/data=!3m1!4b1!4m6!3m5!1s0x5382cde65d16bedb:0x30256775497b9c91!8m2!3d52.6170446!4d-117.8465443!16s%2Fg%2F11n667cb_v?hl=en-CA`
      ),
      [{ media: "20220804Dsc05147" }, { media: "20220804Dsc05132" }],

      {
        text: "Another campsite with stunning views. At this one I had some lovely conversations with someone who use to be a biologist, then worked in tech and now was traveling around working on photography projects.",
      },
      [{ media: "20220804Dsc05199" }, { media: "20220804Dsc05150" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Honeymoon+Lake+Campground/@52.5560389,-117.6826967,17z/data=!4m6!3m5!1s0x5382caa54ab172a9:0xa4b949d421f8e24e!8m2!3d52.5560389!4d-117.6811196!16s%2Fg%2F11gzh8nby?hl=en-CA`
      ),

      { text: "Sunwapta Falls" },
      [
        { media: "20220804Dsc05225" },
        { media: "20220804Dsc05213" },
        { media: "20220804Img2325" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Sunwapta+Falls/@52.5324388,-117.6476019,17z/data=!4m6!3m5!1s0x5382b549bed50a31:0x105cd6dbb12baefd!8m2!3d52.5327651!4d-117.6456814!16s%2Fg%2F11c3v5wvxx?hl=en-CA`
      ),

      {
        text: "More views, Stutfield Glacier and Tangle Creek Falls",
      },
      [{ media: "20220804Dsc05319" }, { media: "20220804Dsc05757" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Stutfield+Glacier+Viewpoint/@52.2658962,-117.2961091,13.46z/data=!4m14!1m7!3m6!1s0x5377ffd08d7bf10b:0x756dbdccbb4583a6!2sTangle+Creek+Falls,+AB!8m2!3d52.267271!4d-117.2864664!16s%2Fg%2F11b5yll26n!3m5!1s0x539d556115d5f8cf:0xf81eb61da25c6a1b!8m2!3d52.2738523!4d-117.3084487!16s%2Fg%2F11c1xjlt1l`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Tangle+Creek+Falls,+AB/@52.267271,-117.2890413,17z/data=!3m1!4b1!4m6!3m5!1s0x5377ffd08d7bf10b:0x756dbdccbb4583a6!8m2!3d52.267271!4d-117.2864664!16s%2Fg%2F11b5yll26n`
      ),

      {
        text: "I've arrived at the Athabasca Glacier, and setup camp for the night. I ended up staying here for quite a while and doing hikes around the area.",
      },
      [{ media: "20220804Img2355" }, { media: "20220804Img2352" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Wilcox+Creek+Campground/@52.2113538,-117.2201645,14.54z/data=!4m9!1m2!2m1!1sbeaty+creek+and+stanley+falls!3m5!1s0x5377feb64df99bcd:0x9b6986fd6fa4bb0b!8m2!3d52.2189566!4d-117.175702!16s%2Fg%2F11g_cgll_`
      ),

      {
        text: "I tend to avoid tours, but I wanted to walk on the glacier, and tours were the only ways to do it. It was surprisingly a wonderful experience, the guide and I ended up in a bit of doom and gloom talking about the future of the glaciers.",
      },
      [{ media: "20220804Dsc05375" }, { media: "20220804Dsc05499" }],
      [{ media: "20220804Dsc05486" }, { media: "20220804Dsc05477" }],
      [{ media: "20220804Dsc05424" }, { media: "20220804Dsc05414" }],
      {
        text: "I did a hiking tour where we walked up the glacier, but there were also these bus tours that drove onto the glacier.",
      },
      [{ media: "20220804Dsc05398" }, { media: "20220804Dsc05468" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Athabasca+Glacier+Trail/@52.2110903,-117.241509,15z/data=!4m9!1m2!2m1!1sbeaty+creek+and+stanley+falls!3m5!1s0x5377ffc109fd9261:0x3e10a9630729dfc!8m2!3d52.2108536!4d-117.2331405!16s%2Fg%2F11kbcqrj1_`
      ),

      {
        text: "Another less trafficked trail, this trail was the first time I encountered a bear on trail this time in the rockies. It sauntered off immediately but got me on my toes!",
      },
      [
        { media: "20220804Dsc05737" },
        { media: "20220804Dsc05662" },
        { media: "20220804Dsc05656" },
        { media: "20220804Dsc05647" },
      ],
      [{ media: "20220804Dsc05565" }, { media: "20220804Dsc05504" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Stanley+Falls/@52.3281474,-117.3316158,2521m/data=!3m1!1e3!4m10!1m2!2m1!1sbeaty+creek+and+stanley+falls!3m6!1s0x539d54579ca91ded:0x3ea31d2c54221ea7!8m2!3d52.3225709!4d-117.3238482!15sCh1iZWF0eSBjcmVlayBhbmQgc3RhbmxleSBmYWxsc1ofIh1iZWF0eSBjcmVlayBhbmQgc3RhbmxleSBmYWxsc5IBCXdhdGVyZmFsbJoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VSaGExbGhOekZuUlJBQuABAA!16s%2Fg%2F1thkwy_7`
      ),

      {
        text: "The Athabasca from another angle! This hike was immediately across from the Athabasca and had great views of the glacier, but also just all around. ",
      },
      [
        { media: "20220804Img2408" },
        { media: "20220804Dsc05812" },
        { media: "20220804Dsc05808" },
      ],
      [
        { media: "20220804Dsc05802" },
        { media: "20220804Dsc05789" },
        { media: "20220804Dsc05775" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Wilcox+Trail+Red+Chair+viewpoint/@52.2193109,-117.230436,14.54z/data=!4m9!1m2!2m1!1sbeaty+creek+and+stanley+falls!3m5!1s0x5377ff245f8b0cd5:0x31385007448e728e!8m2!3d52.2238!4d-117.2008!16s%2Fg%2F11tndxhp30`
      ),

      {
        text: "I started to head out from the Athabasca glacier area after hanging around there for awhile and working, and did one of my favourite trails to date. This one viewed another glacier.",
      },
      [
        { media: "20220804Img2450" },
        { media: "20220804Dsc05912" },
        { media: "20220804Dsc05894" },
        { media: "20220804Dsc05858" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Parker+Ridge+Trail/@52.1850817,-117.1229595,14.83z/data=!4m9!1m2!2m1!1sbeaty+creek+and+stanley+falls!3m5!1s0x5377f923796ae35d:0xf566ada8fbc1d14c!8m2!3d52.181636!4d-117.105325!16s%2Fg%2F11hzty0xdm`
      ),

      {
        text: 'Coming around the "Big Bend", I stopped and noticed this trail off the road to another waterfall! There is always something :)',
      },
      [{ media: "20220804Img2470" }, { media: "20220804Dsc05990" }],
      [{ media: "20220804Dsc05975" }, { media: "20220804Dsc05963" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/52%C2%B010'15.2%22N+117%C2%B004'25.6%22W/@52.170884,-117.0763569,17z/data=!3m1!4b1!4m4!3m3!8m2!3d52.170884!4d-117.073782`
      ),

      [
        {
          text: "I stopped at the weeping wall, but maybe it had a good day it wasn't weeping very much",
        },
        { media: "20220804Dsc06011" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Weeping+Wall+viewpoint/@52.1750099,-117.0911661,12.56z/data=!4m6!3m5!1s0x5377f10cc42fa7bb:0xa0d0924f57b66a55!8m2!3d52.1544404!4d-117.0096127!16s%2Fg%2F11h4xhd_zx`
      ),

      {
        text: "For my last little bit here I worked by some absolutely gorgeous glacier lakes. After this it's time to head to Calgary to pick up Claire for a visit!",
      },
      [{ media: "20220804Dsc06034" }, { media: "20220804Img2485" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Waterfowl+Lakes+Viewpoint/@51.8426607,-116.6570856,13.92z/data=!4m14!1m7!3m6!1s0x537784c3a86176d7:0x84efc28cc9dec020!2sWaterfowl+Lakes!8m2!3d51.8317285!4d-116.6141708!16s%2Fg%2F11cjmlgt74!3m5!1s0x5377850390ba6ed3:0x777527f424d871e3!8m2!3d51.8496073!4d-116.6285553!16s%2Fg%2F11fp8by57v`
      ),
      [{ media: "20220804Dsc06050" }, { media: "20220804Dsc06055" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Bow+Lake+Viewpoint/@51.6754711,-116.471775,14.68z/data=!4m14!1m7!3m6!1s0x537784c3a86176d7:0x84efc28cc9dec020!2sWaterfowl+Lakes!8m2!3d51.8317285!4d-116.6141708!16s%2Fg%2F11cjmlgt74!3m5!1s0x537779773d2c3a7b:0x3292a2490099609b!8m2!3d51.6733142!4d-116.4499103!16s%2Fg%2F11j0hvd0t4`
      ),
    ],
  },
  {
    published: true,
    title: "Claire visits The Rockies",
    createdDate: "April 25, 2023",
    eventDate: ["August 28, 2022", "September 04, 2022"],
    chunks: [
      {
        text: "After breezing through Banff to get to Calgary I picked Claire up at the airport to go back and explore Banff! We got some brunch and stopped by Monogram coffee (a must after discovering them in Winnipeg).",
      },
      [
        { media: "20220828Img2533" },
        { media: "20220828Img2531" },
        { media: "20220828683397723420733437664A948EaB06B5F952Cd4B375" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Calgary+International+Airport/@51.1064943,-114.15193,10.71z/data=!4m14!1m7!3m6!1s0x5371640f7bf63b77:0xdbe9871f5cbc7457!2sCalgary+International+Airport!8m2!3d51.1215031!4d-114.0076156!16zL20vMDFxeWpo!3m5!1s0x5371640f7bf63b77:0xdbe9871f5cbc7457!8m2!3d51.1215031!4d-114.0076156!16zL20vMDFxeWpo`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Vendome/@51.055133,-114.0834487,18z/data=!4m10!1m2!2m1!1sbrunch!3m6!1s0x53716fee671bdaa7:0xa9cfeb40b03a6fff!8m2!3d51.0552029!4d-114.0832321!15sCgZicnVuY2haCCIGYnJ1bmNokgEKcmVzdGF1cmFudJoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VRMmJVOXlURkZuRUFF4AEA!16s%2Fg%2F1tjhxbcc`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Monogram+Coffee/@51.0490673,-114.0671732,12z/data=!4m10!1m2!2m1!1smonagram!3m6!1s0x53716ffb8b253217:0x6e9e6822a6f0e1f0!8m2!3d51.0490673!4d-114.0671732!15sCghtb25vZ3JhbVoKIghtb25vZ3JhbZIBC2NvZmZlZV9zaG9wmgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVVJ4Y0c5eWNtOTNSUkFC4AEA!16s%2Fg%2F11c3x3rqz9`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Calgary+Farmers'+Market+West/@51.0851511,-114.2676461,11.42z/data=!4m14!1m7!3m6!1s0x5371640f7bf63b77:0xdbe9871f5cbc7457!2sCalgary+International+Airport!8m2!3d51.1215031!4d-114.0076156!16zL20vMDFxeWpo!3m5!1s0x53716fc09a90ffcd:0x3d321029b6cc51b8!8m2!3d51.0881871!4d-114.2219919!16s%2Fg%2F11q1tc__9w`
      ),
      {
        text: "We started heading to the rockies, and stopped by some lovely views. and this campground had a warning for... splotch bears...",
      },
      [
        { media: "20220828Img2556" },
        { media: "20220828Img2550" },
        { media: "20220828Dsc06076" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Hoodoos+Viewpoint/@51.1837077,-115.5294291,14.58z/data=!4m12!1m5!3m4!2zNTHCsDE5JzMyLjgiTiAxMTbCsDAyJzA0LjYiVw!8m2!3d51.32578!4d-116.03462!3m5!1s0x5370cb9662182239:0xf2477572c944b3e5!8m2!3d51.1889253!4d-115.5215999!16s%2Fg%2F11c1pkhq61`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Protection+Mountain+Campground/@51.3257795,-116.0449197,15z/data=!4m12!1m5!3m4!2zNTHCsDE5JzMyLjgiTiAxMTbCsDAyJzA0LjYiVw!8m2!3d51.32578!4d-116.03462!3m5!1s0x53774540e3ecbfdf:0xee006570c23ff1b6!8m2!3d51.3270937!4d-116.0383134!16s%2Fg%2F11c6q9b1r0`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Morant's+Curve/@51.4105705,-116.1412989,14z/data=!4m12!1m5!3m4!2zNTHCsDE5JzMyLjgiTiAxMTbCsDAyJzA0LjYiVw!8m2!3d51.32578!4d-116.03462!3m5!1s0x5377435e1bd58dd5:0x5daf2a66c10884da!8m2!3d51.3995234!4d-116.1271975!16s%2Fg%2F11pf5k3fqr`
      ),
      {
        text: "After an attempt to go north, I took a wrong turn and we ended up in BCs Yoho National Park. My first time ever in BC, and the farthest west I have ever been in Canada! The campground was right by a short winding drive to this wonderful waterfall hike.",
      },
      [
        { media: "20220828Dsc06116" },
        { media: "20220828Dsc06079" },
        { media: "20220828Img2561" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Kicking+Horse+Campground/@51.4273972,-116.4315263,14.93z/data=!4m15!1m5!3m4!2zNTHCsDE5JzMyLjgiTiAxMTbCsDAyJzA0LjYiVw!8m2!3d51.32578!4d-116.03462!3m8!1s0x5377616e29fc5c43:0x4e5b0b8cc6bc06d8!5m2!4m1!1i2!8m2!3d51.4230785!4d-116.4354248!16s%2Fg%2F11rcqgp44s`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Takakkaw+Falls/@51.4889821,-116.4713032,14.96z/data=!4m11!1m5!3m4!2zNTHCsDE5JzMyLjgiTiAxMTbCsDAyJzA0LjYiVw!8m2!3d51.32578!4d-116.03462!3m4!1s0x5377624b83614a0d:0xad245718899268eb!8m2!3d51.5000608!4d-116.4741325`
      ),
      {
        text: "We decided we wanted to do 1 of the teahouse hikes. Lake Louise has exploded with popularity, so we had to arrive super early to get a spot in the parking lot. We arrived before 7am to get a place, and the lot was already filling up.  We decided to do the Plain of Six Glaciers teahouse and got started. Park of this trail is shared with horse tours. We made it to the teahouse. The food was delicious and we munched it away before I got a chance to take a picture and Claire decided to hike down some garbage for the teahouse :)",
      },
      [
        { media: "20220828Img2570" },
        { media: "20220828Img2569" },
        { media: "20220828Img2568" },
      ],

      {
        text: "The whole hike was absolutely gorgeous, but the last leg took us to this view and we sat for quite awhile just appreciating the views.",
      },

      [{ media: "20220828Dsc06182" }, { media: "20220828Dsc06149" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Lake+Louise+Parking+Lot/@51.4166836,-116.2187896,15.71z/data=!4m6!3m5!1s0x53775d69fed950c7:0xc6d4acca71d3ee25!8m2!3d51.4162583!4d-116.2136598!16s%2Fg%2F11rnvn_4gy`
      ),

      {
        text: "We got one of the best sites at Mosquito creek (no mosquitos!) and stayed there for a couple days with lots of yummy food made.",
      },
      [
        { media: "20220828Img2668" },
        { media: "20220828Img2656" },
        { media: "20220828Img2606" },
      ],
      {
        text: "Picture here is a very sophisticated experiment that we did because we were curious what the temperature difference would be depending on the current. We will be publishing next year, hit me up Science. ",
      },
      [
        { media: "20220828Img6742" },
        { media: "20220828703809482502377E0Eb0Ede4C76B192D0725A169135" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Mosquito+Creek+Trailhead/@51.6303439,-116.329603,17z/data=!4m11!1m3!2m2!1scampgrounds!6e1!3m6!1s0x5377713bdb020acb:0xe70188115449cb86!8m2!3d51.6300062!4d-116.3286145!15sCgtjYW1wZ3JvdW5kc5IBC2hpa2luZ19hcmVh4AEA!16s%2Fg%2F11rfr2xlyg`
      ),

      {
        text: "There was a forest fire near by so it was fairly hazy. We popped over to Peyto lake for views of another glacier lake.",
      },
      [{ media: "20220828Img2663" }, { media: "20220828Img2661" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Peyto+Lake+Upper+Viewpoint/@51.7241029,-116.5223141,13.68z/data=!4m10!1m3!2m2!1scampgrounds!6e1!3m5!1s0x5377793a8c90468f:0x2513fddaa0f8601!8m2!3d51.7182986!4d-116.5083363!16s%2Fg%2F11f6cr61r6`
      ),

      {
        text: "And back to Calgary to drop Claire off at the airport. Before she left we got 1 more meal in Calgary which turned out to be a pizza food truck in a residential alley. Farewell Claire! I'll see you again on the west coast :)",
      },
      [
        { media: "20220828Img2679" },
        { media: "20220828Img2676" },
        { media: "20220828Img2680" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Savino+Pizzeria/@51.0258431,-114.1519797,17z/data=!4m14!1m7!3m6!1s0x53716f208cb18d5b:0xd1f6ee03df40a342!2sSavino+Pizzeria!8m2!3d51.0258431!4d-114.1494048!16s%2Fg%2F11hflf_g76!3m5!1s0x53716f208cb18d5b:0xd1f6ee03df40a342!8m2!3d51.0258431!4d-114.1494048!16s%2Fg%2F11hflf_g76`
      ),
    ],
  },
  {
    published: true,
    title: "Drumheller",
    createdDate: "April 26, 2023",
    eventDate: ["September 05, 2022", "September 17, 2022"],
    chunks: [
      [
        {
          text: "I'm in Calgary now and I have a choice, I either run back to the rockies as fast as I can because... mountains... or! I can go explore the Alberta badlands, I decided to take a week to see the badlands :) Started my day off right with sudoku, coffee, and breakfast. And even got to try out my new fun hose at the first campsite :D",
        },
        { media: "20220905Img2700" },
        { media: "20220905Img2726" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Rosso+Coffee+Roasters+-+Tuxedo/@51.07078,-114.0626125,20.28z/data=!4m12!1m5!3m4!2zNTHCsDA0JzE1LjMiTiAxMTTCsDAzJzQ1LjAiVw!8m2!3d51.07091!4d-114.06249!3m5!1s0x53716566607f9769:0xcd411688fed52706!8m2!3d51.070771!4d-114.0622363!16s%2Fg%2F11c6yckz7n`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Irricana+Campground/@51.3238015,-113.6093152,15.86z/data=!4m12!1m5!3m4!2zNTHCsDE5JzI3LjgiTiAxMTPCsDM2JzEyLjIiVw!8m2!3d51.32438!4d-113.60339!3m5!1s0x5373e9fcfde89b97:0x564a7c784670f000!8m2!3d51.3241531!4d-113.6058926!16s%2Fg%2F11clzqqdgz`
      ),

      {
        text: "The first stop was Horseshoe Canyon, it's a short stroll into the bottom, but a unique landscape I haven't seen before!",
      },
      [{ media: "20220905Dsc06265" }, { media: "20220905Dsc06277" }],
      [{ media: "20220905Dsc06262" }, { media: "20220905Dsc06247" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Horseshoe+Canyon+Campground/@51.4135995,-112.8948997,15z/data=!4m12!1m5!3m4!2zNTHCsDI0JzQ5LjAiTiAxMTLCsDUzJzA0LjYiVw!8m2!3d51.4136!4d-112.8846!3m5!1s0x53730b2e48b415d7:0x91d747ca212f0fba!8m2!3d51.4139694!4d-112.8860656!16s%2Fg%2F11hzjym7dj`
      ),

      {
        text: "I arrived in Drumheller and there was some sort of theme to this area I couldn't quite put my finger on 🤔.",
      },
      [
        { media: "20220905Dsc06311" },
        { media: "20220905Img2792" },
        { media: "20220905Img2785" },
      ],
      [
        { media: "20220905Img2780" },
        { media: "20220905Img2771" },
        { media: "20220905Img2769" },
        { media: "20220905Img2767" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/World's+Largest+Dinosaur/@51.4668522,-112.7109752,17.93z/data=!4m14!1m7!3m6!1s0x537312889e227adf:0xcc98d7bb01c92e9b!2sWorld's+Largest+Dinosaur!8m2!3d51.467266!4d-112.7088079!16s%2Fm%2F02rdmtq!3m5!1s0x537312889e227adf:0xcc98d7bb01c92e9b!8m2!3d51.467266!4d-112.7088079!16s%2Fm%2F02rdmtq`
      ),

      [
        {
          text: "I stayed the night behind this little church, there was a pretty surprising steady flow of people stopping to look at it, who knew roadside attractions could be small!",
        },
        { media: "20220905Img2797" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/The+Little+Church/@51.4793444,-112.8073962,15z/data=!3m1!4b1!4m6!3m5!1s0x53730d2f50579c33:0x5ad7ada0c7739f12!8m2!3d51.4793449!4d-112.7970965!16s%2Fg%2F11ckkx_yt4`
      ),

      [
        {
          text: "While in Drumheller, a stop at Royal Tyrrell Museum is a must, I always get disappointed when the bones are replicas (though I understand why they do it) but this museum had lots of real fossils on display!",
        },
        { media: "20220905Img2801", overrideWidth: 70 },
      ],
      [
        { media: "20220905Img2812" },
        { media: "20220905Img2802" },
        { media: "20220905Img2804" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Royal+Tyrrell+Museum+Lookout/@51.4793177,-112.7990277,15z/data=!4m14!1m7!3m6!1s0x53730d2f50579c33:0x5ad7ada0c7739f12!2sThe+Little+Church!8m2!3d51.4793449!4d-112.7970965!16s%2Fg%2F11ckkx_yt4!3m5!1s0x53730d0c385937ff:0xab907575d1d654aa!8m2!3d51.4794499!4d-112.7883737!16s%2Fg%2F11s9c63t4_`
      ),

      {
        text: "Now off to the next canyon! This one apparently stole everyone's horses... rude.",
      },
      [{ media: "20220905Dsc06338" }, { media: "20220905Dsc06326" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Horsethief+Canyon/@51.5392561,-112.8709352,16.91z/data=!4m14!1m7!3m6!1s0x537373b63e79cd69:0xef43c5fdd52305a4!2sHorsethief+Canyon!8m2!3d51.5396105!4d-112.8707008!16s%2Fg%2F11b7xkrvgm!3m5!1s0x537373b68b09a15b:0xfcd20f5322d68f99!8m2!3d51.5380517!4d-112.8688847!16s%2Fg%2F1ptx3qd5d`
      ),

      [
        {
          text: "Continuing north, and crossing the river I took the shortest ferry ride I've ever seen. This picture is before we started moving",
        },
        { media: "20220905Img2849" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Bleriot+Ferry+Port/@51.5685351,-112.9004294,13.93z/data=!4m14!1m7!3m6!1s0x537373b63e79cd69:0xef43c5fdd52305a4!2sHorsethief+Canyon!8m2!3d51.5396105!4d-112.8707008!16s%2Fg%2F11b7xkrvgm!3m5!1s0x53737164087ef015:0x8ee9bb39469fd4a4!8m2!3d51.5730488!4d-112.8858211!16s%2Fg%2F11hzpkncf6`
      ),

      {
        text: "Next stop was Torrington for a very specific and important reason, the gopher hole museum!",
      },
      [
        { media: "20220905Img2851" },
        { media: "20220905Img2913" },
        { media: "20220905Img2906" },
      ],
      {
        text: "This town took the gopher theme seriously and had all of their fire hydrants painted as gophers, there was a little booklet that described all of them and gave their backstories so you could walk around town as a self guided tour. There was even a tale of baby Jesse who went missing for a year and then showed up on the museums doorstep!",
      },
      [
        { media: "20220905Img2922" },
        { media: "20220905Img2916" },
        { media: "20220905Img2912" },
        { media: "20220905Img2905" },
      ],
      {
        text: "The gopher hole museum was a series of taxidermy gopher dioramas, they were all made by the community members contributing different pieces. I've included a small selection here, but go... you must go here :D",
      },
      [
        { media: "20220905Img2898" },
        { media: "20220905Img2891" },
        { media: "20220905Img2889" },
      ],
      [
        { media: "20220905Img2883" },
        { media: "20220905Img2880" },
        { media: "20220905Img2877" },
      ],
      [
        { media: "20220905Img2870" },
        { media: "20220905Img2869" },
        { media: "20220905Img2867" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/World+Famous+Gopher+Hole+Museum/@51.7916668,-113.6142225,15z/data=!3m1!4b1!4m6!3m5!1s0x5374709706cc09f7:0x9436029311b1aa2!8m2!3d51.7916672!4d-113.6039228!16s%2Fm%2F0cc8yt9`
      ),

      [
        {
          text: "Headed back to Drumheller, I stopped by a viewpoint of the Orkney Valley",
        },
        { media: "20220905Dsc06365" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Orkney+Viewpoint/@51.5603481,-112.9228981,13.27z/data=!4m14!1m7!3m6!1s0x5374709706cc09f7:0x9436029311b1aa2!2sWorld+Famous+Gopher+Hole+Museum!8m2!3d51.7916672!4d-113.6039228!16s%2Fm%2F0cc8yt9!3m5!1s0x537373c2d5c38601:0x846003bbd551941c!8m2!3d51.546209!4d-112.896279!16s%2Fg%2F11bv3mmskn`
      ),

      {
        text: "Hoodoos! Continuing south from Drumheller, I stopped by the hoodoo trail which has some spectacular landscape.",
      },
      [{ media: "20220905Dsc06380" }, { media: "20220905Dsc06392" }],
      [{ media: "20220905Dsc06386" }, { media: "20220905Dsc06413" }],

      {
        text: "Walking around I also found what I think are concretions, round rock formations, so cool!",
      },
      [{ media: "20220905Dsc06424" }, { media: "20220905Dsc06421" }],
      {
        text: "I stayed the night here and woke up to explore the hoodoos a little more in the morning before heading out.",
      },
      [
        { media: "20220905Dsc06431" },
        { media: "20220905684695073346Bd301Ab752C47D48Dec90A2Eab7Ae41" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Hoodoos+Trail/@51.3826827,-112.5410187,15.2z/data=!4m14!1m7!3m6!1s0x5370cb9662182239:0xf2477572c944b3e5!2sHoodoos+Viewpoint!8m2!3d51.1889253!4d-115.5215999!16s%2Fg%2F11c1pkhq61!3m5!1s0x537310bbb13aab47:0x413e22da4a7149cc!8m2!3d51.3806406!4d-112.5342411!16s%2Fg%2F11b5pk0zlh`
      ),

      [
        {
          text: "I went to Wayne, mostly for the novelty of needing to cross 11 bridges to get there... I know how to have fun!",
        },
        { media: "20220905WayneFaster", overrideWidth: 66 },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Wayne,+Drumheller,+AB+T0J+2V0/@51.3816114,-112.660209,15z/data=!3m1!4b1!4m6!3m5!1s0x53730fd489e30357:0x601277c24163baa5!8m2!3d51.3816119!4d-112.660209!16s%2Fm%2F080fwqp`
      ),

      {
        text: "Another tour, I went to the Atlas coal mine. There were only two of us on the tour, and the other guy was also living in a van and had started in Quebec! We were also both heading to Calgary next, so we met up at a campground on the way to have a fire and chat.",
      },
      [{ media: "20220905Dsc06442" }, { media: "20220905Dsc06450" }],
      [{ media: "20220905Img2966" }, { media: "20220905Dsc06435" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Atlas+Coal+Mine+National+Historic+Site/@51.3283924,-112.4865911,15.45z/data=!4m9!1m2!2m1!1smine!3m5!1s0x53731b1b48cc4121:0x6b3882357c2f804c!8m2!3d51.3290193!4d-112.4823231!16s%2Fm%2F02x0115`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/51%C2%B005'20.1%22N+114%C2%B007'59.7%22W/@51.0889095,-114.1435397,15z/data=!3m1!4b1!4m4!3m3!8m2!3d51.08891!4d-114.13324`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Canmore,+AB/@51.0675778,-115.3274561,12z/data=!3m1!4b1!4m6!3m5!1s0x5370c59595456331:0x46b6315f9e1e692e!8m2!3d51.0883666!4d-115.3479101!16zL20vMDJ5OW50`
      ),

      {
        text: "I finally mounted a gift of Vern's measurements. This is really handy when going under bridges so I don't have to remember if I can pass under them :)",
      },
      { text: "and the final sunset in Alberta, I'm heading to BC next!" },
      [{ media: "20220905Img2968" }, { media: "20220905Img2983" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/51%C2%B018'46.0%22N+116%C2%B000'32.6%22W/@51.3127795,-116.0193597,15z/data=!3m1!4b1!4m4!3m3!8m2!3d51.31278!4d-116.00906`
      ),
    ],
  },
  {
    published: true,
    title: "To Terrace!",
    createdDate: "April 27, 2023",
    eventDate: ["September 18, 2022", "September 29, 2022"],
    adjustMapPadding: 50,
    chunks: [
      {
        text: "I know I already crossed in to BC for a day earlier, but now I'm officially heading into the province! and I've crossed the continental divide!",
      },
      [{ media: "20220918Img2995" }, { media: "20220918Img2990" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Continental+Divde/@51.2198125,-116.0812557,12.54z/data=!4m6!3m5!1s0x537751ddd7e73401:0xb41648e48bd7f419!8m2!3d51.2281984!4d-116.0502776!16s%2Fg%2F11q3sdwhq9`
      ),

      {
        text: "Heading into Kootenay National Park, I stopped at Marble Canyon for a little hike",
      },
      [{ media: "20220918Dsc06528" }, { media: "20220918Dsc06562" }],
      [
        { media: "20220918Dsc06541" },
        { media: "20220918Dsc06498" },
        { media: "20220918Dsc06465" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Marble+Canyon+Trailhead/@51.1810062,-116.1695619,12.99z/data=!4m6!3m5!1s0x53775424aaf5b4d1:0x2865a6784409c315!8m2!3d51.185911!4d-116.1228744!16s%2Fg%2F11c1n9kj21`
      ),

      { text: "Next up were the paint pots, super vibrant mud!" },
      [{ media: "20220918Dsc06745" }, { media: "20220918Dsc06682" }],
      [{ media: "20220918Dsc06724" }, { media: "20220918Dsc06664" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Paint+pots/@51.1846935,-116.2036391,11.87z/data=!4m6!3m5!1s0x537755fca60dad4f:0xcbc1a24661978942!8m2!3d51.1695743!4d-116.1474619!16s%2Fg%2F11pzk4r2nh`
      ),

      {
        text: "Even the rest areas are beautiful, this was a spot I pulled off to take a break from driving and was just a short walk down river.",
      },
      [{ media: "20220918Dsc06769" }, { media: "20220918Dsc06793" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Numa+Creek+Trailhead/@51.1326191,-116.1331347,18.34z/data=!4m12!1m5!3m4!2zNTHCsDA3JzU1LjkiTiAxMTbCsDA3JzU0LjIiVw!8m2!3d51.13218!4d-116.13173!3m5!1s0x53775569ad6a41a9:0x44d90eb7a636e29d!8m2!3d51.1328933!4d-116.1325207!16s%2Fg%2F11fhvypb56`
      ),

      [
        {
          text: "I made it to Radium Hot Springs and had some fun coworking with this jolly bunch for the day.",
        },
        { media: "20220918Img3021" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Redstreak+Campground/@50.6238095,-116.0680897,15z/data=!4m15!1m5!3m4!2zNTDCsDM3JzI1LjciTiAxMTbCsDAzJzI4LjAiVw!8m2!3d50.62381!4d-116.05779!3m8!1s0x537a6fc755b2dabb:0x76f3d33148dd48dc!5m2!4m1!1i2!8m2!3d50.6209358!4d-116.0603646!16s%2Fg%2F11qh08xq9f`
      ),

      {
        text: "And oh no! I just found out my friend Veronica is moving back to Ontario at the end of the month, and I was hoping to go visit her in Terrace, so hit the road and headed to Golden (got some surprisingly good indian food) back through Yoho to start and head north, It was starting to get pretty cold in the rockies, and my tires were still some not great all season ones so I decided to stay at the bottom of the giant hill and wait for it to warm up before heading back out.",
      },
      [{ media: "20220918Img3036" }, { media: "20220918Img3032" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Indian+Kitchen/@51.0455663,-117.2996255,9.57z/data=!4m6!3m5!1s0x5379bf868dbbafd9:0xf1efeb4e64534b61!8m2!3d51.3025665!4d-116.9503326!16s%2Fg%2F11g9m5w3kz`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Monarch+Campground/@51.420621,-116.4493995,16.05z/data=!4m6!3m5!1s0x537761e83ab63f63:0xd3b39febf25a415e!8m2!3d51.421411!4d-116.4433442!16s%2Fg%2F11t6j8mt44`
      ),

      {
        text: "It's hard to see it in the second picture, but heading back up the hill I stopped by a view point of the spiral tunnel again. Theres a train tunnel that spirals underground to get up the hill because it's too steep to just go straight up. In the picture you can kinda see the train passing through the frame 3 times.",
      },
      [{ media: "20220918Dsc06831" }, { media: "20220918Dsc06837" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/The+Spiral+Tunnel+Viewpoint/@51.4362802,-116.4103118,14.78z/data=!4m6!3m5!1s0x537761605bd32e41:0x53feac4d0704c7fd!8m2!3d51.4312917!4d-116.4039279!16s%2Fg%2F11tfjrns_3`
      ),

      {
        text: "Back at whistlers campground, the coffee trailer was still operating! So I got a nice latte and had a nice chat about traveling with the barista :) ",
      },
      [{ media: "20220918Img3045" }, { media: "20220918Img3048" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Whistlers+Campground/@52.8211774,-118.106559,13.37z/data=!4m6!3m5!1s0x53832a44b8713483:0x2df76af36e834b7c!8m2!3d52.8504957!4d-118.0774499!16s%2Fg%2F11gdqc7pk1`
      ),

      {
        text: "I stopped by Mount Robson which is the tallest peak in the Canadian rockies, I unfortunately didn't get a chance to do any hiking, but I'll be back!",
      },
      [{ media: "20220918Dsc06858" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Mount+Robson+Visitor+Centre/@53.0348325,-119.2323394,16.43z/data=!4m12!1m5!3m4!2zNTPCsDAyJzA2LjciTiAxMTnCsDEzJzQxLjYiVw!8m2!3d53.03519!4d-119.22822!3m5!1s0x53837051c9f060b7:0x6e495cbc40260f83!8m2!3d53.0355003!4d-119.2289584!16s%2Fg%2F113gmr0kw`
      ),

      latLngFromUrl(
        `https://www.google.com/maps/place/54%C2%B003'20.1%22N+124%C2%B051'02.9%22W/@53.2557667,-127.0273285,6.23z/data=!4m4!3m3!8m2!3d54.05559!4d-124.85081`
      ),

      [
        {
          text: "I always wanted insuite laundry (Not all camp spots are beautiful, but sometimes they still provide good jokes :D)",
        },
        { media: "20220918Img3051" },
        {
          text: "And the big things follow me around still... a big ol' fishing rod!",
        },
        { media: "20220918Img3065" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/53%C2%B022'36.3%22N+120%C2%B021'50.1%22W/@53.3767396,-120.3741983,15z/data=!3m1!4b1!4m4!3m3!8m2!3d53.37674!4d-120.36392`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/54%C2%B023'59.9%22N+126%C2%B038'59.5%22W/@54.3997429,-126.6511301,17.91z/data=!4m4!3m3!8m2!3d54.39996!4d-126.64986`
      ),

      {
        text: "An absolutely gorgeous camping spot down on a pebble beach of a river.",
      },
      [
        { media: "20220918Img3076" },
        { media: "20220918Img3074" },
        { media: "20220918Img3072" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/55%C2%B013'07.3%22N+127%C2%B026'45.0%22W/@55.2186996,-127.4560983,15z/data=!3m1!4b1!4m4!3m3!8m2!3d55.2187!4d-127.44582`
      ),

      [{ text: "COW TRUCK" }, { media: "20220918Img3079" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/54%C2%B036'05.3%22N+128%C2%B024'10.0%22W/@54.6014596,-128.4130683,15z/data=!3m1!4b1!4m4!3m3!8m2!3d54.60146!4d-128.40279`
      ),

      {
        text: "I made it to Terrace before Veronica left! And oh my! what a beautiful home, the view was spectacular, and I had a visit from Loki, the coolest cat in the world. ",
      },
      [
        { media: "20220918Img3082" },
        { media: "20220918Img3103" },
        { media: "20220918Img3096" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Terrace,+BC/@54.2936079,-128.6957249,10.33z/data=!4m12!1m5!3m4!2zNTTCsDAzJzIwLjEiTiAxMjTCsDUxJzAyLjkiVw!8m2!3d54.05559!4d-124.85081!3m5!1s0x54749994f30f4865:0x318a4a1d19918a52!8m2!3d54.5181925!4d-128.6031539!16zL20vMDMwcmpz`
      ),

      {
        text: "We went adventuring and went to these lava fields, all of the lichen! And mushrooms had started to grow :) ",
      },
      [{ media: "20220918Img3126" }, { media: "20220918Dsc06876" }],
      [{ media: "20220918Dsc06864" }, { media: "20220918Img3117" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Nisga'a+Lava+Flow+Trailhead/@55.0974137,-128.9749306,15z/data=!4m12!1m5!3m4!2zNTXCsDA1JzM5LjYiTiAxMjjCsDU3JzU0LjIiVw!8m2!3d55.09432!4d-128.96506!3m5!1s0x540b39e77e1c5fff:0xa57c7b9986fb387c!8m2!3d55.095964!4d-128.9697855!16s%2Fg%2F11g6wd8y2w`
      ),

      { text: "A few stops at some gorgeous waterfall just off the road." },
      [{ media: "20220918Img3136" }, { media: "20220918Img3132" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Vetter+Falls/@55.1784825,-129.0614464,17.51z/data=!4m12!1m5!3m4!2zNTXCsDEwJzQxLjgiTiAxMjnCsDAzJzM1LjYiVw!8m2!3d55.17828!4d-129.05988!3m5!1s0x540b3ea0a04fd39f:0xb95751c593008812!8m2!3d55.1785888!4d-129.0607756!16s%2Fg%2F11fxvf7hfr`
      ),

      { text: "A suspension bridge and of course some mountain views" },
      [{ media: "20220918Img3118" }, { media: "20220918Img3111" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/55%C2%B011'30.3%22N+129%C2%B013'17.4%22W/@55.1922675,-129.2252337,16.33z/data=!4m4!3m3!8m2!3d55.19176!4d-129.22151`
      ),

      { text: "and this tree, I love this tree :)" },
      [{ media: "20220918Img3109" }, { media: "20220918Img3107" }],

      {
        text: "After a lovely visit, it was time for me to get out of the north before winter came. I headed to Prince Rupert to catch the ferry and had sushi at a little place by the Harbour Veronica recommended.",
      },
      [
        { media: "20220918Img3140" },
        { media: "20220918Img3141" },
        { media: "20220918Img3143" },
        { media: "20220918Img3142" },
        { media: "20220918Img3144" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Fukasaku+of+Prince+Rupert/@54.3141189,-130.3270005,15.5z/data=!4m10!1m2!2m1!1ssushi!3m6!1s0x5472d513bd3c776b:0x935b56a99c0a0324!8m2!3d54.31884!4d-130.3202666!15sCgVzdXNoaVoHIgVzdXNoaZIBEHN1c2hpX3Jlc3RhdXJhbnSaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVTTBNVTFZVmpSblJSQULgAQA!16s%2Fg%2F1ygbcdp6w`
      ),
    ],
  },
  {
    published: true,
    title: "The inside passage",
    createdDate: "April 28, 2023",
    eventDate: "September 30, 2022",
    chunks: [
      latLngFromUrl(
        `https://www.google.com/maps/place/BC+Ferry+Prince+Rupert+Terminal/@54.2907815,-130.3621589,14.98z/data=!4m15!1m8!3m7!1s0x5472d5136b7f4569:0x7107e30f4dd95612!2sPrince+Rupert,+BC!3b1!8m2!3d54.3150367!4d-130.3208187!16zL20vMDIyNDZw!3m5!1s0x5472d4fcf5db750d:0x30d9787c15811693!8m2!3d54.2977823!4d-130.3540501!16s%2Fg%2F11bzq39p2w`
      ),
      {
        text: "It's time to take the inside passage ferry! The ferry goes from Prince Rupert to Port Hardy on Vancouver Island, it takes 18 hours and the whole way you are surrounded by beautiful coastal mountains, with blankets of fog. I was worried the fog wouldn't let up at all and I'd only be able to see 10 feet in front of my face, but the weather was absolutely perfect!",
      },
      {
        text: "I stayed the night in the ferry line so that I was first in line for the 5:45AM departure",
      },
      [
        { media: "20220930Img3149" },
        { media: "20220930Img3150" },
        { media: "20220930Img3155" },
        { media: "20220930Img3253" },
      ],

      [{ media: "20220930Dsc07397" }, { media: "20220930Dsc07439" }],
      [{ media: "20220930Dsc07352" }, { media: "20220930Dsc07118" }],
      [{ media: "20220930Dsc07134" }, { media: "20220930Dsc07224" }],
      [{ media: "20220930Dsc06938" }, { media: "20220930Img3196" }],
      [{ media: "20220930Dsc06975" }, { media: "20220930Dsc07071" }],
      {
        text: "Last photos before the sunset. I spent the entire day running from port to starboard looking at ll the views, it's time to have a sit down and take a break and get ready to see Vancouver Island :) ",
      },
      [{ media: "20220930Img3316" }, { media: "20220930Img3290" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Port+Hardy+Ferry+Terminal/@50.7264404,-127.4812204,13.8z/data=!4m15!1m8!3m7!1s0x5472d5136b7f4569:0x7107e30f4dd95612!2sPrince+Rupert,+BC!3b1!8m2!3d54.3150367!4d-130.3208187!16zL20vMDIyNDZw!3m5!1s0x54647fc70abdc5ff:0xeb467b30707b156c!8m2!3d50.7246373!4d-127.4551882!16s%2Fg%2F11rc464gv1`
      ),
    ],
  },
  {
    published: true,
    title: "Vancouver Island",
    createdDate: "April 29, 2023",
    eventDate: ["October 08, 2022", "October 29, 2022"],
    chunks: [
      [
        {
          text: "I'm on Vancouver Island! After some time taking it easy after driving a little too far a little too fast, Claire and I ventured out. First stop was Old Country Market. They had a gimmick, and that gimmick worked on me, there are goats on their roof! ",
        },
        { media: "20221008Img3343" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Telegraph+Cove,+BC+V0N+3J0/@50.5453958,-126.8560793,13.5z/data=!4m15!1m8!3m7!1s0x5488a15e20ac1c5b:0x50135152a7b0fd0!2sNanaimo,+BC!3b1!8m2!3d49.1658836!4d-123.9400648!16zL20vMDF0OHJt!3m5!1s0x5463724c0c738519:0x7e74b9510f4e7d49!8m2!3d50.5492459!4d-126.832251!16zL20vMGR3eW40`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Nanaimo,+BC/@49.1894996,-123.9578186,18.41z/data=!4m6!3m5!1s0x5488a15e20ac1c5b:0x50135152a7b0fd0!8m2!3d49.1658836!4d-123.9400648!16zL20vMDF0OHJt`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Old+Country+Market+-+Goats+on+Roof/@49.3051914,-124.4339731,15z/data=!3m1!4b1!4m6!3m5!1s0x54888e5dedfb4269:0x8c90da14ab9e9504!8m2!3d49.3051919!4d-124.4236734!16s%2Fg%2F1tfmn2gr`
      ),

      {
        text: "We went for some hikes in big trees that are very hard to capture, but absolutely beautiful, and a touch overwhelming!",
      },
      [
        { media: "20221008Img3355" },
        { media: "20221008Img3350" },
        { media: "20221008Img3348" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Cathedral+Grove/@49.2896028,-124.6657804,16.15z/data=!4m12!1m5!3m4!2zNDnCsDE3JzI0LjMiTiAxMjTCsDM5JzQzLjkiVw!8m2!3d49.29008!4d-124.6622!3m5!1s0x5488f4918ef12d3b:0xe8c21ef30642be2b!8m2!3d49.2911376!4d-124.6628522!16s%2Fg%2F1hm48wz8c`
      ),

      {
        text: "More exploration! I hear in spring the water flows through here much faster :)",
      },
      [{ media: "20221008Img3360" }, { media: "20221008Img3358" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Hole+in+the+Wall+Trail+Head/@49.2609016,-124.7568229,15.21z/data=!4m12!1m5!3m4!2zNDnCsDE1JzM5LjQiTiAxMjTCsDQ0JzUyLjYiVw!8m2!3d49.26094!4d-124.74793!3m5!1s0x5488f790d20de78d:0xf7c12d7caaff3db!8m2!3d49.2652621!4d-124.7459015!16s%2Fg%2F11q82jbpb4`
      ),

      {
        text: "We hike Mount Benson, it was a super fun steep climb and had a wonderful view of the ocean from the top. We got there right before the fog rolled in and you could see anything which is always my favourite time as it transitions from clear to foggy.",
      },
      [{ media: "20221008Img3375" }, { media: "20221008Img3365" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Mount+Benson+Regional+Park,+Nanaimo,+BC/@49.1497583,-124.0655136,14.48z/data=!4m12!1m5!3m4!2zNDnCsDA5JzAxLjQiTiAxMjTCsDAzJzA4LjUiVw!8m2!3d49.15038!4d-124.05235!3m5!1s0x5488a3270999758b:0x7ecbabc06d3eb21a!8m2!3d49.152093!4d-124.0452531!16s%2Fg%2F11bvthh5lc`
      ),

      [
        {
          text: "Maya who I work with made everyone these amazing mugs! It has become my tea mug :)",
        },
        { media: "20221008Img3377", overrideWidth: 40 },
      ],

      latLngFromUrl(
        `https://www.google.com/maps/place/Skutz+Fall+parking+lot+trail+head/@48.7832495,-123.9658497,15z/data=!4m12!1m5!3m4!2zNDjCsDQ2JzU5LjciTiAxMjPCsDU3JzIwLjAiVw!8m2!3d48.78325!4d-123.95555!3m5!1s0x548f3675aaff44ad:0x7601101e5cf455e3!8m2!3d48.7816515!4d-123.9489771!16s%2Fg%2F11f3rdlst_`
      ),
      // { text: "" },
      // [{ media: "20221008Img3390" }, { media: "20221008Img3387" }],

      {
        text: "I decided to head out to Carmanah to try to do the Heaven Tree hike, this was a stop along the way on Lake Cowichan",
      },
      [{ media: "20221008Img3395" }, { media: "20221008Img3397" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Spring+Beach+Recreation+Site/@48.8450081,-124.1186588,17.56z/data=!4m12!1m5!3m4!2zNDjCsDUwJzQzLjAiTiAxMjTCsDA2JzU3LjQiVw!8m2!3d48.84529!4d-124.11595!3m5!1s0x5488cb2d4ef579a1:0x6354856528751681!8m2!3d48.8445664!4d-124.1175612!16s%2Fg%2F11gj4m92yk`
      ),

      {
        text: "I found a hike with huge trees, but it turned out to be the wrong hike. It was getting a little late so it was not very bright and these pictures didn't turn out super well. But it was incredible hiking down this valley and every time I thought I had seem the biggest tree another one would come up. ",
      },
      [{ media: "20221008Img3405" }, { media: "20221008Img3411" }],
      [
        { media: "20221008Img3409" },
        { media: "20221008Img3415" },
        { media: "20221008Img3402" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place//@48.6613017,-124.8133872,12.88z/data=!4m6!1m5!3m4!2zNDjCsDUyJzA3LjEiTiAxMjTCsDMyJzI0LjkiVw!8m2!3d48.86864!4d-124.54026`
      ),

      { text: "Another adventure, lets head out to Tofino! " },
      [{ media: "20221008Img3426" }, { media: "20221008Img3436" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Wally+Creek/@49.214175,-125.369706,17z/data=!4m12!1m5!3m4!2zNDnCsDEyJzUxLjIiTiAxMjXCsDIyJzA2LjQiVw!8m2!3d49.21421!4d-125.36844!3m5!1s0x5489a993c9e0ded7:0xfe27a42146215bfe!8m2!3d49.2147673!4d-125.3673115!16s%2Fg%2F11t6yvjwjk`
      ),

      {
        text: "We went to this beach which was at the end of a boardwalk through a lush forest.",
      },
      [{ media: "20221008Img3438" }, { media: "20221008Img3442" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Tonquin+Beach/@49.1449888,-125.9191858,16.05z/data=!4m12!1m5!3m4!2zNDnCsDA4JzQ4LjMiTiAxMjXCsDU0JzQ2LjQiVw!8m2!3d49.14676!4d-125.91288!3m5!1s0x548990cefa833119:0x3d019617a5ff1999!8m2!3d49.1447387!4d-125.9146774!16s%2Fg%2F11g8tzc3sf`
      ),

      [
        {
          text: "In the town, this tree was supported by a series of metal rings and wires. So neat!",
        },
        { media: "20221008Img3452" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Eik+Cedar/@49.1506232,-125.9001346,18.08z/data=!4m15!1m8!3m7!1s0x548990ccb0093843:0x331095695a741a35!2sTofino,+BC!3b1!8m2!3d49.1529842!4d-125.9066184!16zL20vMDF0eHk4!3m5!1s0x5489971a5fcfa76f:0x7a4886df5ce6ac22!8m2!3d49.151393!4d-125.8988708!16s%2Fg%2F11tnmp80s7`
      ),

      [
        {
          text: "we headed out of Tofino to Ucluelet for a beautiful coastal hike.",
        },
        { media: "20221008Img3467" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Ucluelet+Lighthouse+Loop/@48.9259517,-125.5438336,15.39z/data=!4m15!1m8!3m7!1s0x548990ccb0093843:0x331095695a741a35!2sTofino,+BC!3b1!8m2!3d49.1529842!4d-125.9066184!16zL20vMDF0eHk4!3m5!1s0x548973b5b4a30cf3:0x66a4be472b5d86ee!8m2!3d48.9244994!4d-125.537293!16s%2Fg%2F11cs16jny1`
      ),
      [
        { media: "20221008Img3473" },
        { media: "20221008Img3455" },
        { media: "20221008Img3482" },
      ],

      {
        text: "Next up is heading back to Odessa for a quick visit, my parents were retiring so Hallie and I were coming home for a bit for a little celebration. I took the ferry to Vancouver, which was my first time in the city. I didn't have a chance to do much but I did get laksa for dinner which was amazing before continuing to Abbotsford to catch my plane. I landed in Toronto and Hallie and I got some food before heading to Odessa.",
      },
      [
        { media: "20221008Img3493" },
        { media: "20221008Img3501" },
        { media: "20221008Img3505" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/BC+Ferries+Horseshoe+Bay+Ferry+Terminal/@49.3744327,-123.2770931,15.34z/data=!4m15!1m8!3m7!1s0x54866bf6eb08be55:0x4a3142df8f4c1358!2sHorseshoe+Bay,+West+Vancouver,+BC!3b1!8m2!3d49.3730834!4d-123.2773396!16zL20vMDNibV92!3m5!1s0x54866bf646ca20b3:0x2773c39c6eb5489e!8m2!3d49.3758409!4d-123.2714467!16s%2Fg%2F11fy_nfmll`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Laksa+King/@49.2809884,-123.0635691,15z/data=!3m1!4b1!4m6!3m5!1s0x548671221ee63f51:0xab43f1d3af118707!8m2!3d49.2809889!4d-123.0532694!16s%2Fg%2F113dmt7q0`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Abbotsford+International+Airport/@49.0238794,-122.3745004,15z/data=!3m1!4b1!4m6!3m5!1s0x5485b561e726e31d:0xa4bc6848009df9f2!8m2!3d49.0238799!4d-122.3642007!16zL20vMDQ4dmow`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Toronto+Pearson+International+Airport/@43.6777171,-79.6351194,15z/data=!3m1!4b1!4m6!3m5!1s0x882b394ac02dd491:0xb41d5de9c4030ec5!8m2!3d43.6777176!4d-79.6248197!16zL20vMDZrOWw3`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Tinuno/@43.6711514,-79.3769462,17z/data=!4m6!3m5!1s0x89d4cbc79d19583b:0x1272e20227c3b848!8m2!3d43.6711355!4d-79.3748685!16s%2Fg%2F11kc8g22bv`
      ),

      {
        text: "And I'm back in BC! I decided to go for a day trip to Vancouver island and Claire and I would do a little adventuring down to Victoria. Some absolutely gorgeous hikes and we were there at the right time because fish were traveling up stream! So fun and interesting to get to see that!",
      },
      [{ media: "20221008Img3546" }, { media: "20221008Img3560" }],
      [
        { media: "20221008Img3553" },
        { media: "20221008Img3557" },
        { media: "20221008Img3549" },
      ],
    ],
  },
  {
    published: true,
    title: "Vancouver Winter Part 1",
    createdDate: "April 30, 2023",
    eventDate: ["October 30, 2022", "December 28, 2022"],
    chunks: [
      [
        {
          text: "Back on the mainland and it's time for winter! The plan is to try to learn to snowboard. But first... Frog cafe, there were many frogs.",
        },
        { media: "20221030Img3570", overrideWidth: 30 },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Wood+N+Frog+Coffee+Company/@49.0244218,-123.0666428,20.15z/data=!4m12!1m5!3m4!2zNDnCsDAxJzI4LjQiTiAxMjPCsDAzJzU5LjAiVw!8m2!3d49.02455!4d-123.0664!3m5!1s0x5485e6609c19727b:0x8313008a81ad6f69!8m2!3d49.0245174!4d-123.0665074!16s%2Fg%2F1hhjz_m95`
      ),
      {
        text: "I spent some time in Fort Langley, Claire came and visited and we ventured into Golden Ears Provincial Park for some hiking before it got too cold up there.",
      },
      [
        { media: "20221030Img3579" },
        { media: "20221030Img3600" },
        { media: "20221030Img3591" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Fort+Camping/@49.1748485,-122.5821341,16z/data=!4m10!1m2!2m1!1sfort+langley+camping!3m6!1s0x5485d2f9730d5269:0xd6d9bd2a7b4536cb!8m2!3d49.175698!4d-122.5796516!15sChRmb3J0IGxhbmdsZXkgY2FtcGluZ1oWIhRmb3J0IGxhbmdsZXkgY2FtcGluZ5IBB3J2X3BhcmuaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVTjFMVTFmZDNGM1JSQULgAQA!16s%2Fg%2F1td00506`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/North+Beach+Trail/@49.3319695,-122.4616847,16.43z/data=!4m12!1m5!3m4!2zNDnCsDE5JzU1LjEiTiAxMjLCsDI3JzI5LjciVw!8m2!3d49.33197!4d-122.45824!3m5!1s0x54842849af3092a5:0x297751b2d36cfd14!8m2!3d49.3329686!4d-122.4578939!16s%2Fg%2F11gd20ysf2`
      ),

      [
        {
          text: "Spending the winter in BC still means new tires if I want to go to the ski hills, check out my new kicks!",
        },
        { media: "20221030Img3613" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Surrey+Libraries+-+Newton+Branch/@49.1305996,-122.84024,18.73z/data=!4m12!1m5!3m4!2zNDnCsDA3JzUwLjciTiAxMjLCsDUwJzIxLjgiVw!8m2!3d49.13074!4d-122.83938!3m5!1s0x5485da339d02ce4d:0x248f8e8de263a21!8m2!3d49.1302264!4d-122.8397062!16s%2Fg%2F1jd2yjqm3`
      ),

      {
        text: "One more trip into Golden Ears before leaving Fort Langley for a bit",
      },
      [{ media: "20221030Img3616" }, { media: "20221030Img3622" }],
      [{ media: "20221030Img3624" }, { media: "20221030Img3615" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Spirea+Nature+Trail/@49.282663,-122.5013498,16.89z/data=!4m12!1m5!3m4!2zNDnCsDE3JzAwLjMiTiAxMjLCsDI5JzU1LjciVw!8m2!3d49.28341!4d-122.49881!3m5!1s0x54842b09333bc8e5:0x3c2d7a8db67ddddc!8m2!3d49.283616!4d-122.4984882!16s%2Fg%2F11j00j_nrm`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Lower+Falls+Trail/@49.3440195,-122.4651897,15z/data=!4m12!1m5!3m4!2zNDnCsDIwJzM4LjUiTiAxMjLCsDI3JzE3LjYiVw!8m2!3d49.34402!4d-122.45489!3m5!1s0x548429a14b6b232f:0xd8984a3b33865044!8m2!3d49.3430664!4d-122.4546753!16s%2Fg%2F11flw3rsyf`
      ),

      {
        text: "Leaving Fort Langley I decided to drive up the coast to Squamish, an absolutely beautiful drive. Halfway up, theres a all season campground with incredible views.",
      },
      [
        { media: "20221030Img3647" },
        { media: "20221030Img3646" },
        { media: "20221030Img3649" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Porteau+Cove+Campground/@49.5573055,-123.2385556,17z/data=!4m6!3m5!1s0x5487045555555555:0x6b51b8e871db79d1!8m2!3d49.5573055!4d-123.2368068!16s%2Fg%2F11gfb_g4h8`
      ),

      { text: "Gotta head over to Cat lake to catch some rays" },
      [
        { media: "20221030Img3655" },
        { media: "20221030Img3657" },
        { media: "20221030Img3659" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Cat+Lake+Recreation+Site+-+Squamish/@49.8015097,-123.1104793,19z/data=!4m12!1m5!3m4!2zNDnCsDQ4JzA1LjMiTiAxMjPCsDA2JzM0LjUiVw!8m2!3d49.80146!4d-123.10958!3m5!1s0x5486fd9fd0e700c1:0xf1451618cc797051!8m2!3d49.8017438!4d-123.1098292!16s%2Fg%2F11rw7jzsgb`
      ),

      {
        text: "In Squamish I ended up spending the night up a logging road with mountain views. It's starting too get to cold to do this without power to heat the van, but definitely worth it!",
      },
      [{ media: "20221030Img3661" }, { media: "20221030Img3660" }],
      [{ media: "2022103020221119150646Nf" }, { media: "20221030Img3678" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B049'12.5%22N+123%C2%B007'04.5%22W/@49.8201495,-123.1282097,15z/data=!3m1!4b1!4m4!3m3!8m2!3d49.82015!4d-123.11791`
      ),

      latLngFromUrl(
        `https://www.google.com/maps/place/Alice+Lake+Picnic+Area/@49.7775295,-123.1335797,15z/data=!4m12!1m5!3m4!2zNDnCsDQ2JzM5LjEiTiAxMjPCsDA3JzIzLjgiVw!8m2!3d49.77753!4d-123.12328!3m5!1s0x5486fbe6c6255b0f:0xa8f37697a7bdba74!8m2!3d49.7790272!4d-123.1246161!16s%2Fg%2F11f9dq1ntt`
      ),

      [
        {
          text: "Headed back into Vancouver for a bit to grab some things. Ended up at Nemesis for breakfast and fancy coffee in a box",
        },
        { media: "20221030Img3704" },
        { media: "20221030Img3702" },
        { media: "20221030Img3706" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Nemesis+Coffee/@49.3103291,-123.0811631,20.1z/data=!4m12!1m5!3m4!2zNDnCsDE4JzM3LjgiTiAxMjPCsDA0JzUxLjIiVw!8m2!3d49.3105!4d-123.08088!3m5!1s0x5486719ac88ff187:0xf7054f4a61bf6666!8m2!3d49.3104407!4d-123.0808842!16s%2Fg%2F11h8ghj39q`
      ),

      {
        text: "A cold snap came, so I was definitely in need of power to heat the van. I headed back up to Porteau Cove to wait for the snow and cold to pass.",
      },
      { media: "20221030Img3728" },

      {
        text: "With the weather starting to warm up again, it's time to get a gas heater installed! Perfect timing! Back to Vancouver on my way to get the heater installed.",
      },
      { media: "20221030Img3744" },
      latLngFromUrl(
        `https://www.google.com/maps/place/Spanish+Banks+Central+Parking+Lot/@49.2775307,-123.2260991,18.2z/data=!4m12!1m5!3m4!2zNDnCsDE2JzM5LjYiTiAxMjPCsDEzJzE2LjUiVw!8m2!3d49.27766!4d-123.22124!3m5!1s0x548673a7269972ed:0x3b8994ce4080828a!8m2!3d49.2776248!4d-123.2251193!16s%2Fg%2F11frn9qqbt`
      ),

      [
        {
          text: "Vern getting the heater installed! I opted to not do this myself cause I didn't want to tap into the fuel tank :P I also got lunch at a poke place that let you bring your own bowl and got very excited!",
        },
        { media: "20221030Img3749" },
        { media: "20221030Img3746" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Pokerrito/@49.2007773,-122.9813667,18.66z/data=!4m12!1m5!3m4!2zNDnCsDEyJzAzLjEiTiAxMjLCsDU4JzQ5LjMiVw!8m2!3d49.20087!4d-122.98035!3m5!1s0x5486771d4928ad03:0xbb7f10f66b3df986!8m2!3d49.200472!4d-122.9807817!16s%2Fg%2F11h4cgsdct`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Thermo+King+of+British+Columbia/@48.5878158,-123.4133094,7.51z/data=!4m10!1m2!2m1!1sthermo+king!3m6!1s0x5485d7c547ee26b5:0xf2706bc61d95ebfc!8m2!3d49.2271405!4d-122.8347255!15sCgt0aGVybW8ga2luZyIDiAEBWg0iC3RoZXJtbyBraW5nkgEecmVmcmlnZXJhdGVkX3RyYW5zcG9ydF9zZXJ2aWNl4AEA!16s%2Fg%2F1vxz99c0`
      ),

      {
        text: "Now that I have a heater, I can go anywhere! To the ski hill! I headed over to Vancouver Island again to hit up Mount Washington with Claire. We were obviously amazing right away.",
      },
      [{ media: "20221030Img3766" }, { media: "20221030Img3769" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Mount+Washington+Skiing+Alpine+Resort/@49.7413173,-125.3030741,17z/data=!3m2!4b1!5s0x548822f0a3aae725:0xb3ec76cbcb24a83c!4m6!3m5!1s0x548822f0a25d8c1d:0x2587a153025666e6!8m2!3d49.7413173!4d-125.3030741!16zL20vMDk4Y3Ns`
      ),

      [
        { text: "These were some fun trees at a campsite... that is all :D" },
        { media: "20221030Img3773" },
        { media: "20221030Img3775" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Living+Forest+Oceanside+Campground+%26+RV+Park/@49.1325172,-123.9178573,15.75z/data=!4m12!1m5!3m4!2zNDnCsDA3JzU2LjIiTiAxMjPCsDU0JzM4LjAiVw!8m2!3d49.13229!4d-123.91055!3m5!1s0x5488a43b34cb068d:0x740585c8a8747150!8m2!3d49.1307693!4d-123.9120821!16s%2Fg%2F1tz75nzm`
      ),

      [
        {
          text: "Another weekend, another day to hit the slopes! Grabbing some pizza along the way",
        },
        { media: "20221030Img3786" },
        { media: "20221030Img3777" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Salt+Pizzeria/@49.3370592,-124.3507161,18.05z/data=!4m12!1m5!3m4!2zNDnCsDIwJzE0LjAiTiAxMjTCsDIwJzU5LjEiVw!8m2!3d49.33721!4d-124.34976!3m5!1s0x54888f78d0de3bbb:0xb35bc08d07215e70!8m2!3d49.33735!4d-124.3494639!16s%2Fg%2F11dympbtc2`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Mount+Washington+Skiing+Alpine+Resort/@49.7413173,-125.3030741,17z/data=!3m2!4b1!5s0x548822f0a3aae725:0xb3ec76cbcb24a83c!4m6!3m5!1s0x548822f0a25d8c1d:0x2587a153025666e6!8m2!3d49.7413173!4d-125.3030741!16zL20vMDk4Y3Ns`
      ),

      { text: "Here you can see me practicing my cool stance." },
      [{ media: "20221030Img7217" }, { media: "20221030Img3789" }],

      [
        { text: "Claire's birthday dinner and binary cake!" },
        { media: "20221030Img3801" },
        { media: "20221030Img3803" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B011'22.7%22N+123%C2%B057'26.1%22W/@49.1889015,-123.9598091,16.62z/data=!4m4!3m3!8m2!3d49.18964!4d-123.95724`
      ),

      [
        {
          text: "I was heading home in January to visit the family instead of traveling during Christmas, so Claire prepared me a stocking and I had a lovely sunny coastal view :)",
        },
        { media: "20221030Img3824" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Union+Bay+Boat+Launch/@49.5853213,-124.8874746,18.53z/data=!4m12!1m5!3m4!2zNDnCsDM1JzA5LjMiTiAxMjTCsDUzJzEzLjYiVw!8m2!3d49.58591!4d-124.8871!3m5!1s0x54886be977e40209:0x26b22656074f0d11!8m2!3d49.5844841!4d-124.8862686!16s%2Fg%2F1hc41y5mp`
      ),
      [{ media: "20221030Img3841" }, { media: "20221030Img3842" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Goose+Spit+Park/@49.6643568,-124.9059052,16.58z/data=!4m12!1m5!3m4!2zNDnCsDM5JzUwLjciTiAxMjTCsDU0JzEwLjMiVw!8m2!3d49.66408!4d-124.90286!3m5!1s0x5488133015131ddd:0xa0324210e0c1cf8c!8m2!3d49.6653093!4d-124.9015792!16s%2Fg%2F12601dynf`
      ),

      [
        {
          text: "And now it's time to head back to Ontario for another visit. So long Vancouver Island!",
        },
        { media: "20221030Img3851" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B012'23.9%22N+123%C2%B056'15.8%22W/@49.2066495,-123.9480297,15z/data=!3m1!4b1!4m4!3m3!8m2!3d49.20665!4d-123.93773`
      ),
    ],
  },
  {
    published: true,
    title: "A Toronto New Years",
    createdDate: "May 01, 2023",
    eventDate: ["December 31, 2022", "January 25, 2023"],
    chunks: [
      {
        text: "Keep the tradition alive, even if I'm on the other side of the country!  I went back to toronto to celebrate new years with Adrian and Sennah, we decided we love kamayan feats, but we've never tried to make one so this new years we did it! Results were awesome :)",
      },
      [{ media: "20221231Img3865" }, { media: "20221231Img3873" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Toronto+Pearson+International+Airport/@43.7055638,-79.5032359,10.47z/data=!4m15!1m8!3m7!1s0x89d4cb90d7c63ba5:0x323555502ab4c477!2sToronto,+ON!3b1!8m2!3d43.653226!4d-79.3831843!16zL20vMGg3aDY!3m5!1s0x882b394ac02dd491:0xb41d5de9c4030ec5!8m2!3d43.6777176!4d-79.6248197!16zL20vMDZrOWw3`
      ),

      {
        text: "I ordered a journal cause I'm really going to start journaling this year (included a personalized message to myself). Adrian gave me my first tarot card reading, and we went for brunch with Camille and Chris!",
      },
      [
        { media: "20221231Img3881" },
        { media: "20221231Img3880" },
        { media: "20221231Img3879" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Good+Fork/@43.6498489,-79.4382859,17z/data=!3m1!4b1!4m6!3m5!1s0x882b36882be5e61b:0x22ec3ac9b3aecb64!8m2!3d43.649849!4d-79.433415!16s%2Fg%2F1tdylvjd`
      ),

      { text: "A fishy snack and Adrian's birthday cake." },
      [
        { media: "20221231Img3883" },
        { media: "20221231Img3882" },
        { media: "20221231Img3887" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Colborne+Lodge/@43.6310816,-79.4505331,15.01z/data=!4m6!3m5!1s0x882b342b58202641:0x902c201ef2e2606c!8m2!3d43.6404149!4d-79.4599726!16zL20vMDhwY3Q0`
      ),

      {
        text: "We went for dinner at Lauren and Caleb's where caleb made us some delicious lobster ravioli, and also this amazing tiramisu. Another day I went and met up with Brendan and had this lovely walk back in the snow.",
      },
      [
        { media: "20221231Img3893" },
        { media: "20221231Img3900" },
        { media: "20221231Img3899" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Odessa,+ON+K0H+2H0/@44.2762836,-76.7274882,15z/data=!3m1!4b1!4m6!3m5!1s0x4cd2b2ae32702fa7:0x83a16128634144bc!8m2!3d44.2770379!4d-76.7182925!16zL20vMDM2Z3Fn`
      ),

      [
        {
          text: "More feasts! Adrian, Sennah and I killing it with the meals :D",
        },
        { media: "20221231Img3927" },
        { media: "20221231Img3908" },
      ],

      [
        {
          text: "Off to Odessa now to visit with the family! We played quite a few games a blitz",
        },
        { media: "20221231Img3999" },
        { media: "20221231Img3936" },
      ],

      [
        {
          text: "More feasts in Odessa as well, Colin made us Khao Soi, and I made a turkey dinner (it sure doesn't photograph well :P)",
        },
        { media: "20221231Img3965" },
        { media: "20221231Img3990" },
      ],

      {
        text: "And thats it, back to the west coast! This time with a new routine where I write in my journal and read before bed :)",
      },
      [{ media: "20221231Img4006" }, { media: "20221231Img4020" }],
    ],
  },
  {
    published: true,
    title: "Vancouver Winter Part 2",
    createdDate: "May 02, 2023",
    eventDate: ["January 29, 2023", "March 10, 2023"],
    chunks: [
      {
        text: "I'm back in BC so it's time to go back to learning how to snowboard again. First stop was Mt. Seymour, and after a day on the hill a hot chocolate makes a perfect treat :)",
      },
      [
        { media: "20230129Img4038" },
        { media: "20230129Img4037" },
        { media: "20230129Img4040" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Mt+Seymour+Resort/@49.3661513,-122.9581568,15z/data=!3m1!4b1!4m6!3m5!1s0x5485db8bc349ac9b:0x837bedfc524c790d!8m2!3d49.3661518!4d-122.9478571!16s%2Fg%2F1tngpz_s`
      ),

      latLngFromUrl(
        `https://www.google.com/maps/place/Burdy/@49.2804016,-123.0815772,17.06z/data=!4m12!1m5!3m4!2zNDnCsDE2JzUzLjYiTiAxMjPCsDA0JzQ0LjQiVw!8m2!3d49.28156!4d-123.07899!3m5!1s0x548671a75ec63fed:0x79c7d67e8c9b88bc!8m2!3d49.282172!4d-123.0786751!16s%2Fg%2F11nn5lhztq`
      ),

      [
        { text: "Next I went to Cypress for another day on the hill" },
        { media: "20230129Img4073" },
        { media: "20230129Img4072" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Cypress+Mountain/@49.3940043,-123.2087013,16.06z/data=!3m1!5s0x5486692b7e93a65d:0x2f731a76f03c3548!4m6!3m5!1s0x548660d698f59675:0x821712a6c6d32ff3!8m2!3d49.396018!4d-123.204545!16zL20vMDQ2cXlq`
      ),

      {
        text: "I went to try this cafe in a lumber yard, they were super delightful :)",
      },
      [
        { media: "20230129Img4096" },
        { media: "20230129Img4095" },
        { media: "20230129Img4068" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Northern+Cafe/@49.2084922,-123.0827716,15z/data=!3m2!4b1!5s0x5486744ade486a73:0x921526cb3923947a!4m6!3m5!1s0x5486744b1c355a99:0xd707375c4a088427!8m2!3d49.2084927!4d-123.0724719!16s%2Fg%2F1hc1b01q_`
      ),

      [
        {
          text: "And then I went back to cypress! I learned that you have to get out early at these hills by the city, because by noon it's too packed!",
        },
        { media: "20230129Img4142" },
        { media: "20230129Img4140" },
      ],

      [
        {
          text: "Some treats from the weeks, I got a delightful chicken parm sandwich from Burdy, and bungeoppang from a winter only outdoor food cart :)",
        },
        { media: "20230129Img4054" },
        { media: "20230129Img4157" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Kim's+Korean+Waffles+%26+Pancakes+-+FLTF+International,+4501+North+Rd,+Burnaby,+BC+V3N+4R7/@49.2439595,-122.9037997,15z/data=!4m11!1m5!3m4!2zNDnCsDE0JzM4LjMiTiAxMjLCsDUzJzM2LjYiVw!8m2!3d49.24396!4d-122.8935!3m4!1s0x5486783d4c279761:0x1561fa74d69dfcef!8m2!3d49.2439102!4d-122.8935331`
      ),

      [
        {
          text: "There was a pretty big snowfall and I didn't really want to go driving for a bit so I hung out in  a cute  park and went for a little walk in the woods. I made a little snowman to keep watch :)",
        },
        { media: "20230129Img4166" },
        { media: "20230129Img4170" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Mundy+Park+Hillcrest+Parking+Lot/@49.2558347,-122.8378021,16.56z/data=!4m12!1m5!3m4!2zNDnCsDE1JzIzLjUiTiAxMjLCsDQ5JzQ1LjQiVw!8m2!3d49.25652!4d-122.82929!3m5!1s0x5486788e8add71c1:0x6a8325c79219d782!8m2!3d49.2549313!4d-122.8341988!16s%2Fg%2F11b8twsvwr`
      ),

      [
        {
          text: "Hot damn, I went to Lift for brunch and it was... amazing... would recommend :)",
        },
        { media: "20230129Img4204" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Lift+Breakfast+Bakery+Ltd/@49.3120921,-123.079531,19.4z/data=!4m12!1m5!3m4!2zNDnCsDE4JzQzLjEiTiAxMjPCsDA0JzQzLjgiVw!8m2!3d49.31198!4d-123.07883!3m5!1s0x5486704c5f4f0e83:0xa4aca2b9286a7098!8m2!3d49.3120725!4d-123.0787358!16s%2Fg%2F11bzykgp32`
      ),

      {
        text: "Time to head to Penticton to get a roof rack! some views from the drive.",
      },
      [{ media: "20230129Dsc07624" }, { media: "20230129Dsc07613" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/View+Point/@49.2198623,-119.9528736,16.08z/data=!4m11!1m5!3m4!2zNDnCsDEzJzEyLjEiTiAxMTnCsDU2JzUwLjAiVw!8m2!3d49.22004!4d-119.94721!3m4!1s0x5482fd3ab752464f:0x4b147f4f5cfa4c34!8m2!3d49.2200919!4d-119.9479124`
      ),

      [
        {
          text: "I got to Penticton the night before I needed to go for the roof rack, so I headed up a mountain for the night. It started to snow, but luckily the road melted before I had to leave :)",
        },
        { media: "20230129Img4250" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Garnet+Fire+Interpretive+Centre/@49.4734657,-119.5080602,17.71z/data=!4m12!1m5!3m4!2zNDnCsDI4JzIyLjgiTiAxMTnCsDMwJzI3LjMiVw!8m2!3d49.47299!4d-119.50758!3m5!1s0x548288608fad8847:0xf7126a95ac7fab34!8m2!3d49.4735029!4d-119.5065737!16s%2Fg%2F113jtft18`
      ),

      [
        {
          text: "Roof rack install! I didn't stay inside Vern for that long :P",
        },
        { media: "20230129Img4253" },
        { media: "20230129Img4254" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Curious+Campervans/@49.4845462,-119.5945016,17z/data=!3m1!4b1!4m6!3m5!1s0x54828927427afc65:0xb0a19d363317a9a0!8m2!3d49.4845462!4d-119.5945016!16s%2Fg%2F11h6lm0js_`
      ),

      [
        {
          text: "Now back to Vancouver! I stopped by the Yellow Deli in Chilliwack. Really good sandwich... And then I visited Gill who I hadn't seen in years!",
        },
        { media: "20230129Img4258" },
        { media: "20230129Img4259" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Yellow+Deli/@49.1547492,-122.0298619,12.01z/data=!4m10!1m2!2m1!1syellow+deli!3m6!1s0x54843f38b6d24755:0xc5b34e925476991c!8m2!3d49.1657734!4d-121.959317!15sCgt5ZWxsb3cgZGVsaSIDiAEBWg0iC3llbGxvdyBkZWxpkgEKcmVzdGF1cmFudOABAA!16s%2Fg%2F11j8p5m1v8`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/City+Centre+Artist+Lodge/@49.2659388,-123.1017388,19.62z/data=!4m12!1m5!3m4!2zNDnCsDE1JzU3LjUiTiAxMjPCsDA2JzA1LjMiVw!8m2!3d49.26597!4d-123.10147!3m5!1s0x54867164cc39f79d:0xf8a9ec03d1411986!8m2!3d49.2658762!4d-123.1014211!16s%2Fg%2F11swzl_lvf`
      ),

      [
        {
          text: "Now it's time to head back to Vancouver Island until the end of winter and I can start heading north! This is the view from the ferry, you can kinda see the lights from the ski hills.",
        },
        { media: "20230129Img4264" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B000'09.4%22N+123%C2%B009'13.5%22W/@48.928963,-123.6969101,9.32z/data=!4m4!3m3!8m2!3d49.00261!4d-123.15376`
      ),
    ],
  },
  {
    published: true,
    title: "Vancouver Island Again",
    createdDate: "May 03, 2023",
    eventDate: ["March 11, 2023", "April 09, 2023"],
    chunks: [
      latLngFromUrl(
        `https://www.google.com/maps/place/Duke+Point+Ferry+Terminal/@49.1615874,-123.8935504,17.34z/data=!4m12!1m5!3m4!2zNDnCsDA3JzQxLjYiTiAxMjPCsDQ5JzQxLjAiVw!8m2!3d49.12821!4d-123.82806!3m5!1s0x5488a6ca3b64c2a7:0x426f0dcfbd741b0d!8m2!3d49.1619879!4d-123.8919299!16s%2Fg%2F11c267n6h7`
      ),
      [
        { text: "Back on the island, Claire and I shall do some hiking!" },
        { media: "20230311Img4266" },
        { media: "20230311Img4268" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Cable+bay+trail+parking/@49.127383,-123.8304569,15.77z/data=!4m12!1m5!3m4!2zNDnCsDA3JzQxLjYiTiAxMjPCsDQ5JzQxLjAiVw!8m2!3d49.12821!4d-123.82806!3m5!1s0x5488a7fca971bdcb:0xb54c3767f63e6d96!8m2!3d49.1232034!4d-123.8227623!16s%2Fg%2F11sd_jg4cr`
      ),

      [
        {
          text: "Determined not to let rain stop us, we still got some pretty great views.",
        },
        { media: "20230311Img4274" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Westwood+Lake/@49.1589795,-124.0108597,15z/data=!4m12!1m5!3m4!2zNDnCsDA5JzMyLjMiTiAxMjTCsDAwJzAyLjAiVw!8m2!3d49.15898!4d-124.00056!3m5!1s0x5488a31d3ac2d7ab:0x62c4f730ecd38ea6!8m2!3d49.159476!4d-123.998629!16s%2Fg%2F11h6q7ncmd`
      ),

      [
        { text: "And Nanaimo had a new taco place that really hit the spot!" },
        { media: "20230311Img4276" },
        { media: "20230311Img4275" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Bar+Luna/@49.1620626,-123.9448416,15z/data=!3m2!4b1!5s0x5488a3fd7efd0ac5:0x72758142dadad5d1!4m6!3m5!1s0x5488a347c2e6c003:0x7632dbc33ab6f6aa!8m2!3d49.1620631!4d-123.9345419!16s%2Fg%2F11t7p6vj33`
      ),

      [
        { media: "20230311Img4309" },
        { media: "20230311Img4307" },
        { media: "20230311Img4283" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B011'33.0%22N+123%C2%B057'22.9%22W/@49.1924581,-123.9566887,19.86z/data=!4m4!3m3!8m2!3d49.1925!4d-123.95636`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Pipers+Lagoon+Park/@49.2245935,-123.9536948,16.11z/data=!4m14!1m7!3m6!1s0x5488a1c94b5b9c75:0x51dd0f8e0a0f2631!2sPipers+Lagoon+Parking!8m2!3d49.2231035!4d-123.9478601!16s%2Fg%2F11hcdvk3nt!3m5!1s0x5488a1ce5e2aa1eb:0xc4bd45ce2a2e7c54!8m2!3d49.2228804!4d-123.9483316!16s%2Fg%2F1tf1bfx1`
      ),

      [
        { text: "Back to mount washington for some more snowboarding!" },
        { media: "20230311Img4313" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Mount+Washington+Skiing+Alpine+Resort/@49.7409797,-125.3044301,17.95z/data=!3m1!5s0x548822f0a3aae725:0xb3ec76cbcb24a83c!4m6!3m5!1s0x548822f0a25d8c1d:0x2587a153025666e6!8m2!3d49.7413173!4d-125.3030741!16zL20vMDk4Y3Ns`
      ),

      [
        {
          text: "I got my new solar panels so I spent an evening installing them in a lovely home depot parking lot, these should definitely improve my output!",
        },
        { media: "20230311Img4324" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/The+Home+Depot/@49.2327294,-124.0508333,18.26z/data=!4m12!1m5!3m4!2zNDnCsDEzJzU5LjEiTiAxMjTCsDAzJzAxLjgiVw!8m2!3d49.23309!4d-124.05051!3m5!1s0x548898c8deb9c069:0xef1b15abeaf180d0!8m2!3d49.2323036!4d-124.0494843!16s%2Fg%2F1tf7gc3x`
      ),

      [
        {
          text: "I gotta give the people the content that they want, like this sink! It's installed backwards :P",
        },
        { media: "20230311Img4337" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B032'37.3%22N+124%C2%B051'57.0%22W/@49.540477,-124.8790718,14.68z/data=!4m4!3m3!8m2!3d49.54368!4d-124.86584`
      ),

      [
        {
          text: "I spent a weekend exploring the logging roads around campbell river, there were so many beautiful free campsites :)",
        },
        { media: "20230311Img4339" },
        { media: "20230311Dsc07630" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/50%C2%B002'07.4%22N+125%C2%B028'01.0%22W/@50.035475,-125.4706462,16.66z/data=!4m4!3m3!8m2!3d50.03539!4d-125.46694`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Brittany+Bay+Recreation+Site/@50.0568479,-125.596267,16.58z/data=!4m11!1m5!3m4!2zNTDCsDAzJzI1LjAiTiAxMjXCsDM1JzM5LjMiVw!8m2!3d50.05694!4d-125.59425!3m4!1s0x5462bdcb0810863b:0x15a34df58adf4730!8m2!3d50.0563897!4d-125.5943459`
      ),

      [
        {
          text: "this next photo was where google was telling me to drive... I don't think they have the most recent information about this spot :P",
        },
        { media: "20230311Img4340" },
        { media: "20230311Img4406" },
      ],

      {
        text: "I found this hike hiding down one of the roads. It was absolutely gorgeous with a water fall around every corner.",
      },
      [
        { media: "20230311Dsc07635" },
        { media: "20230311Img4346" },
        { media: "20230311Dsc07680" },
      ],
      [{ media: "20230311Img4402" }, { media: "20230311Img4351" }],
      [{ media: "20230311Img4393" }, { media: "20230311Img4388" }],
      [
        { media: "20230311Img4374" },
        { media: "20230311Img4355" },
        { media: "20230311Img4403" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/50%C2%B005'47.6%22N+125%C2%B025'25.4%22W/@50.0971757,-125.4280097,16.36z/data=!4m10!1m5!3m4!2zNTDCsDA1JzUzLjAiTiAxMjXCsDI1JzM0LjUiVw!8m2!3d50.09805!4d-125.42625!3m3!8m2!3d50.096556!4d-125.423722`
      ),

      { text: "Spent some days working around campbell river" },
      [{ media: "20230311Img4419" }, { media: "20230311Img4411" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Discovery+Fishing+Pier+Lookout/@50.020596,-125.2393188,16.82z/data=!4m12!1m5!3m4!2zNTDCsDAxJzE4LjIiTiAxMjXCsDE0JzA5LjEiVw!8m2!3d50.02172!4d-125.23586!3m5!1s0x5462af249100640b:0xcbfe76b3bbca4481!8m2!3d50.0223142!4d-125.2361989!16s%2Fg%2F11g6wszw1z`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Miracle+Beach/@49.8494473,-125.097464,16.36z/data=!4m12!1m5!3m4!2zNDnCsDUwJzU5LjUiTiAxMjXCsDA1JzM2LjciVw!8m2!3d49.84987!4d-125.09353!3m5!1s0x54881b43da4c9639:0xc34036cf7f53b4c!8m2!3d49.8509054!4d-125.0944428!16s%2Fg%2F11_tcrd14`
      ),

      [
        {
          text: "Then Claire and I went out to Carmanah so I could actually do the heaven tree hike.",
        },
        { media: "20230311Img4424" },
        { media: "20230311Img4423" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/48%C2%B047'57.8%22N+124%C2%B038'25.7%22W/@48.7993995,-124.6507797,15z/data=!3m1!4b1!4m4!3m3!8m2!3d48.7994!4d-124.64048`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/48%C2%B046'39.7%22N+124%C2%B042'10.1%22W/@48.7776795,-124.7130997,15z/data=!3m1!4b1!4m4!3m3!8m2!3d48.77768!4d-124.7028`
      ),
      [{ media: "20230311Img4454" }, { media: "20230311Img4455" }],
      [
        { media: "20230311Img4463" },
        { media: "20230311Img4461" },
        { media: "20230311Img4458" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Carmanah+Valley+Trailhead/@48.6511569,-124.7568896,13.14z/data=!4m12!1m5!3m4!2zNDjCsDM5JzE0LjkiTiAxMjTCsDQzJzI1LjYiVw!8m2!3d48.65414!4d-124.72379!3m5!1s0x54892d567fba7c9f:0xd48b1e3192e09551!8m2!3d48.6591485!4d-124.7017436!16s%2Fg%2F11mwy_hkfs`
      ),

      [{ media: "20230311Img4465" }, { media: "20230311Img4427" }],

      [
        {
          text: "New fan! My fan had started to make a horrible noise, but I also really wanted the remote control version because of the way Vern is set up. So I found someone to take my current fan and installed a new one.",
        },
        { media: "20230311Img4469" },
      ],

      [
        { text: "Claire and I got some yummy pizzas" },
        { media: "20230311Img4478" },
        { media: "20230311Img4476" },
      ],

      { text: "And then we went off to find another new hike!" },
      [
        { media: "20230311Img4491" },
        { media: "20230311Img4493" },
        { media: "20230311Img4488" },
      ],
      [
        { media: "20230311Img4487" },
        { media: "20230311Img4484" },
        { media: "20230311Img4481" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Pipeline+Trail/@49.0679715,-123.9773979,15.43z/data=!4m12!1m5!3m4!2zNDnCsDAzJzUyLjMiTiAxMjPCsDU3JzQwLjgiVw!8m2!3d49.06454!4d-123.96133!3m5!1s0x5488a5f0c3c67cf1:0x29608aa39267a8b0!8m2!3d49.0747038!4d-123.9704618!16s%2Fg%2F11py6pz_1w`
      ),

      [
        {
          text: "This picture of me with my hat very naturally on my head is the start of this blog! I did it the goal was to not let figuring out the best technical solutions stop me from actually do it and I think I've succeeded! Maybe I'll improve things one day :D",
        },
        { media: "20230311Img4494" },
      ],

      [
        {
          text: "The last adventure on Vancouver Island was to go to another island! It's weird being on the top deck of a ferry, I'm not use to being this short!",
        },
        { media: "20230311Img4507" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Nanaimo+Harbour+Ferry+Terminal/@49.1668049,-123.9339316,16.99z/data=!4m14!1m7!3m6!1s0x5488a77c3af72be7:0xf5789563bd8bfc81!2sGabriola+Island!8m2!3d49.1577754!4d-123.7893349!16zL20vMDNxbnFt!3m5!1s0x5488a159e2ac51bd:0x4abc8372f2de3a12!8m2!3d49.166064!4d-123.930677!16s%2Fg%2F11h07kvkt8`
      ),
      { text: "First stop, wow! This rock formation is amazing!" },
      [{ media: "20230311Img4534" }, { media: "20230311Img4537" }],
      [{ media: "20230311Img4528" }, { media: "20230311Img4509" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Malaspina+Galleries/@49.1767188,-123.9113236,12.74z/data=!4m14!1m7!3m6!1s0x5488a77c3af72be7:0xf5789563bd8bfc81!2sGabriola+Island!8m2!3d49.1577754!4d-123.7893349!16zL20vMDNxbnFt!3m5!1s0x5488a0c3559a0d19:0xf0fcf72fd7f906e1!8m2!3d49.1914945!4d-123.8711805!16s%2Fg%2F11c1n8mw_y`
      ),

      {
        text: "Then we drove to the other end of the island and had a few more lovely hikes in a beautiful lush forest and along the coast where the currents were being pretty wild. Along the way we picked up a hitch hiker who we drove to work. She told me to visit her friend in whitehorse who works at the sushi restaurant.",
      },
      [
        { media: "20230311Img4548" },
        { media: "20230311Img4557" },
        { media: "20230311Img4562" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Drumbeg+Provincial+Park/@49.1323909,-123.7015754,16.26z/data=!4m12!1m5!3m4!2zNDnCsDA3JzUzLjEiTiAxMjPCsDQxJzQ1LjgiVw!8m2!3d49.13141!4d-123.69605!3m5!1s0x548602b647b0e109:0xd49815db0e88062b!8m2!3d49.1342603!4d-123.6973709!16zL20vMGJwemtm`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Cox+Community+Park/@49.1818795,-123.8580897,15z/data=!4m11!1m5!3m4!2zNDnCsDEwJzU0LjgiTiAxMjPCsDUwJzUyLjAiVw!8m2!3d49.18188!4d-123.84779!3m4!1s0x5488a14fbe4ca56b:0xa3336dbbf025ca46!8m2!3d49.1817051!4d-123.8550112`
      ),
      { text: "Thats it for Gabriola, back to the mainland!" },
    ],
  },
  {
    published: true,
    title: "The start north",
    createdDate: "May 04, 2023",
    eventDate: ["April 13, 2023", "April 16, 2023"],
    chunks: [
      {
        text: `Here we are, I just landed back on the mainland, it's starting to warm up, and I'm almost ready for the next adventure. The plan is to drive from Vancouver, BC to Tuktoyaktuk, NT. According to google, if I were to drive straight there it would be about 4,560km and 54 hours one way, though I'm sure I will make some detours along the way.`,
      },
      {
        text: `I need to take care of a few things before I can head out. First stop is getting my heater maintenance done, then off to get the van maintenance done. At the ford dealership I ended up having a meeting outside on a very nice standing desk. After this I head off to Abbotsford to pick up some solar components to do some rewiring there, I need to switch them from being wired in series to parallel.`,
      },
      [
        { media: "20230413Img4565" },
        { media: "20230413Img4584" },
        { media: "20230413Img4596" },
      ],
      {
        text: "Before leaving Vancouver, I needed to try Tatchan Noodle which I had seen while on the island and could finally try.",
      },
      [{ media: "20230413Img4587" }, { media: "20230413Img4589" }],
      latLngFromUrl(
        "https://www.google.com/maps/place/Thermo+King+of+British+Columbia/@49.6393673,-123.814878,8.12z/data=!4m10!1m2!2m1!1sthermoking!3m6!1s0x5485d7c547ee26b5:0xf2706bc61d95ebfc!8m2!3d49.2271405!4d-122.8347255!15sCgp0aGVybW9raW5nIgOIAQFaDCIKdGhlcm1va2luZ5IBHnJlZnJpZ2VyYXRlZF90cmFuc3BvcnRfc2VydmljZeABAA!16s%2Fg%2F1vxz99c0"
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Tatchan+Noodle/@49.2518925,-123.0628461,15.58z/data=!4m6!3m5!1s0x548675d2f7a580b1:0xf65e11f877f39bf9!8m2!3d49.2516771!4d-123.0563919!16s%2Fg%2F11tjwm0w89`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Mainland+Ford+Ltd./@49.1905739,-122.8196272,17z/data=!3m1!4b1!4m6!3m5!1s0x5485d77735638e8b:0x9c50835754367431!8m2!3d49.1905739!4d-122.8196272!16s%2Fg%2F11btm7yz_h`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Canadian+Tire/@49.0520455,-122.3336446,15z/data=!3m1!4b1!4m6!3m5!1s0x54843544c541c6d5:0xc6cfbe226a085cfd!8m2!3d49.052046!4d-122.3233449!16s%2Fg%2F1tjl5lkh`
      ),
      {
        text: `Of course, I needed to stop by the drive in Langley, it was open! They were playing Mario and Creed III, so not the most appealing line up for me, but I never pass on the drive in experience and I got to watching the rocky movies (The first one is great, the second one is good... the rest lost me :))`,
      },
      [{ media: "20230413Img4601" }, { media: "20230413Img4603" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Twilight+Drive-In+Theatre/@49.0630713,-122.5124253,15z/data=!3m1!4b1!4m6!3m5!1s0x5485cbfaeddfcd3d:0x20640fa761c01efc!8m2!3d49.0630718!4d-122.5021256!16s%2Fg%2F1tdqh4ph`
      ),
      {
        text: `Now that I have all the chores done (I also ended doing a bit of re-plumbing for my water fill which was leaking :( ), and I picked up things in Abbotsford, I'm untethered and free to go! I don't have a timeline, and progress will likely be slow at first because it might still be cold. But it's time to get my mosey on!  For this weekend, I drove to Hope and stopped at Bridal falls, and Flood falls for some really nice short hikes`,
      },
      { text: `Bridal falls` },
      [
        { media: "20230413Img4611" },
        { media: "20230413Img4614" },
        { media: "20230413Img4617" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Bridal+Falls,+BC+V0X+1X0/@49.1916142,-121.7797373,13z/data=!3m1!4b1!4m6!3m5!1s0x54846b6599ea1c87:0x5be7249880545f6a!8m2!3d49.191622!4d-121.738538!16zL20vMGQ1X2w3`
      ),

      { text: `Flood falls` },
      [{ media: "20230413Img4631" }, { media: "20230413Img4640" }],
      [
        { media: "20230413Img4627" },
        { media: "20230413Img4646" },
        { media: "20230413Img4645" },
      ],

      latLngFromUrl(
        `https://www.google.com/maps/place/Flood+Falls+Trail,+Hope,+BC+V0X+1L2/@49.3607616,-121.5202261,17z/data=!3m1!4b1!4m6!3m5!1s0x54840a162e4c3cb7:0xd78bd37a27393f34!8m2!3d49.3607616!4d-121.5202261!16s%2Fg%2F11c2xjglt3`
      ),

      [
        {
          text: "I ended the weekend driving north from Hope a bit, and staying at a rest area in the mountains, It snowed overnight so I woke up to a lovely layer in the morning :)",
        },
        { media: "20230413Img4653" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Britton+Creek+Rest+Area/@49.6524238,-121.0035972,16.99z/data=!4m15!1m8!3m7!1s0x5113cb935c41b04d:0x1155e872d8421ea3!2sTuktoyaktuk,+NT!3b1!8m2!3d69.445358!4d-133.034181!16zL20vMDNwOXF4!3m5!1s0x5483db1a3f705703:0xa93984a01037728e!8m2!3d49.6519891!4d-121.0000056!16s%2Fg%2F11tjbvhchg`
      ),
    ],
  },
  {
    published: true,
    title: "The road to Revelstoke",
    createdDate: "May 05, 2023",
    eventDate: ["April 18, 2023", "April 25, 2023"],
    chunks: [
      {
        text: "Continuing from Hope a bit, I stayed along a little river on the mountain pass. The weather was quite quick to change and it went from sunny to snowing inside the van. While here I did a very exciting upgrade and finally installed my new side door screen. I Had one before I made from a french door screen, but this is quite the upgrade from that and made specifically for the transit.",
      },
      [
        { media: "20230418Img4658" },
        { media: "20230418Img4661" },
        { media: "20230418Img4657" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B052'08.7%22N+120%C2%B054'45.3%22W/@49.8699295,-120.9231697,15z/data=!4m10!1m5!3m4!2zNDnCsDUyJzExLjgiTiAxMjDCsDU0JzQ2LjMiVw!8m2!3d49.86993!4d-120.91287!3m3!8m2!3d49.869071!4d-120.912569`
      ),

      {
        text: "I drove through Merritt, and stayed at the Walmart which did not have a bad view (the first picture) and then continued on to Kamloops where I stayed by a lovely little lake and got some fry bread tacos.",
      },
      [
        { media: "20230418Img4668" },
        { media: "20230418Img4673" },
        { media: "20230418Img4678" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Walmart+Supercentre/@50.1218102,-120.7580929,16.4z/data=!4m12!1m5!3m4!2zNTDCsDA3JzI0LjkiTiAxMjDCsDQ1JzI1LjgiVw!8m2!3d50.12358!4d-120.75717!3m5!1s0x882f04014acade0d:0xc6c4bed0e612d778!8m2!3d50.121584!4d-120.7520485!16s%2Fg%2F1hc4czl5v`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Inks+Lake/@50.6186295,-120.4579897,15z/data=!4m12!1m5!3m4!2zNTDCsDM3JzA3LjEiTiAxMjDCsDI2JzUxLjciVw!8m2!3d50.61863!4d-120.44769!3m5!1s0x537e2b88a00c905b:0x7b4ae89360ba4b1d!8m2!3d50.6175466!4d-120.4455947!16s%2Fg%2F11sqc6w605`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Kekuli+Caf%C3%A9+Coffee+%26+Bannock+-+Kamloops/@50.6748169,-120.3380829,16.7z/data=!4m12!1m5!3m4!2zNTDCsDQwJzQ2LjEiTiAxMjDCsDIwJzA1LjgiVw!8m2!3d50.67946!4d-120.33494!3m5!1s0x537e2d928282e9df:0x4d33f79ba2a2a02d!8m2!3d50.6717485!4d-120.3341624!16s%2Fg%2F11pz1cd0_s`
      ),

      {
        text: "After picking up some groceries I headed out from Kamloops to head towards Revelstoke. First couple nights I stayed at this absolutely lovely rec site. It rained the whole weekend and the road was pretty rough getting in so I was a little worried about driving out. But after so slipping and sliding sideways I made it through the mud patch and was on my way!",
      },
      [{ media: "20230418Dsc07824" }, { media: "20230418Img4684" }],
      [{ media: "20230418Dsc07833" }, { media: "20230418Dsc07821" }],
      [{ media: "20230418Dsc07827" }, { media: "20230418Dsc07812" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Harper+Lake+Recreation+Site/@50.7393975,-119.7145059,17z/data=!4m6!3m5!1s0x537e4fe85c4c5317:0xea0a116e17f9bd1!8m2!3d50.7396759!4d-119.7125854!16s%2Fg%2F11b_1n3pw0`
      ),

      {
        text: "Continuing on I had a quick overnight up a logging road on a mountain. I wanted to continue higher, but not much further up the road was still completely covered in snow so I thought I better not :) ",
      },
      [{ media: "20230418Img4711" }, { media: "20230418Dsc07842" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/50%C2%B045'28.2%22N+119%C2%B011'14.1%22W/@50.7578395,-119.1975397,15z/data=!3m1!4b1!4m4!3m3!8m2!3d50.75784!4d-119.18724`
      ),

      {
        text: "A bit stop in the houseboat capital of canada! There was a suspicious lack of house boats...",
      },
      [{ media: "20230418Img4721" }, { media: "20230418Img4718" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Sicamous,+BC/@50.8325408,-118.9807065,15z/data=!3m1!4b1!4m12!1m5!3m4!2zNTDCsDUwJzE1LjMiTiAxMTjCsDU5JzIyLjciVw!8m2!3d50.83759!4d-118.98963!3m5!1s0x537ee97bfe968551:0xcecad795db3db2c6!8m2!3d50.8377865!4d-118.9766959!16s%2Fg%2F11bc6qvcx4`
      ),

      { text: "Next stop, Revelstoke!" },
    ],
  },
  {
    published: true,
    title: "Revelstoke",
    createdDate: "May 18, 2023",
    eventDate: ["April 27, 2023", "May 10, 2023"],
    chunks: [
      {
        text: "I made it to Revelstoke! I had a few packages shipped here, so I picked up my new kettle ✨. After that, with my arrival also came the warmer weather! So I got my bike out and went for a little bike ride around the town. My friend Jordan has been living here for the winter so we met up and I joined in the last few rounds of a disc golf game, I was really bad, but I was motivated enough to buy a few starter discs to practice :P",
      },
      [{ media: "20230427Img4727" }, { media: "20230427Img4725" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Centennial+Park/@50.9899772,-118.2069561,15.57z/data=!4m6!3m5!1s0x537937011b0aa4f9:0x73d73411dc14bd98!8m2!3d50.9940368!4d-118.2042266!16s%2Fg%2F11d_d1xr88`
      ),

      {
        text: "A lot of the hikes are still very covered in snow, so I had to find the ones that were at the lower elevation and on the south side of the mountains. The first one was mount cartier lookout. It was too hot :P",
      },
      [
        { media: "20230427Img4736" },
        { media: "20230427Dsc078652Hdr" },
        { media: "20230427Dsc078562Hdr" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Mount+Cartier+Recreation+Trail/@50.9251101,-118.1455753,17.41z/data=!4m6!3m5!1s0x537949e75bf5a8a1:0x19465771427c00af!8m2!3d50.925586!4d-118.143067!16s%2Fg%2F11q1v1fj9r`
      ),

      {
        text: "While I was in Revelstoke I spent a few nights on the river flats, surrounded by mountains. First day there I installed my grill mesh, and the back door screen so more upgrades done!",
      },
      [
        { media: "20230427Dsc078892Hdr" },
        { media: "20230427Img4754" },
        { media: "20230427Img4753" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/50%C2%B056'03.5%22N+118%C2%B009'20.3%22W/@50.9343467,-118.1563535,216m/data=!3m1!1e3!4m4!3m3!8m2!3d50.934297!4d-118.155628`
      ),

      {
        text: "I wanted to do at least a little hiking in the national park, I definitely couldn't go to any of the higher elevation ones, but there was this little hike to the Nels Nelsen historic ski jump. It was short, but it sure was steep.",
      },
      [
        { media: "20230427Dsc079012Hdr" },
        { media: "20230427Dsc079072Hdr" },
        { media: "20230427Img4773" },
      ],
      [{ media: "20230427Dsc079582Hdr" }, { media: "20230427Dsc079312Hdr" }],
      [{ media: "20230427Dsc079462Hdr" }, { media: "20230427Dsc079252Hdr" }],

      {
        text: "Stopped for some lovely brunch, and also walked by the train museum... though I did not go in, shame on me!",
      },
      [{ media: "20230427Img4759" }, { media: "20230427Dsc07966" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Nels+Nelsen+Historic+Ski+Jump/@51.0066375,-118.1967644,779m/data=!3m1!1e3!4m15!1m8!3m7!1s0x5379371235d02875:0x338f1e9e9fa6c78a!2sNels+Nelsen+Historic+Area,+District+r%C3%A9gional+de+Columbia-Shuswap,+BC+V0E+2S0!3b1!8m2!3d51.0072575!4d-118.194119!16s%2Fg%2F11cls_rwd0!3m5!1s0x53793713b30a002b:0xaa54ca9229bead20!8m2!3d51.0078657!4d-118.1936427!16s%2Fg%2F11f54wc43m`
      ),

      {
        text: "Just a little snapshot of my annoying #officefortheday, it was a lovely little park with boulders and a beautiful view.",
      },
      [
        { media: "20230427Img4780" },
        { media: "20230427Img4779" },
        { media: "20230427Img4777" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Big+Eddy+Recreation+Site/@51.0044069,-118.2424609,947m/data=!3m1!1e3!4m14!1m8!3m7!1s0x5379371235d02875:0x338f1e9e9fa6c78a!2sNels+Nelsen+Historic+Area,+District+r%C3%A9gional+de+Columbia-Shuswap,+BC+V0E+2S0!3b1!8m2!3d51.0072575!4d-118.194119!16s%2Fg%2F11cls_rwd0!3m4!1s0x537937c7dc4c923d:0xb807a4a2a52f0cfa!8m2!3d51.004849!4d-118.2390583`
      ),

      {
        text: "Another short hike that wasn't covered in snow! After work I went and did boulder mountain lookout, lovely view with a pretty fun start.",
      },
      [{ media: "20230427Dsc079902HdrPano" }, { media: "20230427Dsc08016" }],
      [{ media: "20230427Dsc079822Hdr" }, { media: "20230427Dsc079762Hdr" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Boulder+Mountain+Lookout+-+Start+of+Hike/@51.0088645,-118.2414575,947m/data=!3m1!1e3!4m15!1m8!3m7!1s0x5379371235d02875:0x338f1e9e9fa6c78a!2sNels+Nelsen+Historic+Area,+District+r%C3%A9gional+de+Columbia-Shuswap,+BC+V0E+2S0!3b1!8m2!3d51.0072575!4d-118.194119!16s%2Fg%2F11cls_rwd0!3m5!1s0x53793724be705ee3:0xd7f8e53a5707702c!8m2!3d51.0103393!4d-118.2393469!16s%2Fg%2F11pcl1l69v`
      ),

      { text: "Back to the flats for a bit!" },
      [{ media: "20230427Img4795" }, { media: "20230427Img4788" }],
      [{ media: "20230427Dsc08025" }, { media: "20230427Dsc08022" }],

      [
        {
          text: "There was a german indian fusion place in town... so I tried the butter chicken schnitzel on spaetzle, it was pretty darn good, though I did take an awful photo of it...",
        },
        { media: "20230427Img4789", overrideWidth: 30 },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Paramjit's+Kitchen/@50.9871788,-118.2214166,6821m/data=!3m1!1e3!4m10!1m2!2m1!1sindian!3m6!1s0x5379370f5a9bf52b:0xde20b3b28f013289!8m2!3d50.999321!4d-118.195966!15sCgZpbmRpYW5aCCIGaW5kaWFukgERaW5kaWFuX3Jlc3RhdXJhbnSaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTnBNa2x0UTJGbkVBReABAA!16s%2Fg%2F1tg78qqk`
      ),

      { text: "Another very short hike to the beautiful Begbie falls." },
      [{ media: "20230427Img4801" }, { media: "20230427Dsc08072" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Begbie+Falls/@50.9330322,-118.191491,728m/data=!3m1!1e3!4m14!1m7!3m6!1s0x537936e4b4a702a7:0x18d4e218c72572e!2sBegbie+Falls!8m2!3d50.9330322!4d-118.189592!16s%2Fg%2F11c1rfd_zr!3m5!1s0x537936e4b4a702a7:0x18d4e218c72572e!8m2!3d50.9330322!4d-118.189592!16s%2Fg%2F11c1rfd_zr`
      ),

      {
        text: "I wondered into some random woods to hind this cute geocache...",
      },
      [
        { media: "20230427Img4807" },
        { media: "20230427Img4809" },
        { media: "20230427Img4805" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/51%C2%B001'13.6%22N+118%C2%B014'00.1%22W/@51.017802,-118.2339659,779m/data=!3m1!1e3!4m4!3m3!8m2!3d51.020449!4d-118.233368`
      ),

      {
        text: "And then it turned out there was another short hike to a beautiful waterfall that pictures don't do justice.",
      },
      [{ media: "20230427Dsc08091" }, { media: "20230427Img4811" }],
      [{ media: "20230427Dsc08083" }, { media: "20230427Dsc08087" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Moses+Falls/@51.0169691,-118.2663536,8334m/data=!3m1!1e3!4m6!3m5!1s0x537931784e874b33:0x57cf969ce5b2552e!8m2!3d51.0428569!4d-118.2193461!16s%2Fg%2F11fsltzw1v`
      ),

      [
        {
          text: "I then I took a little drive up to the dam which was closed, but some some bears on the way and some beautiful views from some lookouts",
        },
      ],
      [{ media: "20230427Dsc08095" }, { media: "20230427Dsc08093" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/51%C2%B003'11.2%22N+118%C2%B011'04.8%22W/@51.0506507,-118.1889738,1462m/data=!3m1!1e3!4m4!3m3!8m2!3d51.053096!4d-118.184652`
      ),

      {
        text: "Also not pictured Jordan and I co worked for a bit and I joined in for a trip to the movie theatre to watch Guardians of the Galaxy Vol. 3. It was a good visit, but unfortunately I have to carry on to head back to kamloops to pick some things up and head north! :) ",
      },
    ],
  },
  {
    published: true,
    title: "Kamloops",
    createdDate: "May 20, 2023",
    eventDate: ["May 10, 2023", "May 19, 2023"],
    chunks: [
      {
        text: "Time to head back to Kamloops! First little stop was not that far outside of Revelstoke down a logging road. Nice and quiet :)",
      },
      [{ media: "20230510Img4818" }, { media: "20230510Img4816" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/50%C2%B055'36.3%22N+118%C2%B028'52.5%22W/@50.9267495,-118.4915397,15z/data=!3m1!4b1!4m4!3m3!8m2!3d50.92675!4d-118.48124`
      ),

      [
        {
          text: `
          I had to make a slight detour to Vernon to pick up a backpack I've been trying to find for months. 
          I was able to get the Valhalla Pure in Vernon to order the last one Mystery Ranch had available in Canada! 
          While Popping down here I also picked up a new lens... lets see if I can do good things with it haha`,
        },
        { media: "20230510Img4824" },
        { media: "20230510Img4869" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Valhalla+Pure+Outfitters/@50.28174,-119.2698221,17z/data=!3m1!4b1!4m6!3m5!1s0x537dd88e6aaef3f5:0x2c0543f6a70dfa2f!8m2!3d50.28174!4d-119.2698221!16s%2Fg%2F11b6gdyt2w`
      ),

      {
        text: "Another drive in checked off the list! I stopped by the drive in in Enderby which had one of the better views I've seen.",
      },
      [{ media: "20230510Img4831" }, { media: "20230510Dsc08099" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Starlight+Drive-In+Theatre/@50.260397,-119.4786768,9.76z/data=!4m10!1m2!2m1!1sdrive-in+movie+theater!3m6!1s0x537e89b761baa23d:0x7c643fb6ce8e4864!8m2!3d50.5222194!4d-119.139161!15sChZkcml2ZS1pbiBtb3ZpZSB0aGVhdGVyWhgiFmRyaXZlIGluIG1vdmllIHRoZWF0ZXKSARZkcml2ZV9pbl9tb3ZpZV90aGVhdGVymgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVU5WY1ZsbU0xbDNFQUXgAQA!16s%2Fg%2F1txnp431`
      ),
      [
        { text: "The campground in Enderby was right by the river" },
        { media: "20230510Img4834" },
        { media: "20230510Img4833" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Riverside+RV+Park/@50.5457616,-119.1520421,13.86z/data=!4m9!1m2!2m1!1sdrive-in+movie+theater!3m5!1s0x537e884dcde4fe15:0x88683cde3a245218!8m2!3d50.556952!4d-119.1291847!16s%2Fg%2F1tfgp26s`
      ),

      {
        text: "Went back to the woods to this rec site on a river, it was still stupid hot out, but the river provided a nice cool breeze! On the way I stopped and got some asparagus from a farm so I cooked that up for dinner! :)",
      },
      [{ media: "20230510Dsc08100" }, { media: "20230510Img4837" }],
      [{ media: "20230510Img4839" }, { media: "20230510Img4849" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Chase+Creek+Recreational+Site/@50.6349396,-119.6811331,12.99z/data=!4m9!1m2!2m1!1sdrive-in+movie+theater!3m5!1s0x537e4385bf36dd0b:0xf04b92c7d5a5564!8m2!3d50.6046442!4d-119.6426977!16s%2Fg%2F11ghtw5n01`
      ),

      {
        text: "Nearby there was this short hike to a single hoodoo, it was 60 feet tall and absolutely not captured by these pictures. ",
      },
      [
        { media: "20230510Img4844" },
        { media: "20230510Dsc08124" },
        { media: "20230510Dsc08117" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Pillar+Provincial+Park/@50.5934303,-119.6570155,14.05z/data=!4m9!1m2!2m1!1sdrive-in+movie+theater!3m5!1s0x537e436f37411961:0x99f28b45d5263124!8m2!3d50.5895334!4d-119.6293439!16zL20vMGJ5MDN5`
      ),

      {
        text: "One of the things I needed to get from Kamloops was a roof rack tray to store jerry cans. I found someone and I did up some drawings for what I want and he turned it around quickly so I could pick it up on my way! Now I'm truly ready for the north :) ",
      },
      [{ media: "20230510Untitled1" }, { media: "20230510Untitled" }],
      [
        { media: "20230510705873717632268F99FDcac44E3A70AC240701154E0" },
        { media: "20230510Img4868" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/5481+Campbell+Creek+Rd,+Kamloops,+BC+V2C+5T8/@50.6137707,-120.1913218,15z/data=!3m1!4b1!4m5!3m4!1s0x537e302257d35ceb:0x38d174a1d5065e10!8m2!3d50.6137712!4d-120.1810221`
      ),

      {
        text: "I picked up a few more things in Kamloops like some groceries and a book. I also got a VHF radio so I can hopefully be safer going down the logging road. But then I started out, I stayed at a fun rec site that was setup for horses, and also stopped at a diner in Little Fort for a schnitzel... ridiculous portions.",
      },
      [{ media: "20230510Img4860" }, { media: "20230510Img4862" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Fishtrap+Creek+Recreation+Site/@51.1245495,-120.2194497,15z/data=!4m12!1m5!3m4!2zNTHCsDA3JzI4LjQiTiAxMjDCsDEyJzMyLjkiVw!8m2!3d51.12455!4d-120.20915!3m5!1s0x537fbd62fbe29295:0xcb21833c5a6bd14!8m2!3d51.124382!4d-120.2111054!16s%2Fg%2F11bw21xw59`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/High+Five+Diner/@51.4279294,-120.2185774,15.14z/data=!4m12!1m5!3m4!2zNTHCsDA3JzI4LjQiTiAxMjDCsDEyJzMyLjkiVw!8m2!3d51.12455!4d-120.20915!3m5!1s0x537f81e6d2df4cb1:0xf272cb7f297e1ddd!8m2!3d51.4250951!4d-120.2041821!16s%2Fg%2F11b5wlx5n7`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Lolo+Lake+Recreation+Site/@51.6249461,-120.2146951,12.3z/data=!4m12!1m5!3m4!2zNTHCsDA3JzI4LjQiTiAxMjDCsDEyJzMyLjkiVw!8m2!3d51.12455!4d-120.20915!3m5!1s0x5381d0eddc5ac10f:0x3caed48ab89a12d1!8m2!3d51.6331824!4d-120.1698625!16s%2Fg%2F1q62br1dh`
      ),
    ],
  },
  {
    published: true,
    title: "Wells Gray",
    createdDate: "May 28, 2023",
    eventDate: ["May 20, 2023", "May 21, 2023"],
    chunks: [
      {
        text: "I knew about Helmcken falls and wanted to stop there on the way. But I didn't know much about the park it was in, Wells Gray. For car access in this park it is a 65km road, and then you turn around and drive back out. Along the way there are some spectacular waterfalls you can either stop at viewing platforms for, or there are short hikes. For my visit I only saw 4 of them, but they sure left an impact!",
      },

      {
        text: "The night before heading into the park to explore I stayed at the Raft Rim campground, a free campsite right near the edge of a beautiful cliff.",
      },
      [{ media: "20230519Img4874" }, { media: "20230519Img4872" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Raft+Rim+Recreation+Site/@51.6501111,-119.9791259,15z/data=!3m1!4b1!4m6!3m5!1s0x5381df2795eaf39d:0x26b1e17931a1803c!8m2!3d51.6501116!4d-119.9688262!16s%2Fg%2F11qfznzjhg?entry=ttu`
      ),

      [
        {
          text: "Day 1 started with getting up early to drive to the viewing platform for Helmcken Falls before any crowds started. The view from the viewing platform was amazing! After that I went to the trail head for the hike that took you right to the rim of the waterfall.",
        },
        { media: "20230519Img4877", overrideWidth: 30 },
      ],
      [{ media: "20230519Dsc08127" }, { media: "20230519Dsc08157" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Helmcken+Falls/@51.9579139,-120.1968316,13z/data=!4m10!1m2!2m1!1sHelmcken+Falls!3m6!1s0x538195f7ef70a9c3:0xaf0dee3478f6da68!8m2!3d51.9539703!4d-120.1770295!15sCg5IZWxtY2tlbiBGYWxsc1oQIg5oZWxtY2tlbiBmYWxsc5IBC3NjZW5pY19zcG904AEA!16zL20vMGNwNjV2?entry=ttu`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Helmcken+Falls+Rim+Trailhead/@51.9580197,-120.1846437,13z/data=!4m10!1m2!2m1!1sHelmcken+Falls!3m6!1s0x538195c01452dc21:0x8323d36d65f8b7bb!8m2!3d51.9618719!4d-120.1342352!15sCg5IZWxtY2tlbiBGYWxsc5IBC2hpa2luZ19hcmVh4AEA!16s%2Fg%2F11r3sdply_?entry=ttu`
      ),

      [
        {
          text: "I drove to the end of the road to check out the campgrounds (which had this fun boardwalk that wasn't connected to shore). There was also a cafe out there so I decided to stop by... it way maybe one of the more awkward experiences I've had trying to find out what food they had and to order, but I got my overpriced sandwich! so there! :D",
        },
        { media: "20230519Img4891" },
        { media: "20230519Img4889" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/The+Osprey+Caf%C3%A9/@52.1354527,-120.210935,14.33z/data=!4m9!1m2!2m1!1sHelmcken+Falls!3m5!1s0x53819b8dcf7d4f31:0x200abb37f99c28ca!8m2!3d52.142389!4d-120.1929778!16s%2Fg%2F11bvt5zd7l?entry=ttu`
      ),

      {
        text: "I drove a little bit of the way back and stayed at the campground near Dawson falls, still gorgeous, but a little less overwhelming after seeing Helmcken that day :)",
      },
      [{ media: "20230519Dsc08174" }, { media: "20230519Dsc08173" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Dawson+Falls/@51.9631881,-120.1336121,15.5z/data=!4m9!1m2!2m1!1sHelmcken+Falls!3m5!1s0x538195c8a0798809:0xd6b97e2ee81163a8!8m2!3d51.9650816!4d-120.1235478!16s%2Fm%2F047p63k?entry=ttu`
      ),

      {
        text: "The next day I headed out to see some more waterfalls and start heading north again, but along the way I drove up the winding road to the Green Mountain viewing platform. There was a geocache there that was more elaborate then other ones I had found.",
      },
      [{ media: "20230519Img4903" }, { media: "20230519Dsc08177" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Green+Mountain+Viewing+Tower/@51.9258384,-120.0700294,17z/data=!4m6!3m5!1s0x538195a59b4ef67f:0x115f5d7c08fad691!8m2!3d51.9258384!4d-120.0681304!16s%2Fg%2F11fmn1gpz7?entry=ttu`
      ),

      {
        text: "Todays main waterfall was Moul falls, a short hike in to this waterfall, that was significantly shorter than Helmcken, but probably my favourite waterfall because you can get so close.",
      },
      [{ media: "20230519Img4962" }, { media: "20230519Dsc08192Hdr" }],
      {
        text: "I spent quite a bit ouf time standing and just being in the presence of this one. And also marveling in the butterflies that were flying right into the spray.",
      },
      [{ media: "20230519Dsc08203" }, { media: "20230519Img4916" }],

      [
        {
          text: "One unique thing about Moul is that you can walk behind the waterfall to the other side which is a sort of overhanging cave. The spray behind the waterfall was pretty intense and my raincoat did absolutely nothing, I was instantly soaked.",
        },
        { media: "20230519Img4951" },
      ],
      [{ media: "20230519Img4947" }, { media: "20230519Img4939" }],
      {
        text: "With the spray it was pretty hard to get photos as the lense got covered in water, but I still tried!",
      },
      [{ media: "20230519Img4956" }, { media: "20230519Img4937" }],

      [
        {
          text: "Thats it, time to hike back, after wringing out my socks and dumping the water out of my boots I started back.",
        },
        { media: "20230519Img4960" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Moul+Falls/@51.8306611,-120.0539617,14.81z/data=!4m14!1m7!3m6!1s0x5381eb6d3841e6dd:0xa971badbc19873e0!2sMoul+Falls+Parking!8m2!3d51.82659!4d-120.0264201!16s%2Fg%2F11gjb46638!3m5!1s0x5381c1e442af2a45:0x48bb8166cd3eaec1!8m2!3d51.8347557!4d-120.0498798!16s%2Fm%2F0gtv4lk?entry=ttu`
      ),

      {
        text: "Last stop was another waterfall from a viewing platform, it was a pretty spectacular one in a canyon.",
      },
      [{ media: "20230519Dsc08213" }, { media: "20230519Dsc08207" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Spahats+Creek+Falls/@51.7370943,-120.0245819,15z/data=!3m1!4b1!4m6!3m5!1s0x5381dcd336a2f963:0x66d27aff8d006d62!8m2!3d51.7370948!4d-120.0142822!16s%2Fm%2F03ql6nk?entry=ttu`
      ),
      { text: "And thats it for Wells Gray, time to continue north!" },
    ],
  },
  {
    published: true,
    title: "Mount Robson to Prince George",
    createdDate: "May 29, 2023",
    eventDate: ["May 21, 2023", "May 26, 2023"],
    chunks: [
      {
        text: "The next leg of the trip was to get to Prince George passing through Mount Robson. It was so nice to see the rockies again, I had missed them :D I wanted to do a hike in Robson park, but it turns out it was closed until July, so another time! I did stop by Rearguard falls, which is the farthest the Salmon come up the Fraser.",
      },
      [{ media: "20230521Dsc08221" }, { media: "20230521Dsc08225" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Mount+Robson+Visitor+Centre/@53.0286403,-119.2375883,15.15z/data=!4m6!3m5!1s0x53837051c9f060b7:0x6e495cbc40260f83!8m2!3d53.0355003!4d-119.2289584!16s%2Fg%2F113gmr0kw?entry=ttu`
      ),

      latLngFromUrl(
        `https://www.google.com/maps/place/Beaver+River+Recreation+Site/@53.2215632,-120.0892455,12.18z/data=!4m6!3m5!1s0x5384652214b13ced:0x7d225805fdc53c75!8m2!3d53.2577172!4d-120.0221371!16s%2Fg%2F1q62ksr1j?entry=ttu`
      ),

      {
        text: "I stopped in McBride and went to Beanery2Bistro which was this little cafe in an old train station run by a couple. The vibes at this place were immaculate, you walked into a kinda residential looking kitchen, and have some wonderfully friendly service. 10/10, would recommend.",
      },
      [
        { media: "20230521Img4978" },
        { media: "20230521Img4977" },
        { media: "20230521Img4975" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Beanery2Bistro/@53.2989992,-120.1708099,16.69z/data=!4m6!3m5!1s0x538468b06e2efb3f:0x13ff433d28b30d39!8m2!3d53.3008566!4d-120.1703376!16s%2Fg%2F1tgmvyzf?entry=ttu`
      ),

      {
        text: "Continuing on I stayed at this rec site for a few days. I was parked right on the lake with mountains on the other side, and a whole bunch of tadpoles along the shore.",
      },
      [{ media: "20230521Img4981" }, { media: "20230521Img4983" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Lasalle+Lakes+(West)+Recreation+Site/@53.5207489,-120.741499,12.46z/data=!4m6!3m5!1s0x538449810fe99dc1:0x2736e8bddc80fca4!8m2!3d53.5232299!4d-120.6795681!16s%2Fg%2F1q62fhpwz?entry=ttu`
      ),

      [
        {
          text: "I made a random stop at this chip truck thinger, It's apparently open 24 hours, though I didn't test that cause it was the afternoon :P",
        },
        { media: "20230521Img4985" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Joe+Boo's+Fast+n+Fresh+Mobile+Eatery/@53.7157105,-121.1028958,18.25z/data=!4m12!1m5!3m4!2zNTPCsDQyJzU3LjEiTiAxMjHCsDA2JzA2LjgiVw!8m2!3d53.71587!4d-121.10189!3m5!1s0x5385c75dc523bef5:0x9e61a1fb613a11e8!8m2!3d53.7161779!4d-121.1017595!16s%2Fg%2F11jcmcr26s?entry=ttu`
      ),

      [
        {
          text: "Before heading into Prince george I stopped and did the hike at The Ancient Forest, absolutely stunning old growth cedar forest.",
        },
        { media: "20230521Dsc08278" },
      ],
      [{ media: "20230521Dsc08268" }, { media: "20230521Dsc08274" }],
      [{ media: "20230521Dsc08262" }, { media: "20230521Dsc08252" }],
      [{ media: "20230521Img4991" }, { media: "20230521Dsc08259" }],
      [{ media: "20230521Dsc08246" }, { media: "20230521Dsc08248" }],
      {
        text: "There was an attempt at taking a drove video of this waterfall... I think I need to work on my cinematography a bit!",
      },
      [{ media: "20230521Dsc08244" }, { media: "20230521Dji0007" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Ancient+Forest%2FChun+T'oh+Whudujut+Provincial+Park/@53.7629956,-121.2187244,17z/data=!3m1!4b1!4m6!3m5!1s0x5385c1516477f347:0x3a39b4b8c2ec04af!8m2!3d53.7629956!4d-121.2187244!16s%2Fg%2F1pwfv2m6r?entry=ttu`
      ),

      [
        {
          text: "And to end off after picking some things up in Prince george, I headed straight to the drive in, cause I have a problem :P The first movie was Mario... again... so I cleaned Vern for that movie, and the second feature was Fast X.",
        },
        { media: "20230521Img4994" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Park+Drive-In+Theatre/@49.4984461,-129.5055476,4.08z/data=!4m10!1m2!2m1!1sdrive+in!3m6!1s0x538896a07dce51d7:0x9cb1c2d719744a14!8m2!3d54.0177688!4d-122.9488677!15sCghkcml2ZSBpbloKIghkcml2ZSBpbpIBFmRyaXZlX2luX21vdmllX3RoZWF0ZXLgAQA!16s%2Fg%2F1wbf_2b2?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "The start of Stewart-Cassiar Highway",
    createdDate: "May 31, 2023",
    eventDate: ["May 27, 2023", "May 31, 2023"],
    chunks: [
      [
        {
          text: "I left Prince George and continued west, I had bought jerry cans in Prince George so at the first campground I got to I got those strapped to the roof rack tray, it worked!",
        },
        { media: "20230527Img4999" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Riverside+Park+Campground/@54.0175575,-124.0087944,14.37z/data=!4m9!3m8!1s0x53884f06b15944f7:0x15f7f4972cbf2591!5m2!4m1!1i2!8m2!3d54.02538!4d-124.0110725!16s%2Fg%2F1hc6mvbbq?entry=ttu`
      ),

      [
        {
          text: "I continued on to Smithers where I had intended to do a hike call Twin Falls, but on my way I encountered a barricade with a sign saying it was closed for avalanche risk. So plans changed and I went even farther to Witset canyon and stayed at the campground that was right behind it. Absolutely amazing campground. I had a lovely chat with Brenda who was running the place and took advantage of the free laundry so I'd be ready to head out in the morning.",
        },
        { media: "20230527Img5010", overrideWidth: 30 },
      ],
      [{ media: "20230527Dsc08282" }, { media: "20230527Dsc08284" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Witset+Canyon/@55.0147239,-127.3405016,15z/data=!3m1!4b1!4m6!3m5!1s0x54756b2a288f1fad:0x71de5beab0949dbe!8m2!3d55.0147243!4d-127.3302019!16s%2Fg%2F11c0qgnzd5?entry=ttu`
      ),

      {
        text: "The next stop was New Hazelton, here there is the Hagwilget Canyon with a suspension bridge which was the highest suspension bridge in Canada when it was constructed.",
      },
      [{ media: "20230527Dsc08295" }, { media: "20230527Dsc08286" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Hagwilget+Canyon+Bridge/@55.257433,-127.6077182,16.06z/data=!4m14!1m7!3m6!1s0x54756b2a288f1fad:0x71de5beab0949dbe!2sWitset+Canyon!8m2!3d55.0147243!4d-127.3302019!16s%2Fg%2F11c0qgnzd5!3m5!1s0x5475496c50b41e8b:0x58760c38b65b1c59!8m2!3d55.2573778!4d-127.6043667!16s%2Fm%2F0gh8wdh?entry=ttu`
      ),

      {
        text: "I stayed in a nearby park for a few days working, the view was absolutely spectacular.",
      },
      [{ media: "20230527Img5013" }, { media: "20230527Img5018" }],
      { media: "20230527Dji0017" },
      [{ media: "20230527Dji0009" }, { media: "20230527Dji0023" }],

      [
        {
          text: "I got up to no good with the drone again... I don't know about this affect :P",
        },
        { media: "20230527Dji0019", overrideWidth: 60 },
      ],

      latLngFromUrl(
        `https://www.google.com/maps/place/Anderson+Flats+Provincial+Park/@55.242305,-127.6734279,15.63z/data=!4m6!3m5!1s0x54754992a73fc40b:0x1512003aa5f5a278!8m2!3d55.2445024!4d-127.6654926!16s%2Fg%2F11g8dhb5zn?entry=ttu`
      ),

      [
        {
          text: "And I'm off, starting driving up the Stewart-Cassiar Highway I found a spot by a lake for the night with clear view of the sky so I could setup the starlink and work the next day (No cell reception here!). I am officially the farthest north I have ever been!",
        },
        { media: "20230527Img5024" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/55%C2%B022'49.4%22N+128%C2%B006'13.7%22W/@55.3803736,-128.1141137,15z/data=!3m1!4b1!4m4!3m3!8m2!3d55.380374!4d-128.103814?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "Stewart and Hyder",
    createdDate: "June 05, 2023",
    eventDate: ["June 01, 2023", "June 04, 2023"],
    chunks: [
      {
        text: "I decided to make my first detour off the highway to go to Stewart. It's only about 45 minutes 1 way off the highway.",
      },
      {
        text: "Before heading out, I took another silly drone video from my camp spot. Now I'm done with these weird 360 video ones... gotta start trying to make good videos :P",
      },
      [{ media: "20230601Dsc08300" }, { media: "20230601Dji0027" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/55%C2%B023'05.6%22N+128%C2%B006'51.0%22W/@55.3845441,-128.1156515,396m/data=!3m1!1e3!4m10!1m5!3m4!2zNTXCsDExJzM1LjEiTiAxMjjCsDAyJzIwLjEiVw!8m2!3d55.19309!4d-128.03892!3m3!8m2!3d55.38489!4d-128.114171?entry=ttu`
      ),

      [
        {
          text: "On the way towards the turn off, I decided to walk into the woods and found this nice little waterfall. The mosquitos were pretty bad, so I better rush back to the Van and keep driving!",
        },
        { media: "20230601Dsc08301" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/55%C2%B046'46.1%22N+128%C2%B051'40.1%22W/@55.7851036,-129.0375747,11.32z/data=!4m4!3m3!8m2!3d55.7794833!4d-128.8611333?hl=en&entry=ttu`
      ),

      {
        text: "Another stop right at the turn off was this place by a river, with more mountain views. I stayed here and finished out my workday on Friday.",
      },
      [{ media: "20230601Img5029" }, { media: "20230601Img5030" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/56%C2%B005'35.7%22N+129%C2%B028'18.6%22W/@56.09326,-129.4744049,644m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d56.09326!4d-129.47183?entry=ttu`
      ),

      {
        text: "The drive... oh my, the drive was stunning. The whole way was driving with mountains on both sides, I made some stops along the way and tried to capture some of it, but it's so hard!",
      },
      [
        {
          text: "Another attempt at a drone video... I went too fast, but it still shows the scenery so I'm gonna include it!",
        },
        { media: "20230601Dji0029" },
      ],
      [
        { media: "20230601Dji0030", overlay: "20230601Dji0030Overlay" },
        { media: "20230601Dsc08305" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/56%C2%B006'25.5%22N+129%C2%B034'23.2%22W/@56.144226,-129.6814497,23216m/data=!3m1!1e3!4m4!3m3!8m2!3d56.10709!4d-129.5731?entry=ttu`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/56%C2%B006'08.9%22N+129%C2%B038'29.2%22W/@56.1029897,-129.6458718,2097m/data=!3m1!1e3!4m10!1m5!3m4!2zNTbCsDA2JzI1LjUiTiAxMjnCsDM0JzIzLjIiVw!8m2!3d56.10709!4d-129.5731!3m3!8m2!3d56.102464!4d-129.641435?entry=ttu`
      ),
      [
        {
          text: "Bear glacier! I read a sign that was describing when this glacier was bigger and damned the lake in, when the lake got high enough the glacier would actually start to float and the lake would drain underneath it causing a flood. Then it would settle back down and the lake would start filling up again. 🤯",
        },
        { media: "20230601Dsc08314" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/56%C2%B006'21.0%22N+129%C2%B040'09.1%22W/@56.105842,-129.6717699,644m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d56.105842!4d-129.669195?entry=ttu`
      ),

      {
        text: "I made it to Stewart, it was pretty late so I got a site at the campground and took a stroll around the small town including a little boardwalk on the estuary.",
      },
      [{ media: "20230601Img5046" }, { media: "20230601Img5050" }],
      [{ media: "20230601Img5041" }, { media: "20230601Img5038" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Rainey+Creek+Campground/@55.937539,-129.9992576,2224m/data=!3m1!1e3!4m9!3m8!1s0x540bce0dde2a8c4d:0xaddd5cd3238f399!5m2!4m1!1i2!8m2!3d55.9388464!4d-129.99996!16s%2Fg%2F11bbwktkp6?entry=ttu`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Estuary+Boardwalk/@55.9339586,-130.0144851,2854m/data=!3m1!1e3!4m6!3m5!1s0x540bce73312f5655:0x5040f073cd9d541c!8m2!3d55.9350676!4d-129.9964006!16s%2Fg%2F11hd6ppy6w?entry=ttu`
      ),

      {
        text: "The next morning I decided to go to Hyder and to see the Salmon Glacier. I started by stopping by the one bakery in town to get a coffee and a salmon bagel (gotta have a theme for the day :P)",
      },
      [{ media: "20230601Img5051" }, { media: "20230601Img5052" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Trudy%E2%80%99s+Temptations+Bakery/@55.9359789,-129.9949627,154m/data=!3m1!1e3!4m6!3m5!1s0x540bce0d3e10ec47:0xb640eb8d08f2ae25!8m2!3d55.9362304!4d-129.994673!16s%2Fg%2F11c2ns7_qv?entry=ttu`
      ),

      {
        text: "I crossed into Hyder, oh my, I'm in America and a new timezone :O  Theres no border crossing to get into Hyder it's a tiny town with no real way to get to the rest of Alaska. I drove out to the end of the pier to for a beautiful view of Portland Canal. From here you can see Hyder and Stewart about the same distance away.",
      },
      {
        text: "along the side of the road there were posts sticking up with bird houses on them. The posts were apparently from one of the original gold rush settlements where they built a town on piles here.",
      },
      [{ media: "20230601Dsc08326" }, { media: "20230601Dsc08324" }],
      [{ media: "20230601Dsc08320" }, { media: "20230601Dsc08321" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/55%C2%B054'17.1%22N+130%C2%B000'42.8%22W/@55.9054528,-130.0213121,6288m/data=!3m1!1e3!4m4!3m3!8m2!3d55.904743!4d-130.011874?entry=ttu`
      ),

      [
        {
          text: "Hyder only really has two stores, Caroline's gift shop and a seafood shop. In the morning I stopped by Caroline's and got some fudge. Someone had yelled at her earlier in the day, so we had a great time cursing and being jovial. We covered a lot of ground from printers being awful, to different business schemes she has had. We got to talking about bears and she ended up taking me over to her houses porch to see her bear deterrent board. She left me with advice to pee around my campsites, but to make sure I walk backwards so I don't pee on myself. All and all a lovely way to start my day :)",
        },
        { media: "20230601Img5054" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Caroline's+Boundary+Gallery+%26+Gifts/@55.9054528,-130.0213121,6288m/data=!3m1!1e3!4m6!3m5!1s0x540bce4e130d285b:0x1b33c961b35c272f!8m2!3d55.9122004!4d-130.0211137!16s%2Fg%2F11g8yp2hft?entry=ttu`
      ),

      [
        {
          text: "The big tourist attraction in Hyder is the fish creek boardwalk where from July to August you can watch Grizzlies eating salmon from the river. There are no fish at this time of year, so not the same attraction for bears but I still took a little stroll along the boardwalk.",
        },
        { media: "20230601Dsc08329" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Fish+Creek+Wildlife+Observation+Site/@55.9140061,-130.0854783,14443m/data=!3m1!1e3!4m6!3m5!1s0x540bd194b487da4d:0x718cf2c91b55a8db!8m2!3d55.9619369!4d-130.0614961!16s%2Fg%2F1ts1rxx6?entry=ttu`
      ),

      {
        text: "To Salmon Glacier! It is a bit of a drive down a forest service road. There is a viewpoint at the end which people fawn over, but unfortunately at this time of year you still can't drive all the way because as you can see I had to stop driving here because the road was covered in about 6 feet of snow. You can still see the glacier, and the rainy drive was worth it in it's own right :)",
      },
      [{ media: "20230601Dsc08331" }, { media: "20230601Dsc08335" }],
      [{ media: "20230601Dsc08344" }, { media: "20230601Dsc08345" }],
      [{ media: "20230601Dsc08366" }, { media: "20230601Dsc08350" }],
      [{ media: "20230601Dsc08362" }, { media: "20230601Dsc08363" }],
      {
        text: "Along the drive I found some abandoned equipment rusting away, and I'm a sucker for rust so I had to hop out and take some photos.",
      },
      [{ media: "20230601Dsc08355" }, { media: "20230601Dsc08358" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/56%C2%B006'04.6%22N+130%C2%B002'37.6%22W/@56.10127,-130.0463649,17z/data=!3m1!4b1!4m4!3m3!8m2!3d56.10127!4d-130.04379?hl=en&entry=ttu`
      ),

      [
        {
          text: "The seafood place mentioned earlier, all she had was chowder... so I got some chowder!",
        },
        { media: "20230601Img5062", overrideWidth: 35 },
      ],
      [
        {
          text: "And back to Stewart! I crossed through Canadian customs and was back. I met a fellow who was traveling from Victoria in his sprinter, which he mainly used for transporting musical instruments as he taught music in schools (the reason he was in Stewart). he showed in the side door of his van and it was floor to ceiling drums and other instruments, fun! :D",
        },
        { media: "20230601Dsc08368" },
      ],
      {
        text: "Before leaving Stewart I did a small hike around town where there were some massive Spruce trees.",
      },
      [{ media: "20230601Dsc08377" }, { media: "20230601Dsc08378" }],
      [{ media: "20230601Dsc08380" }, { media: "20230601Dsc08379" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/55%C2%B056'38.7%22N+129%C2%B059'40.6%22W/@55.94409,-129.9971749,17z/data=!3m1!4b1!4m4!3m3!8m2!3d55.94409!4d-129.9946?hl=en&entry=ttu`
      ),
      [
        {
          text: "This photo really doesn't capture it, but this mountain was covered in ribbons of waterfalls... it looked otherworldly.",
        },
        { media: "20230601Dsc08382" },
      ],
      [
        {
          text: "Final stop on my way out was a recreational site to make a coffee for the drive, but I noticed there was a row boat free to use by anyone so I had to go out for a little row!",
        },
        { media: "20230601Img5066", overrideWidth: 33 },
      ],
      [{ media: "20230601Img5078" }, { media: "20230601Img5079" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Clements+Lake+Recreation+Site/@56.04978,-129.9069149,17z/data=!4m12!1m5!3m4!2zNTbCsDAyJzU5LjIiTiAxMjnCsDU0JzE1LjYiVw!8m2!3d56.04978!4d-129.90434!3m5!1s0x540a2d20307344af:0x11411f59d7784ec4!8m2!3d56.0480209!4d-129.9017429!16s%2Fg%2F1pxwq36q3?hl=en&entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "First van repair",
    createdDate: "June 06, 2023",
    eventDate: ["June 04, 2023", "June 06, 2023"],
    adjustMapPadding: 0,
    chunks: [
      [
        {
          text: "It was a rainy cold weekend, with lows of 0c and highs of 5c. A bit of snow in the forecast though it seemed that mostly turned to rain at my elevation, the mountain peaks around certainly got a dusting. I spent the time at a few spots on a lake. Even though I couldn't see the mountains for most of it because of the mist, it was absolutely gorgeous. I also stopped by this random river which was pretty fun :)",
        },
        { media: "20230604Img5082" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/57%C2%B016'13.4%22N+130%C2%B016'15.7%22W/@57.27038,-130.2736149,17z/data=!3m1!4b1!4m4!3m3!8m2!3d57.27038!4d-130.27104?entry=ttu`
      ),

      { text: "Campsite 1" },
      [{ media: "20230604Img5086" }, { media: "20230604Img5087" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/57%C2%B045'46.2%22N+129%C2%B058'17.4%22W/@57.76283,-129.9740849,17z/data=!3m1!4b1!4m4!3m3!8m2!3d57.76283!4d-129.97151?entry=ttu`
      ),

      [{ text: "I had a little visitor" }, { media: "20230604Dsc08386" }],

      { text: "Campsite 2" },
      [{ media: "20230604Img5095" }, { media: "20230604Dsc08387" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Red+Goat+Lodge/@57.8139484,-129.9703349,15.13z/data=!4m15!1m5!3m4!2zNTfCsDQ1JzQ2LjIiTiAxMjnCsDU4JzE3LjQiVw!8m2!3d57.76283!4d-129.97151!3m8!1s0x53f78679f2aa4fdd:0xe83045086dd03849!5m2!4m1!1i2!8m2!3d57.8155388!4d-129.9604019!16s%2Fg%2F11c0vxffd3?entry=ttu`
      ),

      {
        text: 'And then after getting to Dease Lake, the real fun began when I noticed a hose hanging down from Vern. After some brainstorming with my dad for "What the f*** is this?" we figure out the what, and I finally found where it came from and got it put back together',
      },
      [{ media: "20230604Img5101" }, { media: "20230604Img5105" }],
      [
        {
          text: "Now it's time to eat dinner, I should probably get these hands dirtier for the next photo...",
        },
        { media: "20230604Img5110" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Nympha's+Asian+Food+Eatery/@58.4381494,-129.9991516,16.72z/data=!4m15!1m8!3m7!1s0x53f9beaf1485920b:0x814ff3f72ef39615!2sDease+Lake,+BC+V0C+1L0!3b1!8m2!3d58.43741!4d-129.9993781!16zL20vMDd6anRx!3m5!1s0x53f9bf4cc4beac7b:0xf9faaaa4d9780277!8m2!3d58.4389554!4d-129.9904345!16s%2Fg%2F11rc87m31l?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "Horses? Yes. Guardrails? No.",
    createdDate: "June 15, 2023",
    eventDate: ["June 07, 2023", "June 08, 2023"],
    adjustMapPadding: 0,
    chunks: [
      {
        text: "Now that the hose was put back, it was time to see if I could shake it loose again. The next side trip from the highway was to drive Telegraph Creek Road which was a pretty fun windy gravel road along the Stikine river and the canyon.",
      },

      {
        text: "I gave making the drone follow me while I drive a go for the first time, and it worked but I had a bad SD card so the video file was no good, I'll still post it, speeding it up helped a bit but it's still pretty jittery! I also pulled my dash cam for the drive for another angle :)",
      },

      [{ media: "20230607Dji0001Fast" }, { media: "20230607Driving" }],

      {
        text: "Some higher angle photos of the segment of the drive I had posted the videos for.",
      },
      [{ media: "20230607Dji0003" }, { media: "20230607Dji0004" }],

      {
        text: "dropping into the canyon, I attempted to take another drove video, but it still had the bad SD card... so the best I could do is this video that kinda looks like stop motion :)",
      },
      [{ media: "20230607Dji0006" }, { media: "20230607Dji0008Fast" }],

      {
        text: "Along the drive I had to keep stopping a wandering off the road for some amazing views.",
      },
      [{ media: "20230607Dsc08391" }, { media: "20230607Dsc08404" }],

      [{ media: "20230607Dsc08410" }, { media: "20230607Dsc08419" }],

      {
        text: "I stopped at a rest area for a little break from driving and a view of the canyon, but it was already full... with horses! They were wandering around munching on the grass.",
      },
      [{ media: "20230607Img5114" }, { media: "20230607Dsc08403" }],

      {
        text: "These two came over and gave me the stair down... I think they were muscling me out as they threatened to rip the mirror off of Vern.",
      },
      [{ media: "20230607Img5117" }, { media: "20230607Img5115" }],

      {
        text: "On the second day I had to work, so I went back to that same rest area with the horses, but they were of course all gone. But I setup the starlink and worked away before driving back to Dease Lake.",
      },
      [{ media: "20230607Img5126" }, { media: "20230607Dji0040" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/58%C2%B001'05.0%22N+130%C2%B056'08.5%22W/@58.0188636,-130.9366197,302m/data=!3m1!1e3!4m7!1m2!2m1!1srest+area!3m3!8m2!3d58.018068!4d-130.935686?entry=ttu`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Telegraph+Creek,+BC+V0J+2W0/@57.9025647,-131.1709977,1480m/data=!3m1!1e3!4m6!3m5!1s0x53f86d512a51dda9:0x4e4172c128edd67f!8m2!3d57.90293!4d-131.159156!16zL20vMDY4Mjl0?entry=ttu`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Glenora,+BC+V0J+2W0/@57.8378625,-131.386695,2459m/data=!3m2!1e3!4b1!4m15!1m8!3m7!1s0x53f86d512a51dda9:0x4e4172c128edd67f!2sTelegraph+Creek,+BC+V0J+2W0!3b1!8m2!3d57.90293!4d-131.159156!16zL20vMDY4Mjl0!3m5!1s0x53f874e281c33527:0xd5eeb08e61b682bc!8m2!3d57.837863!4d-131.386695!16s%2Fm%2F05b03d4?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "Cassiar",
    createdDate: "June 17, 2023",
    eventDate: "June 10, 2023",
    adjustMapPadding: 0,
    chunks: [
      {
        text: "The last venture off off the highway until yukon, I popped over to Cassiar an asbestos mine ghost town. There isn't much left here in the town and they've closed off the road to the mine, but the drive here and the mountains are incredible!",
      },
      [{ media: "202306100012" }, { media: "202306100014" }],
      [{ media: "20230610Dsc08437" }, { media: "20230610Dsc08442" }],
      [{ media: "20230610Dsc08450" }, { media: "20230610Dsc08454" }],
      [{ media: "20230610Dsc08457" }, { media: "20230610Dsc08458" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Cassiar,+BC+V0W+1A0/@59.2821491,-129.8271946,15z/data=!3m1!4b1!4m6!3m5!1s0x53fbc20cbbcb91cb:0x5c7e2582eab81ffb!8m2!3d59.28215!4d-129.8168949!16zL20vMDRnOXht?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "Tā Ch'ilā Park",
    createdDate: "June 20, 2023",
    eventDate: ["June 10, 2023", "June 11, 2023"],
    adjustMapPadding: 30,
    chunks: [
      {
        text: "I stopped at Tā Ch'ilā Park for the night, and the next day did a little hike to a beaver damn.",
      },
      [{ media: "20230611Dsc08464" }, { media: "20230611Dsc08465" }],
      {
        text: "The rest of the day I spent kayaking around the lakes and bays, beautiful area!",
      },
      [{ media: "20230611Dsc08466" }, { media: "20230611Img5131" }],
      [
        {
          text: "I came across a loon that kept diving and coming up by me. The waters were so clear I could see it swimming underneath the kayak!",
        },
        { media: "20230611Dsc08560" },
      ],
      {
        text: "It was far to far away when it flapped it's wings... but I tried to get some photos and crop them when it did.",
      },
      [{ media: "20230611Dsc08571" }, { media: "20230611Dsc08572" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Boya+Lake+Provincial+Park,+Stikine+Region,+BC/@59.3836126,-129.2625014,11z/data=!3m1!4b1!4m6!3m5!1s0x53fba5acc51826ab:0xf48fb7aaa9a998d7!8m2!3d59.3780989!4d-129.0778037!16zL20vMGJua3F0?entry=ttu`
      ),

      {
        text: "The day ended driving into the Yukon, I'm officially north of the 60° parallel! (and obviously the new farthest north I've been) The sun is setting at 11:30 and this point and the \"night\" is not really dark, twilight all night! Only 1,800km left to get to the arctic ocean :)",
      },
      [{ media: "20230611Dsc08581" }, { media: "20230611Dsc08583" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Welcome+to+Yukon+Sign/@59.9983532,-129.0539287,17.92z/data=!4m15!1m8!3m7!1s0x53fba5acc51826ab:0xf48fb7aaa9a998d7!2sBoya+Lake+Provincial+Park,+Stikine+Region,+BC!3b1!8m2!3d59.3780989!4d-129.0778037!16zL20vMGJua3F0!3m5!1s0x53fb610a312c0135:0x22bd4a4076241c0c!8m2!3d59.9986099!4d-129.0530564!16s%2Fg%2F11p66g7fg8?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "The Alaska Highway to Carcross",
    createdDate: "July 03, 2023",
    eventDate: ["June 12, 2023", "June 14, 2023"],
    // adjustMapPadding: 30,
    chunks: [
      {
        text: "After crossing into the Yukon and staying at a lovely gravel rest stop, I headed out again to go West to Whitehorse! I stretched my legs with a stop at this little recreation site with a trail to a boardwalk around this nice waterfall :)",
      },
      [{ media: "20230612Dsc08594" }, { media: "20230612Dsc08587" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Rancheria+Falls/@60.0881711,-130.8550268,12.3z/data=!4m6!3m5!1s0x53fc4f2d9e70b73f:0xe4b002b1f017f646!8m2!3d60.0779845!4d-130.8267205!16s%2Fg%2F11gbt_rn2y?entry=ttu`
      ),

      {
        text: "Done driving for the day I needed to find somewhere to sleep, and this one was a keeper. Whenever there is a sign like this, thats always a good indication, and it did not disappoint. At this radio tower there was a perfect place to park with 360 views of mountains.",
      },
      [{ media: "20230612Img5164" }, { media: "20230612Dsc08597" }],
      { text: "In the morning, I did yoga like an influencer :D" },
      [{ media: "20230612Img5162" }, { media: "20230612Dsc08607" }],
      {
        text: "And then it was time to leave and head to Carcross! I tried taking another video of part of the drive down... it worked ok...",
      },
      [{ media: "20230612Dji0039" }, { media: "20230612Driving" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/59%C2%B056'07.3%22N+131%C2%B017'28.8%22W/@59.9326444,-131.2955295,2115m/data=!3m1!1e3!4m10!1m5!3m4!2zNTnCsDU2JzA3LjMiTiAxMzHCsDE3JzI5LjMiVw!8m2!3d59.93535!4d-131.29147!3m3!8m2!3d59.935352!4d-131.291327?entry=ttu`
      ),

      // Biking for the last 18 years, 37 countries started in china on way to Alaska
      {
        text: "At a random rest stop there were some old equipment left out which was pretty fun, here I also met Chen, who was currently biking to Alaska. He had be biking around the world for the last 18 years, and had already been to 37 countries!",
      },
      [{ media: "20230612Dsc08618" }, { media: "20230612Dsc08625" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/60%C2%B029'26.1%22N+133%C2%B017'41.0%22W/@60.490593,-133.2961229,569m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d60.490593!4d-133.294714?entry=ttu`
      ),

      [
        {
          text: "While driving to Carcross I took a little pull off to a little dirt road to try to get some photos of the surrounding mountains, but before I got out of the van I saw 2 grizzly bears directly in front of me! Again, too far to get a good photo with a 20mm lens, but I tried! by the time I got the photo, one had already left.",
        },
        { media: "20230612Dsc08633", overrideWidth: 60 },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Scenery+view+point/@60.21768,-134.6212649,574m/data=!3m1!1e3!4m12!1m5!3m4!2zNjDCsDEzJzAzLjciTiAxMzTCsDM3JzA3LjMiVw!8m2!3d60.21768!4d-134.61869!3m5!1s0x51550b04b226996f:0x613443b9e9b393ae!8m2!3d60.2171726!4d-134.6198106!16s%2Fg%2F11tds2jp_h?entry=ttu`
      ),

      {
        text: "While in Carcross I did a few hikes, the first was lovely, had incredible views the whole way up and some interesting old mining structures left behind. A lot of grouse hanging out on this trail, and a few very much didn't think I should be there and asked me to leave by politely raising it's wings and running at me.",
      },
      [{ media: "20230612Dji0002" }, { media: "20230612Dji0003" }],
      [{ media: "20230612Dji0005" }, { media: "20230612Dji0010" }],
      [{ media: "20230612Dsc08660" }, { media: "20230612Dsc08638" }],
      [{ media: "20230612Dsc08643" }, { media: "20230612Img5170" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/60%C2%B000'45.6%22N+134%C2%B038'13.0%22W/@60.012121,-134.6384031,577m/data=!3m1!1e3!4m4!3m3!8m2!3d60.012679!4d-134.636944?entry=ttu`
      ),

      {
        text: "The lower part of the Venus Silver Mine was this structure that was still standing even with the unstable ground.",
      },
      [
        { media: "20230612Dji00031" },
        { media: "20230612Dji00041" },
        { media: "20230612Dji0008" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Venus+Silver+Mine/@60.0139416,-134.6374925,3154m/data=!3m1!1e3!4m6!3m5!1s0x51556df356b9b32f:0x48a58022f19183ea!8m2!3d60.0207819!4d-134.6239494!16s%2Fg%2F11s5qlg6b_?entry=ttu`
      ),

      [
        {
          text: "The second hike was Sam McGee, another one along some old mining equipment and a tram line. again beautiful views, wildflowers everywhere and a relentless incline. On the way down, I cam across what I think was a Lynx sitting directly on the trail. I stopped and it hadn't seem me, and as I was thinking of what to do it looked over at me, and ran off. I spent a little more time talking to myself out loud after that :) ",
        },
        { media: "20230612Dji0047" },
      ],
      [{ media: "20230612Dsc08679" }, { media: "20230612Dsc08704" }],
      [{ media: "20230612Dsc08663" }, { media: "20230612Dsc08690" }],
      [{ media: "20230612Dsc08666" }, { media: "20230612Dsc08668" }],
      [
        { media: "20230612Dsc08697" },
        { media: "20230612Dsc08694" },
        { media: "20230612Dsc08695" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Sam+McGee+Hike+Trailhead/@60.053732,-134.593431,2919m/data=!3m1!1e3!4m6!3m5!1s0x515573187db25ebb:0xea405253edb0312c!8m2!3d60.0599898!4d-134.5724582!16s%2Fg%2F11s8y5qgfh?entry=ttu`
      ),

      [
        {
          text: "After the hikes I was pretty tired, so I stopped by the Bennet lake lookout which was a gorgeous view of the lake and mountains, and also some kite surfers :)",
        },
        { media: "20230612Dsc08719" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Bennett+Beach+Lookout/@60.1680163,-134.7135184,1057m/data=!3m1!1e3!4m15!1m8!3m7!1s0x51550bf452cd3023:0xbed32878aeaa519f!2sCarcross,+YT!3b1!8m2!3d60.1675851!4d-134.7073506!16zL20vMDNwOGxy!3m5!1s0x51550c69632f66db:0xf92a9d1c7c3ac959!8m2!3d60.1667353!4d-134.7118126!16s%2Fg%2F11f31p18_w?entry=ttu`
      ),

      {
        text: "Last stop before heading to Whitehorse, Carcross desert. I think by definition it isn't a desert, but it is the remnants of a lake that left this sand dune area in between the mountains. ",
      },
      [{ media: "20230612Dsc08720" }, { media: "20230612Dsc08721" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Carcross+Desert/@60.1870011,-134.7180157,3944m/data=!3m1!1e3!4m6!3m5!1s0x51550bf5c82f0d07:0x3f3f74d33cf310ce!8m2!3d60.187222!4d-134.694722!16s%2Fm%2F02z0nzh?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "Kluane",
    createdDate: "July 07, 2023",
    eventDate: ["June 15, 2023", "June 22, 2023"],
    chunks: [
      latLngFromUrl(
        `https://www.google.com/maps/place/Whitehorse,+YT/@60.7018345,-135.7374914,9z/data=!3m1!4b1!4m6!3m5!1s0x5154449880bc5381:0xe43db7ea64d0354b!8m2!3d60.7197137!4d-135.052276!16zL20vMDgxczc?entry=ttu`
      ),

      {
        text: "I arrived in whitehorse and hung around for a bit, picking up packages, going to the lovely farmers market, and getting groceries. I had one package that was going to be arriving the following week, so instead of hanging around Whitehorse for another week, I decided to go to Kluane which was definitely on the list of places I needed to stop this summer.",
      },
      {
        text: "The drive continued to be absolutely beautiful which is a real theme for this summer. I arrived and found an absolutely beautiful place by a river with a view of the mountains in the park.",
      },
      [{ media: "20230617Dsc08724" }, { media: "20230617Dsc08725" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/60%C2%B040'36.7%22N+137%C2%B021'30.5%22W/@60.6768599,-137.3633539,566m/data=!3m2!1e3!4b1!4m7!1m2!2m1!1srest+stop!3m3!8m2!3d60.67686!4d-137.358483?entry=ttu`
      ),

      {
        text: "I did the King's throne hike, this hike has 2 legs. The first is to the throne, and then the hike continues to the summit. I really wanted to do the full hike and to try and get a glimpse of Mt Logan. I made it about half way up the second leg and did something to my foot where it hurt to put weight on it. I decided to do the responsible thing, as disappointing as it was, and turn around and not risk my foot getting worse and getting stuck on the mountain. Luckily the hike opens up to an amazing view of Kathleen lake pretty quickly, so it was still a wonderful day.",
      },
      [{ media: "20230617Img5189" }, { media: "20230617Dsc08741" }],
      { media: "20230617Dsc08769Pano", extraStyles: { maxWidth: undefined } },
      {
        text: "The second photo here is a not so good picture of the throne, while I was here a couple of people who had hiked up skied down the snow that you see in this photo :)",
      },
      [{ media: "20230617Dsc08775" }, { media: "20230617Dsc08750" }],
      [{ media: "20230617Dsc08738" }, { media: "20230617Dsc08751" }],

      [
        {
          text: "And finally, after the hike I went for a swim in Kathleen lake. Fun fact, Kathleen lake has kokanee which are salmon that don't return to the ocean anymore. For Kathleen lake salmon this is because at one point a glacier blocked them getting back to the ocean and they had to adapt.",
        },
        { media: "20230617Dsc08783" },
      ],

      [
        {
          text: "Unfortunately the next day my foot was a bit worse so I couldn't do any more hikes while I was here, so it was back to Whitehorse and start the drive north! But first I picked up some packages including a care package from Claire, receiving mail in random places around the country is pretty fun :P",
        },
        { media: "20230617Img5196", overrideWidth: 30 },
      ],
    ],
  },
  {
    published: true,
    title: "The start of the Dempster Highway",
    createdDate: "July 08, 2023",
    eventDate: ["June 22, 2023", "June 23, 2023"],
    adjustMapPadding: 39,
    shiftMapDown: -30,
    chunks: [
      latLngFromUrl(
        `https://www.google.com/maps/place/Shipyards+Park/@60.7243813,-135.0653164,15.45z/data=!4m15!1m8!3m7!1s0x5154449880bc5381:0xe43db7ea64d0354b!2sWhitehorse,+YT!3b1!8m2!3d60.7197137!4d-135.052276!16zL20vMDgxczc!3m5!1s0x515444e9c6a45e25:0xa1b841c96f9369dc!8m2!3d60.7272753!4d-135.0564237!16s%2Fg%2F11fxcg42kw?entry=ttu`
      ),

      {
        text: "After stopping by the market in whitehorse again and stocking up on groceries I headed out. First stop was only about 3 hours away where I met up with Jen who I met in Whitehorse and her friend with their 3 very energetic dogs. For all the energy they were also super well behaved as shown with them all waiting patiently for their treats :)",
      },
      [{ media: "20230622Img5210" }, { media: "20230622Img5208" }],

      {
        text: "This was the first midnight sun I saw, the sun did set shortly after midnight, but the second picture here of the sunset was right around midnight.",
      },
      [{ media: "20230622Img5202" }, { media: "20230622Img5204" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Nunatuk+Campground/@62.1016457,-136.008062,11.94z/data=!4m10!1m2!2m1!1scampground!3m6!1s0x515199cce7ba1767:0xb3ebf6677a1ac646!8m2!3d62.1645557!4d-135.7989421!15sCgpjYW1wZ3JvdW5kkgEKY2FtcGdyb3VuZOABAA!16s%2Fg%2F11g0g5kmqj?entry=ttu`
      ),

      {
        text: "The next day I drove the rest of the way to the start of the dempster, where I actually did drive up a little bit for this camp spot along the river with an old crane in the woods, but we are going to ignore that fact because then I went to Dawson City the next day and THEN my drive on the dempster began :D",
      },
      [
        { media: "20230622Dsc08791" },
        { media: "20230622Dsc08795" },
        { media: "20230622Dsc08790" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/64%C2%B001'13.7%22N+138%C2%B035'04.0%22W/@64.0395969,-138.9509691,9.62z/data=!4m4!3m3!8m2!3d64.02048!4d-138.58443?entry=ttu`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Tacos/@64.0614567,-139.4364922,19.83z/data=!4m15!1m8!3m7!1s0x5148e3cbd4f5b5b7:0x4a8d7ab95cad1ffd!2sDawson+City,+YT!3b1!8m2!3d64.0639058!4d-139.4332999!16zL20vMDJ5ejBw!3m5!1s0x5148e3c85b4c2663:0x295ff7659580c545!8m2!3d64.0615867!4d-139.4362189!16s%2Fg%2F11t9rmpm8f?entry=ttu`
      ),

      [
        {
          text: "After filling my belly with tacos in Dawson City, I'm back at the start of the Dempster! This is the last time I will see pavement (well, there is a small section of pavement in Inuvik, but it's only little). 887km left to get to Tuktoyaktuk!",
        },
        { media: "20230622Dsc08786", overrideWidth: 60 },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Dempster+Highway+Sign/@63.9921938,-138.7553691,13.96z/data=!4m14!1m7!3m6!1s0x5148dbd12d586adf:0xc9b21caac3ee0a0f!2sDempster+Highway+Sign!8m2!3d63.9903232!4d-138.7492479!16s%2Fg%2F11h94449z0!3m5!1s0x5148dbd12d586adf:0xc9b21caac3ee0a0f!8m2!3d63.9903232!4d-138.7492479!16s%2Fg%2F11h94449z0?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "The Dempster North",
    createdDate: "October 27, 2023",
    eventDate: ["June 24, 2023", "July 08, 2023"],
    adjustMapPadding: 39,
    shiftMapDown: -30,
    chunks: [
      {
        text: `
          It's taken me awhile to get this post together, but here it is!
          The trip north on the Dempster highway!
          I tried something new and mounted my camera to the windshield so I could film the full drive, this is the result.`,
      },
      { text: "Enjoy 17 hours of driving, in 6 minutes." },
      [{ media: "20230624FullDrive" }],
      [
        {
          text: `
            The first stop along the Dempster was Tombstone Territorial Park where I stayed a night in the campground. 
            The views from the campground were pretty great!`,
        },
        { media: "20230624Img5222" },
      ],
      latLngFromUrl(
        "https://www.google.com/maps/place/Tombstone+Campground/@64.5060362,-138.2307906,15z/data=!3m1!4b1!4m6!3m5!1s0x51489fd388e707e1:0xc7abc9900c03a3b9!8m2!3d64.5060366!4d-138.2204909!16s%2Fg%2F11c2kkd3pz?entry=ttu"
      ),

      {
        text: `
          On the way north I only stopped for 1 hike in Tombstone, and that was Rake Ridge. This hike blew me away, and definitely made me fall in love with Tombstone.
          I got really lucky on the way up and there were no mosquitos, once I was on the ridge it did start to rain a little and the wind was wild, but that just added to the magical ambiance :D
          On the way down when I got off the ridge, the mosquitos seemed to all come out at once and it was a pretty brutal hike the rest of the way to the van, but still totally worth it!`,
      },
      { media: "20230624Dsc08812Pano", extraStyles: { maxWidth: undefined } },
      [{ media: "20230624Dsc08817" }, { media: "20230624Dsc08820" }],
      [{ media: "20230624Dsc08825" }, { media: "20230624Dsc08829" }],
      [{ media: "20230624Dsc08871" }, { media: "20230624Dsc08876" }],
      [{ media: "20230624Dsc08887" }, { media: "20230624Dsc08838" }],
      {
        text: "I spent quite a bit of time at different spots a long the ridge, including a stop to do some knitting :)",
      },
      [{ media: "20230624Img5254" }, { media: "20230624Dsc08848" }],
      [
        {
          text: "Lego Jeffrey joined on this hike as well and definitely enjoyed the views :)",
        },
        { media: "20230624Dsc08854" },
      ],
      [{ media: "20230624Dsc08862" }, { media: "20230624Img5259" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/64%C2%B038'23.3%22N+138%C2%B025'50.2%22W/@64.641081,-138.3810103,331m/data=!3m1!1e3!4m4!3m3!8m2!3d64.6398!4d-138.4306?entry=ttu`
      ),

      {
        text: "After the hike, I worked for awhile at this beautiful boondocking spot. I ended up staying here for a few days during the week to work because the starlink was working pretty well.",
      },
      [{ media: "20230624Img5272" }, { media: "20230624Img5278" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/64%C2%B037'08.3%22N+138%C2%B022'02.4%22W/@64.6189796,-138.3776397,1968m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d64.61898!4d-138.36734?entry=ttu`
      ),

      {
        text: "I continued on to Eagle Plains which is the first gas station after getting on the Dempster after 370km.",
      },
      {
        text: "There were a few overnights before getting to Eagle Plains, and at the last one while I was packing up a fella came over because he had gotten stuck in some fairly soft ground. He was pretty far out there so I couldn't get that close, but we gave it our best to tow him out. After a few attempts, we gave up and I headed out to send a tow truck from Eagle Plains. When I got there I found out they stopped doing towing at all and the next place would be Fort McPherson, which is where I was heading the next day. After I got back into cell reception the next day to contact him, he had messaged me because a work crew had come back and gotten him out with a back hoe, so everything worked out!",
      },
      {
        text: "Eagle Plains is also extremely close to the Arctic circle, so the sun doesn't really set. This second photo was taken at midnight",
      },
      [{ media: "20230624Img5292" }, { media: "20230624Img5296" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/65%C2%B045'56.8%22N+137%C2%B053'57.8%22W/@65.7657897,-137.9096797,1884m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d65.76579!4d-137.89938?entry=ttu`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Eagle+Plains,+YT+Y0B+1N0/@66.3710776,-136.720468,1840m/data=!3m2!1e3!4b1!4m6!3m5!1s0x51409875c7cf8831:0xabcf7fdeaf6fb802!8m2!3d66.371078!4d-136.720468!16s%2Fg%2F1tfgcwqn?entry=ttu`
      ),

      {
        text: "I made it, The Arctic Circle! The weather was something else this day, these two photos were take about 15 minutes apart, and it kept changing like this all day.",
      },
      [{ media: "20230624Dsc08948" }, { media: "20230624Dsc08952" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Arctic+Circle+Sign/@66.5653259,-136.3174528,1826m/data=!3m2!1e3!4b1!4m6!3m5!1s0x51408431468de3e1:0x5356cf5704d27e8a!8m2!3d66.5653262!4d-136.3071531!16s%2Fg%2F11bz_081zv?entry=ttu`
      ),

      [
        {
          text: "Continuing on, I made it the Northwest Territories for the first time! Now I've been to almost all of the provinces and territories, Nunavut is the last one to check off.",
        },
        { media: "20230624Dsc08956" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Yukon-Northwest+Territories+border/@67.0513543,-136.2067073,739m/data=!3m1!1e3!4m6!3m5!1s0x513f93f370ff2297:0x3afb1d22082126d8!8m2!3d67.0516686!4d-136.2020749!16s%2Fg%2F11g1gnk5rx?entry=ttu`
      ),

      {
        text: `
          One of my favourite boondocking spots along the Dempster was this radio tower. Radio towers are always great because they are high up, clear views and theres an access road for bringing fuel up, though those roads are normally pretty rough, steep, and winding. I saw this radio tower while I was driving so I was on the lookout for the access road, when I came across the road for it, I took it up to see if I could stay there.
        `,
      },
      [{ media: "20230624Dji0006" }, { media: "20230624Dsc08980" }],
      {
        text: `When I got there (and all night) the wind was strong enough to the point where I spent some time googling "How strong does wind need to be to blow over a van".`,
      },
      [{ media: "20230624Img5327" }, { media: "20230624Img5320" }],
      {
        text: "But I soon decided I was fine, and made cookies instead of worrying.",
      },
      [{ media: "20230624Dsc08985" }, { media: "20230624Img5330" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/67%C2%B010'50.0%22N+135%C2%B051'28.7%22W/@67.180561,-135.857959,445m/data=!3m2!1e3!4b1!4m10!1m5!3m4!2zNjfCsDEwJzQzLjkiTiAxMzXCsDUxJzE2LjAiVw!8m2!3d67.17887!4d-135.85443!3m3!8m2!3d67.180561!4d-135.857959?entry=ttu`
      ),

      {
        text: "There aren't a lot of hiking opportunities once you're in the Arctic circle, but there was one along the way so I had to stop. It was a nice little hike, but this was by far the worst the mosquitos had been on my trip. People always talk about how bad they are and up to this point I had thought \"Sure theres a bunch of mosquitos, but nothing abnormal\", but getting out of the van here changed that, immediately me and my backpack were absolutely swarmed, once I returned to the van it took forever to get all the ones that got in with me.",
      },
      [{ media: "20230624Dsc08987" }, { media: "20230624Dsc08991" }],
      [{ media: "20230624Dsc08994" }, { media: "20230624Dsc09000" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Tithegeh+Chii+Vitaii+lookout+(Gwich%E2%80%99in+Park+Lookout+Trailhead)/@68.1863497,-133.4598461,1706m/data=!3m1!1e3!4m12!1m5!3m4!2zNjjCsDExJzEwLjkiTiAxMzPCsDI3JzE5LjIiVw!8m2!3d68.18635!4d-133.45534!3m5!1s0x5114bf860b026c91:0x4ee9733d67a837bd!8m2!3d68.186955!4d-133.4422795!16s%2Fg%2F11frrxnh2t?entry=ttu`
      ),

      {
        text: "Inuvik was a quick stop because when I got there, a heatwave was forecast and I thought the farther north I could get the more comfortable I would be.",
      },
      {
        text: `The main thing I needed to do before leaving was laundry. Bob's welding was the place to do it, with a small 4 machine laundromat, but when I got there all of the machines were out of order. 
        I knew the campground in town at least use to have machines so I went over there to ask, but they were still closed.
        The kid working made a phone call, and told me to wait and someone would be here to help me. Then his parents showed up (neither of them had any association with the campground or the laundromat) but they took me over to Bob's welding to see if they could sort it out. After we decided the machines were definitely down and he talked to a few people, they offered to let me do laundry at their place even though they had just gotten back from a road trip to Edmonton. We went over to their place, and chatted while my laundry was in, turned out he had worked on the construction of the highway to tuk that I was about to be driving on!`,
      },
      {
        text: "I was about to leave, and then they came out and said they were heading over to the community garden to check out their lot and said I should come. So I went to see it, it's a giant greenhouse that use to be Inuvik's stadium before they built a new one, and now was repurposed in the summer as a community garden.",
      },
      [
        { media: "20230624Img5350" },
        { media: "20230624Img5352" },
        { media: "20230624Img5349" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Inuvik+Community+Garden+Society/@68.3598907,-133.7297947,1693m/data=!3m1!1e3!4m14!1m7!3m6!1s0x511493e6968ba46f:0x132bda54e7b517d6!2sInuvik+Community+Garden+Society!8m2!3d68.359891!4d-133.719495!16s%2Fg%2F1tcxzgbr!3m5!1s0x511493e6968ba46f:0x132bda54e7b517d6!8m2!3d68.359891!4d-133.719495!16s%2Fg%2F1tcxzgbr?entry=ttu`
      ),

      {
        text: "I made it! Tuktoyaktuk! When I started thinking about this whole van thing, I didn't have many destinations in mind, but Tuktoyaktuk was always one of them.",
      },
      [{ media: "20230624Dsc09022" }, { media: "20230624Dsc09012" }],
      {
        text: "There is a campground right at the end of the road on the Arctic ocean, so of course I stayed here and worked for a few days. Now fully in the 24hr sunlight, I also made my self stay up till 3am to see the sun not set and just turn around and start going up.",
      },
      [{ media: "20230624Img5395" }, { media: "20230624Img5387" }],
      {
        text: "While I was here, I took my typewriter out to write letters to some people, it seemed like a pretty good landmark in the van adventure to write from :)",
      },
      [{ media: "20230624Img5400" }, { media: "20230624Img5402" }],
      [
        {
          text: "Not pictured is me swimming in the Arctic ocean, but pictured is my awesome fashion choices of pink marlin swim trunks and blundstones.",
        },
        // { media: "20230624Img5370" },
        { media: "20230624Img5372" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Arctic+Ocean+Sign+-+Tuktoyaktuk/@69.4554687,-133.0369972,403m/data=!3m2!1e3!4b1!4m6!3m5!1s0x5113cb07e549a18b:0xa0b4a7e9e702b90f!8m2!3d69.4554687!4d-133.0369972!16s%2Fg%2F11rp38bxgp?entry=ttu`
      ),

      [
        {
          text: "And now it's time for me to head back south and spend some more time in Tombstone. First stop is Inuvik again!",
        },
        { media: "20230624Dji0015" },
      ],

      // [{ media: "20230624Img5339" }],
    ],
  },
  {
    published: true,
    title: "The Dempster South",
    createdDate: "January 19, 2024",
    eventDate: ["July 09, 2023", "July 22, 2023"],
    adjustMapPadding: 39,
    shiftMapDown: -30,
    chunks: [
      {
        text: "The drive from Tuktoyaktuk to Inuvik had some pretty wild clouds, so still lots of chances to stop for some views.",
      },
      [{ media: "20230709Dji0021Faster" }, { media: "20230709Dsc09032" }],
      [{ media: "20230709Dsc09036" }, { media: "20230709Dsc09040" }],
      [{ media: "20230709Dsc09051" }, { media: "20230709Dsc09056" }],

      {
        text: "I didn't go to Alestine's on my way north, so I had to stop when I was passing by again going south. The place was packed when I was there and I ended up sitting with Caroline and Guillaume who were traveling from Vancouver and Vancouver island to a conference. Inuvik was just a layover for them and after we ate and chatted for awhile they were off to fly home, a bit of a faster trip then mine :)",
      },
      [{ media: "20230709Img5418" }, { media: "20230709Img5419" }],
      [{ media: "20230709Img5420" }, { media: "20230709Img5422" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Alestine's/@68.3578224,-133.7415487,15.11z/data=!4m15!1m8!3m7!1s0x5114923f95f996d1:0x522391a237afb784!2sInuvik,+NT+X0E+0T0!3b1!8m2!3d68.3593674!4d-133.7294462!16zL20vMDFoZzBq!3m5!1s0x5114924089fe9429:0xe67afb0a49b1e4d8!8m2!3d68.3593152!4d-133.7331256!16s%2Fg%2F11cp5hyswp?entry=ttu`
      ),

      {
        text: "Another stop I missed on the way north was Fort McPherson Tent and Canvas, I stopped by and bought a few bags.",
      },
      [{ media: "20230709Img5440" }, { media: "20230709Img5427" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Fort+McPherson+Tent+and+Canvas/@67.4376309,-134.8930355,15z/data=!3m1!4b1!4m6!3m5!1s0x51154e2eee7cd6a1:0xb74ce8c0aa312e6e!8m2!3d67.4376312!4d-134.8827358!16s%2Fg%2F1tdx3s73?entry=ttu`
      ),

      [
        {
          text: "Back to the radio tower, one of my favourite spots on the Dempster",
        },
      ],
      [{ media: "20230709Combined2" }, { media: "20230709Dsc09054" }],
      [
        {
          text: "While there a marmot offered a free oil change, I had to decline and relocate the van to stop them from trying again.",
        },
        { media: "20230709Img5433" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/67%C2%B011'05.7%22N+135%C2%B051'03.4%22W/@67.1817192,-135.8636322,2397m/data=!3m1!1e3!4m4!3m3!8m2!3d67.18491!4d-135.85094?entry=ttu`
      ),

      {
        text: "Good bye Northwest Territories! Hopefully I will be back one day when I make it to Yellowknife.",
      },
      [{ media: "20230709Dsc09081" }, { media: "20230709Dsc09071" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Yukon,+Larger+Than+Life+sign/@66.571477,-137.7051412,274296m/data=!3m1!1e3!4m10!1m2!2m1!1syukon+sign!3m6!1s0x513f933b1287cedf:0xf9adaa97f440af3!8m2!3d67.0517825!4d-136.2024303!15sCgp5dWtvbiBzaWdukgETaGlzdG9yaWNhbF9sYW5kbWFya-ABAA!16s%2Fg%2F11f6yxny9h?entry=ttu`
      ),

      { text: "Had some fun flying my drone in the clouds" },
      [{ media: "20230709Dji0043" }, { media: "20230709Dji0047" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/65%C2%B050'44.5%22N+137%C2%B041'30.7%22W/@65.8456797,-137.7021497,1871m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d65.84568!4d-137.69185?entry=ttu`
      ),

      {
        text: "Before tombstone, the landscape had started to get the mountains back, and there was a hike so I was very excited to get back on the trail! The mosquitos were still pretty bad, but the views were wonderful and I was the only one out.",
      },
      [{ media: "20230709Dsc09100" }, { media: "20230709Dji0118" }],
      [{ media: "20230709Dsc09111" }, { media: "20230709Dsc09126" }],
      {
        text: "I climbed on top of ridge to do some knitting, but my yarn was very tangled... Feels like in a place like this yarn shouldn't tangle.",
      },
      [
        { media: "20230709Img5455" },
        { media: "20230709Dsc09103" },
        { media: "20230709Dsc09114" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/65%C2%B021'35.9%22N+138%C2%B016'32.8%22W/@65.3612828,-138.3045249,5781m/data=!3m1!1e3!4m4!3m3!8m2!3d65.35997!4d-138.27579?entry=ttu`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/65%C2%B005'07.4%22N+138%C2%B021'41.8%22W/@65.0853996,-138.3719197,1926m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d65.0854!4d-138.36162?entry=ttu`
      ),

      [
        {
          text: "Just north of tombstone was this little unofficial camp spot right on the river. It was absolutely gorgeous. One day a park ranger came by just to confirm I was self contained and not doing anything I shouldn't in the spot, and we ended up talking for awhile about the different parts of her job that involve dealing with poop.",
        },
        { media: "20230709Img5466" },
      ],
      [{ media: "20230709Dsc09147" }, { media: "20230709Img5467" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/64%C2%B042'55.4%22N+138%C2%B022'39.0%22W/@64.7153996,-138.3877997,1953m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d64.7154!4d-138.3775?entry=ttu`
      ),

      {
        text: "Back in Tombstone, I've gotta do some hikes! This hike I did at 10pm because a ranger told me the light was great at that time. unfortunately I don't think the weather was quite right, so I didn't get the perfect light but it was still great!",
      },
      [{ media: "20230709Img5473" }, { media: "20230709Dsc09155" }],
      [{ media: "20230709Dsc09158" }, { media: "20230709Dsc09159" }],
      [{ media: "20230709Dsc09172" }, { media: "20230709Dsc09168" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Goldensides+Trailhead/@64.5155893,-138.2384317,6945m/data=!3m1!1e3!4m12!1m5!3m4!2zNjTCsDMxJzI0LjIiTiAxMzjCsDExJzE2LjgiVw!8m2!3d64.5234!4d-138.188!3m5!1s0x514621bd7cde1641:0xd24b9b1965a1391c!8m2!3d64.5245857!4d-138.2183079!16s%2Fg%2F11rhq8sk1j?entry=ttu`
      ),

      {
        text: "Last hike of tombstone, the weather was definitely not ideal. I got rain, hail, thunder, and lightning. Still had a great time, and had some good conversations with Brody who had recently moved to Whitehorse and lived in an RV in the summer with his family. He made a very good case for moving to the Yukon :D",
      },
      [{ media: "20230709Dsc09198" }, { media: "20230709Dsc09203" }],
      [
        { media: "20230709Img5480" },
        { media: "20230709Img5483" },
        { media: "20230709Img5478" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Grizzly+Lake+Trailhead/@64.4146123,-138.3236621,2551m/data=!3m1!1e3!4m12!1m5!3m4!2zNjTCsDI1JzExLjQiTiAxMzjCsDE5JzA0LjEiVw!8m2!3d64.41984!4d-138.3178!3m5!1s0x514899ed2afbbf85:0x9d1a8d66899b9b17!8m2!3d64.4130682!4d-138.3045845!16s%2Fg%2F11fkr5_gmx?entry=ttu`
      ),
      {
        text: "And thats it, the last stop on the Dempster. I'm off to finish the drive, and find a shower.",
      },
    ],
  },
  {
    published: true,
    title: "Dawson city",
    createdDate: "January 21, 2024",
    eventDate: ["July 23, 2023", "July 26, 2023"],
    adjustMapPadding: 39,
    shiftMapDown: -30,
    chunks: [
      [
        {
          text: "First stop after the Dempster is of course Dawson city, I didn't spend to much time here but I did go to a market and bought some yarn that had a blend of sheep and husky hair, I'm a sucker :P",
        },
        { media: "20230723Dsc09252" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/64%C2%B002'13.9%22N+139%C2%B018'02.9%22W/@64.0371896,-139.3111037,2001m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d64.03719!4d-139.300804?entry=ttu`
      ),
      [{ media: "20230723Img5504" }, { media: "20230723Img5509" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Dawson+City,+YT/@64.0562418,-139.4450528,2250m/data=!3m1!1e3!4m15!1m8!3m7!1s0x5148e3cbd4f5b5b7:0x4a8d7ab95cad1ffd!2sDawson+City,+YT!3b1!8m2!3d64.0639058!4d-139.4332999!16zL20vMDJ5ejBw!3m5!1s0x5148e3cbd4f5b5b7:0x4a8d7ab95cad1ffd!8m2!3d64.0639058!4d-139.4332999!16zL20vMDJ5ejBw?entry=ttu`
      ),

      {
        text: "I decided to drive the Canadian side of the top of the world highway to make it as far west as I could drive.",
      },
      [{ media: "20230723Dsc09206" }, { media: "20230723Img5500" }],
      { text: "In the second picture here, you can see the border crossing." },
      [{ media: "20230723Dsc09210" }, { media: "20230723Img5501" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Sixty+Mile/@64.0560688,-140.9816848,4042m/data=!3m1!1e3!4m12!1m5!3m4!2zNjTCsDAzJzExLjUiTiAxNDDCsDU3JzAyLjciVw!8m2!3d64.0532!4d-140.95076!3m5!1s0x5149bd1960f6d18b:0x5444c2ab0958b258!8m2!3d64.0508544!4d-140.9618412!16s%2Fg%2F11q1td22py?entry=ttu`
      ),
      {
        text: "The views were pretty hazy from smoke, but I also decided to take a detour off the main road to go see the forty mile ghost town, unfortunately the hike was closed to public access, so I couldn't see it. But it was still a nice drive!",
      },
      [{ media: "20230723Dsc09213" }, { media: "20230723Dsc09216" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/64%C2%B024'44.6%22N+140%C2%B032'07.3%22W/@64.412391,-140.5372824,494m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d64.412391!4d-140.535359?entry=ttu`
      ),

      {
        text: "Back in Dawson, I did the tiny hike to the paddle wheel graveyard, where a bunch of the old paddle wheel boats were left once they were decommissioned. ",
      },
      [{ media: "20230723Dsc09221" }, { media: "20230723Dsc09222" }],
      [{ media: "20230723Dsc09224" }, { media: "20230723Dsc09231" }],
      [{ media: "20230723Dsc09240" }, { media: "20230723Dsc09248" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Paddlewheel+Graveyard/@64.083395,-139.4407796,417m/data=!3m1!1e3!4m6!3m5!1s0x5148e3a3cc678d67:0x2150a777bfafe429!8m2!3d64.0844171!4d-139.4420063!16s%2Fg%2F11bz0q8xzd?entry=ttu`
      ),

      [
        {
          text: "And I'm off! Time to leave Dawson, and go to Whitehorse. While driving on the highway, I passed a bunch of horses having a strolled down the road. :)",
        },
        { media: "20230723Img5513" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/63%C2%B055'42.8%22N+138%C2%B030'29.3%22W/@63.9285496,-138.5184297,2009m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d63.92855!4d-138.50813?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "Kings Throne Round Two",
    createdDate: "January 29, 2024",
    eventDate: ["July 29, 2023", "July 30, 2023"],
    chunks: [
      latLngFromUrl(
        `https://www.google.com/maps/place/Whitehorse,+YT/@60.6940726,-135.0742105,12.79z/data=!4m15!1m8!3m7!1s0x5154449880bc5381:0xe43db7ea64d0354b!2sWhitehorse,+YT!3b1!8m2!3d60.7197137!4d-135.052276!16zL20vMDgxczc!3m5!1s0x5154449880bc5381:0xe43db7ea64d0354b!8m2!3d60.7197137!4d-135.052276!16zL20vMDgxczc?entry=ttu`
      ),

      [
        {
          text: `
            I headed back to Whitehorse and I met Rachel and her two dogs Kinder and Tuna. We decided to go to Kluane for the weekend to do a hike and paddleboard. I rented a paddle board and we headed out. It was my first time paddle boarding, and it turns out it's pretty great! I did stand up, but thats not pictured... it didn't go horribly.
          `,
        },
        { media: "20230729Img5518" },
        { media: "20230729Img0032" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/60%C2%B048'04.4%22N+137%C2%B029'04.6%22W/@60.8012126,-137.4949027,2230m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d60.801213!4d-137.484603?entry=ttu`
      ),

      {
        text: "Last time I tried to do the full Kings Throne hike, I hurt my foot, and had to turn around while I was starting on the ridge. So this was my second chance to get to the summit. We camped near by and got started early.",
      },
      [{ media: "20230729Img5571" }, { media: "20230729Img5552" }],
      [{ media: "20230729Img5568" }, { media: "20230729Img5554" }],
      {
        text: "Kings throne was just as beautiful, and even more fun with this crew.",
      },
      [{ media: "20230729Img0033" }, { media: "20230729Img5526" }],
      [{ media: "20230729Img5534" }, { media: "20230729Img5547" }],
      [{ media: "20230729Img5550" }, { media: "20230729Img5560" }],

      {
        text: "This time we did end up making it to the summit, and it was so worth it! While we were enjoying the views, and thunderstorm did start rolling in so we were in quite the rush on the way down, but we made it back, cooled off by jumping in Kathleen Lake, and enjoyed some udon for dinner.",
      },
      [{ media: "20230729Img5563Pano", extraStyles: { maxWidth: undefined } }],
      [{ media: "20230729Img5559", extraStyles: { maxWidth: undefined } }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Kings+Throne+Peak/@60.4744666,-137.332635,29279m/data=!3m1!1e3!4m6!3m5!1s0x515353df5f2d10f1:0xb3d6ff640ed0460e!8m2!3d60.5469444!4d-137.2577777!16s%2Fg%2F11h94jzf11?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "Keno",
    createdDate: "January 30, 2024",
    eventDate: ["August 03, 2023", "August 07, 2023"],
    chunks: [
      [
        {
          text: "Next up, I'm headed to Keno City for a music festival, along the way I stopped at Five Finger Rapids lookout which my last route had skipped over.",
        },
        { media: "20230803Img5583" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Five+Finger+Rapids+Lookout/@62.2731044,-136.3491109,15.87z/data=!4m6!3m5!1s0x5151edcf9abc6549:0x9c464fa349382c87!8m2!3d62.2732658!4d-136.3427807!16s%2Fg%2F11fqxw8r2c?entry=ttu`
      ),

      [
        {
          text: "Keno City Music Festival is a little free music festival in Keno City, which is a remote old mining town, I think theres around 20 year round residents. Its a fairly unique festival when the sun doesn't go down!",
        },
        { media: "20230803Untitled2", overrideWidth: 30 },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Keno+City,+YT+Y0B+1J0/@63.9265375,-135.2588265,12z/data=!3m1!4b1!4m12!1m5!3m4!2zNjPCsDU0JzMzLjEiTiAxMzXCsDE4JzU3LjIiVw!8m2!3d63.9092!4d-135.3159!3m5!1s0x514512b2f79ec663:0x137e1a134025d38e!8m2!3d63.908307!4d-135.303471!16zL20vMDYwcXEy?entry=ttu`
      ),

      {
        text: "After the festival I drove up an old service road to do a short hike at the top of the one of the mountains around Keno. Before heading out on the hike I decided to mount the fan remote to the wall, it had been floating around the van for long enough!",
      },
      [
        { media: "20230803Img5595" },
        { media: "20230803Dsc09253" },
        { media: "20230803Img5597" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Signpost+at+Keno+Hill/@63.94265,-135.2190196,17z/data=!4m12!1m5!3m4!2zNjPCsDU2JzMzLjUiTiAxMzXCsDEyJzU5LjIiVw!8m2!3d63.94265!4d-135.21645!3m5!1s0x5145127d94906337:0x45bb066f6dbf511!8m2!3d63.9434915!4d-135.2168642!16s%2Fg%2F11fxvbwf6w?entry=ttu`
      ),

      [{ media: "20230803Img5596" }, { media: "20230803Img5600" }],
      { media: "20230803Dsc09258Pano", extraStyles: { maxWidth: undefined } },
      latLngFromUrl(
        `https://www.google.com/maps/place/63%C2%B056'34.8%22N+135%C2%B010'41.2%22W/@63.9468015,-135.194784,13.45z/data=!4m4!3m3!8m2!3d63.943!4d-135.1781?entry=ttu`
      ),
      {
        text: "While I was hiking, about an hour into roaming, I got an evacuation alert. Keno was under a warning, but Mayo which you have to drive through to get to Keno was under and evacuation order. I didn't want to get stuck, so I turned around and trail rain back to the parking lot, from here the sky was already filled with smoke.",
      },
      [
        { media: "20230803Img5608" },
        { media: "20230803Dsc09264" },
        { media: "20230803Dsc09266" },
      ],
      {
        text: "After driving faster then I probably should have down the very bumpy service road, I was back on the main gravel road and headed towards Mayo. I'll admit, it felt a bit weird driving directly towards the forest fire.",
      },
      [{ media: "20230803Dsc09270" }, { media: "20230803Img5609" }],

      [
        {
          text: "After I made it past mayo, I stopped at a rest stop for a bit of a break, the sky looked bonkers. From what I could find out, the forest fire got within a few kilometers of Mayo, but luckily did not make it to the town before it was put out.",
        },
        { media: "20230803Img5610" },
        { media: "20230803Img5611" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Mayo,+YT+Y0B+1M0/@63.5956474,-135.8946459,15z/data=!3m1!4b1!4m12!1m5!3m4!2zNjPCsDM2JzQwLjYiTiAxMzXCsDUyJzU0LjEiVw!8m2!3d63.61129!4d-135.88168!3m5!1s0x514f8900596d2aeb:0xa533edf7a621f1f2!8m2!3d63.594205!4d-135.8965631!16zL20vMDdrYzYz?entry=ttu`
      ),
      {
        text: "Onwards to Whitehorse, and the sky was pretty fun because of a storm that was coming in!",
      },
      [{ media: "20230803Img5615" }, { media: "20230803Img5617" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/61%C2%B030'48.5%22N+135%C2%B047'44.1%22W/@61.51346,-135.7981496,17z/data=!3m1!4b1!4m4!3m3!8m2!3d61.51346!4d-135.79558?entry=ttu`
      ),

      [
        {
          text: "Back in Whitehorse, I decided to take care of a few chores and fill some chips in my windshield, and also take apart my fan motor to clean it to see if I could stop the squeaking... it helped for a bit...",
        },
        { media: "20230803Img5619" },
        { media: "20230803Img5630" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/60%C2%B040'20.5%22N+135%C2%B001'27.1%22W/@60.67236,-135.0267696,17z/data=!3m1!4b1!4m4!3m3!8m2!3d60.67236!4d-135.0242?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "Kluane the Trilogy",
    createdDate: "February 04, 2024",
    eventDate: ["August 12, 2023", "August 15, 2023"],
    chunks: [
      [
        {
          text: "In Whitehorse I met back up with Rachel, and we quickly learnt one of the big benefits of traveling with 2 vans, more kitchen space! We spent the night at a campground to charge our batteries and use the facilities and make a delicious dinner. The plan for the next day was to head out to Kluane 1 last time before leaving the Yukon.",
        },
        { media: "20230812Img5637" },
        { media: "20230812Img5639" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Hi+Country+RV+Park/@60.68293,-135.0640049,17z/data=!4m15!1m5!3m4!2zNjDCsDQwJzU4LjYiTiAxMzXCsDAzJzQxLjIiVw!8m2!3d60.68293!4d-135.06143!3m8!1s0x515444d35387b3d9:0x3178284a94076244!5m2!4m1!1i2!8m2!3d60.6840264!4d-135.0603487!16s%2Fg%2F1td6xryw?entry=ttu`
      ),

      {
        text: "The next day we got to Kluane in the afternoon so we didn't have time for a full hike, but there was a short hike that was on both of our lists so we headed out! It ended up being insanely windy... Not the best hike, but still nice to be out!",
      },
      [{ media: "20230812Img5649" }, { media: "20230812Img5647" }],
      { media: "20230812Img5646Pano", extraStyles: { maxWidth: undefined } },

      latLngFromUrl(
        `https://www.google.com/maps/place/Soldier's+Summit+Trailhead/@61.0116556,-138.7620536,10.86z/data=!4m6!3m5!1s0x5152d341ab8e9e29:0xebdabd268daf6ea4!8m2!3d61.0253649!4d-138.5055149!16s%2Fg%2F11rnhxfflj?entry=ttu`
      ),

      [
        {
          text: "We were going to stay in the Kluane area for the week while we worked, so we found an absolutely beautiful spot on the lake to setup.",
        },
        { media: "20230812Img5658" },
      ],
      [{ media: "20230812Img5653" }, { media: "20230812Img5657" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/61%C2%B002'05.3%22N+138%C2%B029'47.3%22W/@61.03481,-138.4990349,17z/data=!3m1!4b1!4m4!3m3!8m2!3d61.03481!4d-138.49646?entry=ttu`
      ),

      {
        text: `Later that week after work we headed out for the other longer hike that was on both of our lists. 
          On the way to the trail head, which was on a pretty long dirt road, we picked up a couple that was hitchhiking back to the trail head.
          They had locked their keys in their rv and had to walk down the road to the visitor center to call a friend to come with a spare.
          We actually ran into them later on the hike, they had managed to make it into the RV, so their friend didn't end up needing to come :)`,
      },
      {
        text: "The weather was perfect, and the views were wonderful on the trail. I'm going to miss Kluane!",
      },
      [{ media: "20230812Dsc09296" }, { media: "20230812Dsc09302" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/%C3%84%E2%80%99%C3%A4y+Ch%C3%B9+(Slim's+River)+West+Trailhead/@61.0060807,-138.5556076,417m/data=!3m1!1e3!4m14!1m7!3m6!1s0x5131310dc59de4b3:0x4fff8c43a51b7809!2sSheep+Creek!8m2!3d65.2779268!4d-144.6884!16s%2Fg%2F11bw7plwxv!3m5!1s0x5152d3ad0d3e396b:0x2966c276ce6ddae8!8m2!3d61.005527!4d-138.5548783!16s%2Fg%2F11hdvsyf_2?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "Alaska Highway Part 1",
    createdDate: "June 11, 2024",
    eventDate: ["August 17, 2023", "August 20, 2023"],
    chunks: [
      [
        {
          text: "The original plan was to hang out in Carcross for a bit, but Rachel needed to be in Calgary soon so we decided to drive a little further and do a hike in Atlin.",
        },
        { media: "20230817Img6995" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/60%C2%B019'43.8%22N+134%C2%B000'16.8%22W/@60.328845,-134.004657,17z/data=!3m1!4b1!4m13!1m8!3m7!1s0x53ffc78585000853:0xd7886c4153b70659!2sAtlin,+BC+V0W+1A0!3b1!8m2!3d59.578028!4d-133.689524!16zL20vMGJuN25k!3m3!8m2!3d60.328845!4d-134.004657?entry=ttu`
      ),

      { text: "The hike in Atlin!" },
      { media: "20230817Dsc09342Pano", extraStyles: { maxWidth: undefined } },
      [{ media: "20230817Img5668" }, { media: "20230817Dsc09333" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Monarch+Mountain+Trailhead/@59.550409,-133.670429,15z/data=!4m6!3m5!1s0x53ffb924f9ee8c6f:0x125c8f50928b4ba1!8m2!3d59.5504092!4d-133.6616756!16s%2Fg%2F11gsbf_hj2?entry=ttu`
      ),

      {
        text: "Next stop on the way to Calgary is Watson lake to see the sign post forest, they tout it as the largest public collection of stolen items",
      },
      [{ media: "20230817Img5673" }, { media: "20230817Img5674" }],
      [{ media: "20230817Img5675" }, { media: "20230817Img5676" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Sign+Post+Forest/@60.0633542,-128.724375,15z/data=!3m1!4b1!4m6!3m5!1s0x53fb5900ba8cf3d1:0xb57b5b259939fc9b!8m2!3d60.0633546!4d-128.7140753!16s%2Fm%2F076wrd4?entry=ttu`
      ),

      [
        { text: "The drive along the Alaska Highway was filled with wildlife" },
        { media: "20230817Img5683" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/59%C2%B054'11.1%22N+127%C2%B026'07.9%22W/@59.9030896,-127.4458397,15z/data=!3m1!4b1!4m4!3m3!8m2!3d59.90309!4d-127.43554?entry=ttu`
      ),

      [
        {
          text: "The last stop before Rachel needs to drive super fast to Calgary and I take a bit more time on the Alaska Highway was the Liard hot springs. The source of the water was crazy hot, absolutely could not walk to that side of the pool, but there was also a fun channel where the water got cooler.",
        },
        { media: "20230817Img7033" },
        { media: "20230817137Bf035D60742D2B2Fc4817B48E4576" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Liard+River+Hot+Springs+Provincial+Park/@59.4254771,-126.1026402,17z/data=!3m1!4b1!4m6!3m5!1s0x53e55d7967fd2629:0x7cd55d2fcc54c92d!8m2!3d59.4254771!4d-126.1026402!16zL20vMGJoYnc2?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "Alaska Highway Part 2",
    createdDate: "June 12, 2024",
    eventDate: ["August 21, 2023", "August 26, 2023"],
    chunks: [
      [
        {
          text: "After Rachel took off for Calgary, I found a place with a pretty good view for the night",
        },
        { media: "20230821Img5709" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/59%C2%B009'15.4%22N+125%C2%B052'19.9%22W/@59.15427,-125.8747649,17z/data=!3m1!4b1!4m4!3m3!8m2!3d59.15427!4d-125.87219?entry=ttu`
      ),

      {
        text: "The next day I drove to Muncho lake to work for the day and had some lunch",
      },
      [{ media: "20230821Dsc09350" }, { media: "20230821Img5717" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/58%C2%B058'04.0%22N+125%C2%B046'10.1%22W/@58.9648247,-125.8358531,12.85z/data=!4m4!3m3!8m2!3d58.96779!4d-125.76947?entry=ttu`
      ),

      {
        text: "I didn't do to many things along the Alaska Highway, but I did stay in some beautiful spots.",
      },
      [{ media: "20230821Img5719" }, { media: "20230821Img5722" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/58%C2%B046'29.9%22N+125%C2%B040'56.4%22W/@58.7714772,-125.6948972,13.99z/data=!4m4!3m3!8m2!3d58.77497!4d-125.68233?entry=ttu`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/58%C2%B042'09.2%22N+124%C2%B053'56.8%22W/@58.70255,-124.9016849,17z/data=!3m1!4b1!4m4!3m3!8m2!3d58.70255!4d-124.89911?entry=ttu`
      ),

      {
        text: "I drove down a super long logging road to do this hike. It's always a good signal when there are warning signs :)",
      },
      [{ media: "20230821Img5737" }, { media: "20230821Dsc09372" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Sikanni+Chief+Falls+Protected+Area/@57.2553544,-122.9931286,14.46z/data=!4m12!1m5!3m4!2zNTfCsDE1JzI1LjIiTiAxMjLCsDU4JzUzLjgiVw!8m2!3d57.25699!4d-122.9816!3m5!1s0x53ed423a7dd892c5:0x35a433dd4301f791!8m2!3d57.2608736!4d-122.9791743!16s%2Fg%2F1tm1q49g?entry=ttu`
      ),

      {
        text: "Driving towards Calgary, the views started to be filled with more smoke, it was a scary year for fires :(",
      },
      [{ media: "20230821Img5748" }, { media: "20230821Img5752" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Fort+St.+John+Lookout+of+Peace+River/@56.2096798,-120.8477393,19.5z/data=!4m12!1m5!3m4!2zNTbCsDEyJzMzLjgiTiAxMjDCsDUwJzQ4LjIiVw!8m2!3d56.20939!4d-120.84671!3m5!1s0x539236d2810415df:0x1ec47f37f45bc846!8m2!3d56.2097892!4d-120.8473815!16s%2Fg%2F11fxxtvrzw?entry=ttu`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Mile+0+Alaska+Highway/@55.7588344,-120.2258861,18.55z/data=!4m12!1m5!3m4!2zNTXCsDQ1JzMyLjIiTiAxMjDCsDEzJzMxLjEiVw!8m2!3d55.75893!4d-120.2253!3m5!1s0x53919937969ebe61:0x3285c2fd6a9829a9!8m2!3d55.7588268!4d-120.225148!16s%2Fg%2F11d_87lw_0?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "Calgary",
    createdDate: "June 13, 2024",
    eventDate: ["August 27, 2023", "September 02, 2023"],
    chunks: [
      {
        text: "I drove the rest of the way and arrived in Calgary. Then I got to look after these two for the day :)",
      },
      [{ media: "20230827Img5768" }, { media: "20230827Img5769" }],
      latLngFromUrl(
        `https://www.google.com/maps/place/Nose+Hill+64+Ave+Parking+Lot/@51.1087389,-114.0843735,16z/data=!3m1!4b1!4m6!3m5!1s0x537165ec87460d2f:0x2058dc6fe69ed747!8m2!3d51.1087389!4d-114.0843735!16s%2Fg%2F11cnbw8x8j?entry=ttu`
      ),

      {
        text: `
          Rachel planned us a cute date night of making gelato and going out for dinner.
          We made our own gelato flavour at Righteous Gelato that was a coffee base with caramel ribbon and waffle cone bits, we called it "Espresso Yourself Caramelly".
          Then had dinner at one of her favourite restaurants Ten Foot Henry, and everything was amazing.
          We can exist in the city too!`,
      },
      [
        { media: "20230827Img5796" },
        { media: "2023082720230905212507" },
        { media: "20230827Img5799" },
        { media: "20230827Img5800" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Righteous+Gelato/@51.0278404,-114.0728585,12.47z/data=!4m11!1m3!2m2!1sgelato!6e5!3m6!1s0x53717001d3d6d997:0x33b8c370367010fc!8m2!3d51.050937!4d-114.0090127!15sCgZnZWxhdG9aCCIGZ2VsYXRvkgEOaWNlX2NyZWFtX3Nob3CaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVUndlSFo2TUZaUkVBReABAA!16s%2Fg%2F11jfmbzwrz?entry=ttu`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Ten+Foot+Henry/@51.0410713,-114.0762833,15z/data=!3m1!4b1!4m6!3m5!1s0x53717002d4178cb1:0x591e9f39b0fab6a9!8m2!3d51.0410718!4d-114.0659836!16s%2Fg%2F11clydx0_c?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "First Backcountry Camping trip!",
    createdDate: "June 16, 2024",
    eventDate: ["September 06, 2023", "September 08, 2023"],
    chunks: [
      // night before
      latLngFromUrl(
        `https://www.google.com/maps/place/51%C2%B023'16.4%22N+116%C2%B007'51.9%22W/@51.3877244,-116.1328643,610m/data=!3m1!1e3!4m4!3m3!8m2!3d51.387885!4d-116.131074?entry=ttu`
      ),
      // trailhead
      latLngFromUrl(
        `https://www.google.com/maps/place/51%C2%B026'22.4%22N+116%C2%B009'04.7%22W/@51.439542,-116.1538849,712m/data=!3m2!1e3!4b1!4m15!1m10!3m9!1s0x53776bb54b4c457b:0x8b0a93060d65cc00!2sSkoki+Lodge!5m2!4m1!1i2!8m2!3d51.5228923!4d-116.0776084!16s%2Fg%2F11b6syl8b4!3m3!8m2!3d51.439542!4d-116.15131?entry=ttu`
      ),
      // baker lake campground
      latLngFromUrl(
        `https://www.google.com/maps/place/51%C2%B029'38.9%22N+116%C2%B002'01.7%22W/@51.4927814,-116.0352656,617m/data=!3m1!1e3!4m12!1m7!3m6!1s0x53776a74737ac6a3:0xbec4e91a8095c6d8!2sBaker+Lake!8m2!3d51.492463!4d-116.0429444!16s%2Fg%2F11clgfctt2!3m3!8m2!3d51.494149!4d-116.033804?entry=ttu`
      ),
      // near merlin meadow
      latLngFromUrl(
        `https://www.google.com/maps/place/Skoki+Lodge/@51.5226105,-116.0974107,1991m/data=!3m1!1e3!4m20!1m10!3m9!1s0x53776bb54b4c457b:0x8b0a93060d65cc00!2sSkoki+Lodge!5m2!4m1!1i2!8m2!3d51.5228923!4d-116.0776084!16s%2Fg%2F11b6syl8b4!3m8!1s0x53776bb54b4c457b:0x8b0a93060d65cc00!5m2!4m1!1i2!8m2!3d51.5228923!4d-116.0776084!16s%2Fg%2F11b6syl8b4?entry=ttu`
      ),
      {
        text: `
          Up until this point of my life I have wanted to try backcountry camping, but still had never done it.
          Lucky for me, Rachel had reserved campsites for the Skoki loop which you have to reserve at the beginning of the year because they get completely booked right when they are available. 
          Which means my first backcountry camping experience gets to be pretty amazing!
          <br/><br/>
          We stayed in Lake Louise the night before so we could get an early start, and when we woke up it was pouring rain.
          After waiting a bit hoping it would lessen, we headed to the trailhead to push through the weather!
        `,
      },
      [
        {
          text: `
            The beginning of this hike is along a service road that feels like it goes on forever.
            The whole way up it rained, and as we got farther along we started passing people coming back down. 
            Everyone we passed was saying that the first mountain pass we had to go through was in complete whiteout conditions, 
            many of their backpacks were still covered in snow.
            Even people that were going to be staying at the same site as us that night were turning around and bailing on the trip because the campsite was covered in snow.
            Given that we were in the mountains, we decided to keep hiking because who knows what the weather would do and we could always turn around later!
          `,
        },
        { media: "20230906Photo20230906120322Pm" },
        { media: "20230906Img5807" },
      ],
      {
        text: "It was a good thing we did push through and ignore the people turning around, because when we emerged from the service road and trees to the first clearing where the views start, the weather switched to blue skies and beautiful!",
      },
      [{ media: "20230906Dsc09383" }, { media: "20230906Dsc09415" }],
      {
        text: "With the better weather, we took a detour to an absolutely gorgeous lake for a break.",
      },
      { media: "20230906Dsc09398Pano", extraStyles: { maxWidth: undefined } },

      [
        {
          text: "Halfway through the first day, and I was obviously feeling so good. packing for 3 days is an awful lot heavier then just 1 🤣",
        },
        { media: "20230906Photo2023090633108Pm" },
        { media: "20230906Photo2023090633134Pm" },
      ],
      {
        text: "Back on the trail very motivated to get to the first campsite!",
      },
      { media: "20230906Dsc09437Pano", extraStyles: { maxWidth: undefined } },
      [
        { media: "20230906Dsc09432" },
        { media: "20230906Photo2023090652519Pm" },
      ],
      [
        {
          text: "We made it to the first campsite, and all the snow had melted, woohoo! We had our dehydrated meals, which obviously tasted amazing after hiking 15km with 706 metres of elevation gain. Then I got to put our food up in my first bear hang :P",
        },
        { media: "20230906Photo2023090664745Pm" },
        { media: "20230906Img5818" },
      ],
      { text: "First night in the cozy tent playing some games." },
      [{ media: "20230906Img5840" }, { media: "20230906Photo2023090675328Pm" }],

      {
        text: "In the morning, we walked down to the lake we were camped next to and admired the view, then filtered some water for the day.",
      },

      [{ media: "20230906Img5834" }, { media: "20230906Img5836" }],
      {
        text: "And we are off on day 2! this is the easiest of the 3 days because theres only a few hundred metres of elevation gain.",
      },
      [{ media: "20230906Dsc09466" }, { media: "20230906Dsc09471Pano" }],
      {
        text: "We made it to campsite 2, setup our hammocks and hung out reading for a bit.",
      },
      [
        { media: "20230906Dsc09479" },
        { media: "20230906Img5851" },
        { media: "20230906Img5853" },
      ],

      [
        {
          text: `
            And the final day begins! I was very amused by this fork in the road sign :P
          `,
        },
        { media: "20230906Img5875" },
      ],
      {
        text: `It wasn't larch season yet, but as we got near this waterfall, we started seeing some larches changing colour!
        <br/><br/>The trail goes up somewhere in the wall, but we totally missed it and ended up doing a scramble up the other side to get to the top.
        `,
      },
      [{ media: "20230906Dsc09508" }, { media: "20230906Img5879" }],

      {
        text: "The whole hike is incredible, but day 3 is something else. There were two lakes that you hike past, and the colour of both are incredible! This is a view of the first one from the top of the hill we scrambled up, and you can kinda see how it feeds the waterfall.",
      },
      { media: "20230906Dsc09524Pano", extraStyles: { maxWidth: undefined } },

      { text: "Still the first lake, but from when we got to the shore line." },
      [{ media: "20230906Dsc09531" }, { media: "20230906Dsc09533" }],

      {
        text: "Still the first lake, but my favourite view, I could have stayed here all day and it's also where we decided to have lunch.",
      },
      [{ media: "20230906Dsc09543" }, { media: "20230906Dsc09537" }],
      {
        text: "and the second lake! Another amazing blue lake with a waterfall in the background. This is where we started the last chunk of elevation gain to make it over deception pass",
      },
      [
        { media: "20230906Dsc09549" },
        { media: "20230906Photo2023090813350Pm" },
      ],
      [{ media: "20230906Img5894", extraStyles: { maxWidth: undefined } }],
      {
        text: "The view from the top of deception pass! It's all downhill from here, and our minds have definitely started to imagine the food we are going to have for dinner.",
      },
      { media: "20230906Dsc09562Pano", extraStyles: { maxWidth: undefined } },
      [
        {
          text: `
            After 3 days, 45km, and over 1200 metres of elevation gain... this burger was incredible :D 
            I am immediately hooked, and definitely need to start planning more trips!
          `,
        },
        { media: "20230906Img5897" },
      ],
      latLngFromUrl(
        `https://www.google.com/maps/place/Three+Bears+Brewery+%26+Restaurant/@51.1769958,-115.5775169,716m/data=!3m2!1e3!4b1!4m6!3m5!1s0x5370cb9b295ec7d1:0x2c82e7d5da634d2!8m2!3d51.1769959!4d-115.572646!16s%2Fg%2F11lgssh1zm?entry=ttu`
      ),
    ],
  },
  {
    published: true,
    title: "Waterton",
    createdDate: "June 17, 2024",
    eventDate: ["September 09, 2023", "September 22, 2023"],
    chunks: [
      // Upper Kananaskis lake
      latLngFromUrl(
        `https://www.google.ca/maps/place/Upper+Kananaskis+Lake+Area+Parking/@50.610978,-115.1198398,354m/data=!3m1!1e3!4m14!1m7!3m6!1s0x5370602afac49b7f:0x95d748bb580f1cb5!2sUpper+Kananaskis+Lake!8m2!3d50.6152141!4d-115.1614327!16s%2Fm%2F025x74n!3m5!1s0x537061e708ed6d95:0x916b4ee09c4914a8!8m2!3d50.6108566!4d-115.118749!16s%2Fg%2F11jbh924zt?entry=ttu`
      ),

      // where we did night photography
      latLngFromUrl(
        `https://www.google.com/maps/place/50%C2%B058'12.8%22N+115%C2%B006'34.9%22W/@50.970224,-115.1105057,360m/data=!3m2!1e3!4b1!4m12!1m7!3m6!1s0x5370e7e913a1ec51:0xd94cfadf37b7812c!2sMt+Lorette+Ponds!8m2!3d50.969719!4d-115.1069595!16s%2Fg%2F1tp26yzr!3m3!8m2!3d50.970224!4d-115.10969?entry=ttu`
      ),

      // Waterton campground
      latLngFromUrl(
        `https://www.google.com/maps/place/Townsite+Campground/@49.0487051,-113.9117524,1002m/data=!3m1!1e3!4m18!1m8!3m7!1s0x536f44ca57edde7b:0xf1d0e0f62df04403!2sWaterton+Park,+AB+T0K+2M0!3b1!8m2!3d49.0509828!4d-113.9085676!16s%2Fm%2F0416mnz!3m8!1s0x536f5b3495d5ad2b:0x795464cdcdab8cce!5m2!4m1!1i2!8m2!3d49.0485902!4d-113.9093296!16s%2Fg%2F11g_7bg0t?entry=ttu`
      ),

      // bears hump
      latLngFromUrl(
        `https://www.google.com/maps/place/Bear's+Hump+Trailhead/@49.0608319,-113.9106584,749m/data=!3m2!1e3!4b1!4m6!3m5!1s0x536f45ac3323f077:0xc1eb4aea7e096b28!8m2!3d49.0608319!4d-113.9080835!16s%2Fg%2F11sv_qppqs?entry=ttu`
      ),

      // camp spot
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B048'41.9%22N+114%C2%B012'45.3%22W/@49.81163,-114.2151549,738m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d49.81163!4d-114.21258?entry=ttu`
      ),

      // driving
      latLngFromUrl(
        `https://www.google.com/maps/place/50%C2%B011'09.3%22N+114%C2%B026'31.5%22W/@50.18592,-114.4446649,732m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d50.18592!4d-114.44209?entry=ttu`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/50%C2%B012'09.8%22N+114%C2%B026'40.1%22W/@50.20272,-114.4470449,732m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d50.20272!4d-114.44447?entry=ttu`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/50%C2%B012'39.1%22N+114%C2%B026'25.9%22W/@50.21087,-114.4430949,731m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d50.21087!4d-114.44052?entry=ttu`
      ),

      {
        text: "We spent some time around Calgary after Skoki and I went to Kananaskis Country for the first time which was an amazing drive.",
      },
      [{ media: "20230909Img5900" }, { media: "20230909Img5901" }],
      {
        text: "On the way out of Kananaskis one night, we randomly decided to pull over and try night photography, both of us had no idea what we were doing but I think we did a pretty good job!",
      },
      [{ media: "20230909Dsc09590" }, { media: "20230909Dsc09601" }],
      [
        {
          text: "We did a quick trip down to Waterton National Park. I had never seen wing surfing before, so it was fun seeing these people out on the lake. ",
        },
        { media: "20230909Img5929" },
      ],
      {
        text: "We were very optimistic that we could get up early for a sunrise hike, but we didn't get started quite as early as we wanted. We still got to the top before the sunrise was in full force. This hike was short but we went so fast it definitely messed me up, it took a bit to recover to appreciate the view :D",
      },
      [
        { media: "20230909Img5931" },
        { media: "20230909Img5933" },
        { media: "20230909Img5952" },
      ],
      [
        { media: "20230909Img5941" },
        { media: "20230909Img5956" },
        { media: "20230909Img5957" },
      ],
      { text: "The campground was pretty nice, right on the lake." },
      [{ media: "20230909Img5960" }, { media: "20230909Img5982" }],
      { text: "There was also an absolutely bonkers sunset that night." },
      [{ media: "20230909Img5966" }, { media: "20230909Img5975" }],
      {
        text: "Rachel went off to drive north and visit her sister, and I took the slower route up some public land and camped looking out over a george with the sound of a waterfall. It was beautiful, but I had to scavenge some rocks to level the van because I don't have levelers yet.",
      },
      [{ media: "20230909Img5985" }, { media: "20230909Img5987" }],

      {
        text: "The rest of the drive was also pretty nice, not pictured is about a million cows on the road, definitely had to be alert coming around corners :)",
      },
      [{ media: "20230909Img5993" }, { media: "20230909Img5998" }],
    ],
  },
  {
    published: true,
    title: "Larches",
    createdDate: "June 18, 2024",
    eventDate: ["September 23, 2023", "September 23, 2023"],
    chunks: [
      // trailhead
      latLngFromUrl(
        `https://www.google.com/maps/place/Chester+Lake+Trail+Head/@50.7938065,-115.3052546,15.7z/data=!4m6!3m5!1s0x537092ed9d3baa99:0x66c01807d261aaf6!8m2!3d50.7906529!4d-115.3018653!16s%2Fg%2F11b_1nz0xr?entry=ttu`
      ),
      {
        text: "I'm in Calgary in Larch season, so I've been told I need to go see them. I messed up with my camera so my photos aren't great but this hike was lovely! I'm not use to this type of hike being this busy, it was so crowded with people coming out for the larches!",
      },
      [{ media: "20230923Dsc09650" }, { media: "20230923Img6020" }],
      [{ media: "20230923Img6014" }, { media: "20230923Img6015" }],
      [{ media: "20230923Img6008" }, { media: "20230923Dsc09671" }],
      [
        { media: "20230923Img6026" },
        { media: "20230923Img6025" },
        { media: "20230923Img6029" },
      ],
    ],
  },
  {
    published: true,
    title: "The Bugaboos",
    createdDate: "June 25, 2024",
    eventDate: ["September 29, 2023", "September 30, 2023"],
    chunks: [
      // trailhead
      latLngFromUrl(
        `https://www.google.com/maps/place/Conrad+Kain+Hut+Trailhead/@50.746167,-116.7268518,200m/data=!3m1!1e3!4m10!1m2!2m1!1sBugaboo+Provincial+Park+trailhead!3m6!1s0x537a3163bb1e1e59:0x76cb289d8dd0f348!8m2!3d50.745994!4d-116.7263761!15sCiFCdWdhYm9vIFByb3ZpbmNpYWwgUGFyayB0cmFpbGhlYWRaIyIhYnVnYWJvbyBwcm92aW5jaWFsIHBhcmsgdHJhaWxoZWFkkgELaGlraW5nX2FyZWGaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVTmFja3hMUkRWUlJSQULgAQA!16s%2Fg%2F11h_d4h2n8?entry=ttu`
      ),

      {
        text: `
        In Waterton, Rachel noticed that the last night of the season at a backcountry
        hut in the bugaboos was still available, So we obviously booked it!
        <br/><br/>
        After a pretty long drive down a nice and bumpy road, we arrived at the trailhead and 
        put chicken wire around the van. The trailhead is known for porcupines chewing wires and hoses under 
        the van so we didn't want to come back tomorrow to a van that didn't start.
        `,
      },
      [
        { media: "20230929Img6044" },
        { media: "2023092920230929121325" },
        { media: "20230929Img6046" },
      ],
      {
        text: "It started to snow on the way up, and was fairly low visibility. We were hoping that by the time we got to the hut it would clear and we would get a view of the spires and glaciers, what the bugaboos are famous for.",
      },
      [
        { media: "2023092920230929142716" },
        { media: "2023092920230929150652" },
        { media: "2023092920230929150910" },
      ],
      [{ media: "20230929Img6064" }, { media: "2023092920230930072311" }],
      [
        {
          text: "We were pretty lucky, and the weather did clear when we got to the hut! We even got a view of the fog rolling through the valley and covering the spires and glaciers.",
        },
        { media: "2023092920230929182822" },
      ],
      [{ media: "20230929Img6072" }, { media: "20230929Img7580" }],

      [
        {
          text: `The hut we were staying in was pretty neat, and had an amazing setting! 
            It was off grid but had running water, gas stoves, and electric lights.
          Even though the weather wasn't great, it was still a full house because it was the last night of the season, so it ended up being pretty warm sleeping because of all the body heat.`,
        },
        { media: "20230929Img7581Jpg" },
      ],
      { media: "20230929Dsc09760Pano", extraStyles: { maxWidth: undefined } },

      [{ media: "20230929Img7575" }, { media: "2023092920230929182107" }],
      [
        {
          text: "The bathrooms were also pretty spectacular, the first picture was one of the outhouses that was built into a rock, the second picture is the view from the outhouse closer to the hut.",
        },
        { media: "20230929Dsc09734" },
        { media: "2023092920230930072040" },
      ],

      [
        {
          text: `
            We were saving loads of weight not needing to bring a tent, sleeping pad, dishes, or stove
            which meant we could bring much more fun meals that we normally wouldn't want to carry, one of which was pancakes for breakfast :)`,
        },
        { media: "2023092920230930080951" },
      ],
      {
        text: `
          We got up the next day, and our luck had ended. 
          It snowed for the rest of the day. 
          We still did the little hike up the rest of the mountain to the lake in case we got a good view, 
          but as you can see here the view was not great.`,
      },
      [
        { media: "20230929Img6080" },
        { media: "20230929Img6078" },
        { media: "20230929Dsc09777" },
      ],

      [
        {
          text: `
            The first half of the hike down was pretty snowy, 
            but the second half we got to take off our spikes because we were low enough that the trail was clear. 
            This picture is part of the trail, if you look closely you can see the ledge and chain used to walk up this rock face.`,
        },
        { media: "20230929Img6081" },
      ],
    ],
  },
  {
    published: true,
    title: "Glacier National Park",
    createdDate: "June 26, 2024",
    eventDate: ["October 01, 2023", "October 04, 2023"],
    chunks: [
      // cleaning in golden
      latLngFromUrl(
        `https://www.google.com/maps/place/51%C2%B018'02.7%22N+116%C2%B057'13.9%22W/@51.30075,-116.9564349,715m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d51.30075!4d-116.95386?entry=ttu`
      ),
      // hermit meadows trailhead
      latLngFromUrl(
        `https://www.google.com/maps/place/Hermit+Trailhead/@51.3201528,-117.5316661,2629m/data=!3m1!1e3!4m12!1m5!3m4!2zNTHCsDE5JzA5LjQiTiAxMTfCsDMxJzExLjQiVw!8m2!3d51.31929!4d-117.51983!3m5!1s0x5379a871abf0af23:0xf7d8da9a3feade2c!8m2!3d51.3140023!4d-117.5128526!16s%2Fg%2F11gds6pnkn?entry=ttu`
      ),

      {
        text: "After the Bugaboos we both needed to reorganize and clean. We stopped in Golden and emptied out our garages. We ended up sweeping out some Dempster dust that feels like it may never be fully cleaned up 🤣",
      },
      [{ media: "20231001Img6084" }, { media: "20231001Img6087" }],
      {
        text: `
          After we were done cleaning, we left Golden and headed towards Revelstoke. Along the way we stopped to do a hike in Glacier National Park. 
          We did a hike to Hermit Meadows which is a backcountry campground, but we did it as a day hike. Look at all the mountain!
        `,
      },
      [{ media: "20231001Dsc09782Pano", extraStyles: { maxWidth: undefined } }],
      [
        {
          text: "We also had bought an air fryer recently, so our new experiment was pizza pops as trail snacks. They did not disappoint.",
        },
        { media: "20231001Img6095" },
        { media: "20231001Img6106" },
      ],
      [{ media: "20231001Img6096" }, { media: "20231001Img6103" }],
    ],
  },
  {
    published: true,
    title: "Revelstoke",
    createdDate: "June 27, 2024",
    eventDate: ["October 05, 2023", "October 13, 2023"],
    chunks: [
      // revelstoke burgers
      latLngFromUrl(
        `https://www.google.com/maps/place/Big+Eddy+Pub+and+Liquor+Store/@50.9979928,-118.2303736,80m/data=!3m1!1e3!4m12!1m5!3m4!2zNTDCsDU5JzUzLjAiTiAxMTjCsDEzJzQ5LjAiVw!8m2!3d50.99806!4d-118.23027!3m5!1s0x537936e5a0153bcb:0xfa54d5300580f1cc!8m2!3d50.9980239!4d-118.2303314!16s%2Fg%2F1tf9shpm?entry=ttu`
      ),
      // boondocking revelstoke
      latLngFromUrl(
        `https://www.google.com/maps/place/51%C2%B014'03.1%22N+118%C2%B013'04.0%22W/@51.2340423,-118.2180279,105m/data=!3m1!1e3!4m4!3m3!8m2!3d51.23418!4d-118.21778?entry=ttu`
      ),
      // snow forest campground
      latLngFromUrl(
        `https://www.google.com/maps/place/Snowforest+Campground/@51.01761,-118.2064449,719m/data=!3m1!1e3!4m12!1m5!3m4!2zNTHCsDAxJzAzLjQiTiAxMTjCsDEyJzEzLjkiVw!8m2!3d51.01761!4d-118.20387!3m5!1s0x537937024615d63f:0x2a6c9fdf9d3e5928!8m2!3d51.0167083!4d-118.2032928!16s%2Fg%2F11jjks0cqp?entry=ttu`
      ),
      // inspiration woods trailhead
      latLngFromUrl(
        `https://www.google.com/maps/place/Inspiration+Woods+Trail/@51.0248544,-118.2050753,1082m/data=!3m1!1e3!4m12!1m5!3m4!2zNTHCsDAxJzQ5LjgiTiAxMTjCsDEyJzAwLjQiVw!8m2!3d51.0305!4d-118.2001!3m5!1s0x537937004744581f:0xe556b41501fba3e5!8m2!3d51.024306!4d-118.2034508!16s%2Fg%2F11y4d23mj5?entry=ttu`
      ),

      // [
      //   {
      //     text: "We got to Revelstoke and it turned out to be during a burger festival. These were not part of the burger festival, but they were pretty good :P",
      //   },
      //   { media: "20231005Img6117" },
      // ],
      {
        text: `
          We had originally planned on trying mountain biking when we got to Revelstoke, but it turned out we were here too late and all the classes were done for the season. 
          We still wanted to sit still for a bit, so we found a place on a lake to stay and it was pretty gorgeous. 
          The one downside was Rachel's van did get its first mouse here. We got a trap and set it up to catch it, we didn't have luck catching it in the trap we bought, but we did wake up to the sound of the mouse trying to jump out of the old dog food bag we had left out to be thrown out in the morning.
        `,
      },
      [{ media: "20231005Dji0123" }, { media: "20231005Dji0124" }],
      [
        {
          media: "20231005Dji0127",
          extraStyles: { maxWidth: undefined },
        },
        { media: "20231005Img6129" },
      ],
      [
        { media: "20231005Img6121" },
        { media: "20231005Img6166" },
        { media: "20231005Img7702" },
      ],
      [{ media: "20231005Img6130" }, { media: "20231005Img6139" }],

      [
        {
          text: "We made a thanksgiving dinner in the van, and it turned out pretty amazing. Whole roast chicken, breakfast sausages (this is a tradition is Rachel's family, so we had to continue it), roasted mini potatoes and carrots, stuffing, and gravy!",
        },
        { media: "20231005Img6148" },
        { media: "20231005Img6150" },
      ],

      [
        {
          text: `Last time I was in Revelstoke I wanted to go into the park but it was closed because it was too early in the season, this time the road was closed because it was too late in the season, so we still couldn't go that far into the park.
           There were a few trails at the base that were still open, it was short so Rachel convinced me to do a post thanksgiving turkey trot and trail run it.`,
        },
        { media: "20231005Img6154" },
      ],
      // [
      //   { text: "I" },
      //   { media: "20231005Img6160" },
      //   //  { media: "20231005Img6161" }
      // ],
      // [
      //   { media: "20231005Img6170" },
      //   { media: "20231005Img6172" },
      //   { media: "20231005Img6174" },
      // ],
    ],
  },
  {
    published: true,
    title: "Wells Gray 2",
    createdDate: "September 25, 2024",
    eventDate: ["October 14, 2023", "October 15, 2023"],
    chunks: [
      // high five diner
      latLngFromUrl(
        `https://www.google.com/maps/place/High+Five+Diner/@51.42521,-120.2067649,713m/data=!3m1!1e3!4m12!1m5!3m4!2zNTHCsDI1JzMwLjgiTiAxMjDCsDEyJzE1LjEiVw!8m2!3d51.42521!4d-120.20419!3m5!1s0x537f81e6d2df4cb1:0xf272cb7f297e1ddd!8m2!3d51.4250951!4d-120.2041821!16s%2Fg%2F11b5wlx5n7?entry=ttu`
      ),
      // campground on the way to wells gray
      latLngFromUrl(
        `https://www.google.com/maps/place/Clearwater+Valley+Resort/@51.6523837,-120.042477,474m/data=!3m1!1e3!4m15!1m5!3m4!2zNTHCsDM5JzA4LjgiTiAxMjDCsDAyJzI2LjgiVw!8m2!3d51.65243!4d-120.04079!3m8!1s0x5381dbc432cca8df:0x9c22ccc6380a05f8!5m2!4m1!1i2!8m2!3d51.6527758!4d-120.0388925!16s%2Fg%2F1tftkwz4?entry=ttu`
      ),
      // helmcken falls
      latLngFromUrl(
        `https://www.google.com/maps/place/Helmcken+Falls/@51.9542649,-120.1838764,3153m/data=!3m1!1e3!4m12!1m5!3m4!2zNTHCsDQ1JzU1LjAiTiAxMjDCsDAwJzU5LjUiVw!8m2!3d51.76527!4d-120.01653!3m5!1s0x538195f7ef70a9c3:0xaf0dee3478f6da68!8m2!3d51.9539703!4d-120.1770295!16zL20vMGNwNjV2?entry=ttu`
      ),
      // dawson falls
      latLngFromUrl(
        `https://www.google.com/maps/place/Dawson+Falls/@51.9613884,-120.1402358,3230m/data=!3m1!1e3!4m12!1m5!3m4!2zNTHCsDQ1JzU1LjAiTiAxMjDCsDAwJzU5LjUiVw!8m2!3d51.76527!4d-120.01653!3m5!1s0x538195c8a0798809:0xd6b97e2ee81163a8!8m2!3d51.9650816!4d-120.1235478!16s%2Fm%2F047p63k?entry=ttu`
      ),
      // moul falls
      latLngFromUrl(
        `https://www.google.com/maps/place/Moul+Falls/@51.8347557,-120.0498798,17z/data=!4m15!1m8!3m7!1s0x5381c1e442af2a45:0x48bb8166cd3eaec1!2sMoul+Falls!8m2!3d51.8347557!4d-120.0498798!10e5!16s%2Fm%2F0gtv4lk!3m5!1s0x5381c1e442af2a45:0x48bb8166cd3eaec1!8m2!3d51.8347557!4d-120.0498798!16s%2Fm%2F0gtv4lk?entry=ttu`
      ),
      // spahats galls
      latLngFromUrl(
        `https://www.google.com/maps/place/Spahats+Creek+Falls/@51.7370948,-120.0165031,733m/data=!3m1!1e3!4m6!3m5!1s0x5381dcd336a2f963:0x66d27aff8d006d62!8m2!3d51.7370948!4d-120.0142822!16s%2Fm%2F03ql6nk?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D`
      ),

      [
        {
          text: "After leaving Revelstoke and spending a bit of time in Kamloops, we went north to visit Wells Gray Provincial Park. First stop had to be High Five Diner for some schnitzel and perogies.",
        },
        { media: "20231014Img6177" },
      ],

      {
        text: "The next day we did the round of waterfalls. (First image below is Helmcken Falls, second is Dawson Falls, third is Spahats Creek Falls)",
      },
      [
        { media: "20231014Img6184" },
        { media: "20231014Img6189" },
        { media: "2023101420231015165017" },
      ],

      {
        text: `
        Similar to last time I was here, Moul Falls was the star, because you can get really close, and walk behind it. 
         This time it wasn't spring, so walking behind the falls was a little less sketchy and we only got a little wet instead of completely drenched.`,
      },
      [
        { media: "2023101420231015154657" },
        { media: "20231014Img6205" },
        { media: "20231014Img6212" },
      ],
    ],
  },
  {
    published: true,
    title: "Highway 99 and Squamish",
    createdDate: "October 01, 2024",
    eventDate: ["October 16, 2023", "November 23, 2023"],
    chunks: [
      latLngFromUrl(
        `https://www.google.com/maps/place/Juniper+Beach+Camp+Ground/@50.7841092,-121.0913601,2993m/data=!3m1!1e3!4m14!1m7!3m6!1s0x54803c9cc88dedc5:0x8cb25ae563ee01d5!2sJuniper+Beach+Provincial+Park!8m2!3d50.7841097!4d-121.0810604!16zL20vMGJ0ZGp5!3m5!1s0x54803bc740985a91:0x116c83b5550b8c14!8m2!3d50.7790227!4d-121.079781!16s%2Fg%2F11shy5yhby?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/50%C2%B040'05.7%22N+121%C2%B059'11.4%22W/@50.6675932,-121.9892599,599m/data=!3m1!1e3!4m4!3m3!8m2!3d50.66825!4d-121.9865?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/U+turn+view+point/@50.6603827,-121.9876731,335m/data=!3m1!1e3!4m12!1m5!3m4!2zNTDCsDM5JzM2LjkiTiAxMjHCsDU5JzExLjUiVw!8m2!3d50.66024!4d-121.98653!3m5!1s0x54808bfeee424a71:0xe6a45ba9c11a6cc1!8m2!3d50.6602004!4d-121.9861254!16s%2Fg%2F11kpmcq8dn?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D`
      ),
      latLngFromUrl(
        `https://google.com/maps/place/Cinnamon+Recreation+Site/@50.6075068,-122.1616029,11893m/data=!3m1!1e3!4m12!1m5!3m4!2zNTDCsDAyJzU3LjgiTiAxMjPCsDA3JzU4LjYiVw!8m2!3d50.04939!4d-123.13294!3m5!1s0x5480927fdb8e4c1f:0x30ad7262847e6ecf!8m2!3d50.6128398!4d-122.1055928!16s%2Fg%2F11c553_q2b?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Whistler+RV+Park+and+Campgrounds/@50.0469157,-123.1369057,1504m/data=!3m1!1e3!4m12!1m5!3m4!2zNTDCsDAyJzU3LjgiTiAxMjPCsDA3JzU4LjYiVw!8m2!3d50.04939!4d-123.13294!3m5!1s0x548719e8647063f5:0xc3a13d81316a7374!8m2!3d50.045052!4d-123.131145!16s%2Fg%2F1tfrrlzp?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B037'12.6%22N+123%C2%B012'18.9%22W/@49.6201695,-123.2155497,3067m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d49.62017!4d-123.20525?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B046'21.3%22N+123%C2%B006'06.0%22W/@49.7725895,-123.1119697,3057m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d49.77259!4d-123.10167?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B042'13.9%22N+123%C2%B008'55.8%22W/@49.7038495,-123.1591397,3062m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d49.70385!4d-123.14884?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/49%C2%B015'27.8%22N+122%C2%B050'14.4%22W/@49.2577295,-122.8476397,3090m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d49.25773!4d-122.83734?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D`
      ),
      latLngFromUrl(
        `https://www.google.com/maps/place/Cat+Lake+Recreation+Site+-+Squamish/@49.7988953,-123.1132146,1445m/data=!3m1!1e3!4m14!1m7!3m6!1s0x5486fc718fe9fbf7:0x5b9f12b5fac9967!2sCat+Lake!8m2!3d49.799229!4d-123.1097372!16s%2Fg%2F11clgcy2fk!3m5!1s0x5486fd9fd0e700c1:0xf1451618cc797051!8m2!3d49.8017438!4d-123.1098292!16s%2Fg%2F11rw7jzsgb?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D`
      ),

      [
        {
          text: `After Wells Grey, the plan was to drive to Squamish so we went back to Kamloops, got some groceries, and headed out towards highway 99. 
            Our first stop was to stay a few nights and work at this campground, which was free because it wasn't peak season anymore.`,
        },
        { media: "20231016Img6232" },
      ],
      {
        text: `
          We stopped at the Seton Lake Lookout where you can hike up a little hill to look over the road and lake.

          I brought my drone but when I went to take off I flew it straight into a tree, and it got stuck.
          It wasn't a tree we could climb, so we had to throw rocks to try and dislodge it. 
          Rachel managed to hit and break one of the branches that it was stuck on, and it tumbled out of the tree and down a cliff.
          After an adventurous climb down, luckily the drone only really had minor damage to one blade.`,
      },
      [{ media: "20231016Img6260" }, { media: "20231016Dji0134" }],
      {
        text: "The rest of highway 99 was absolutely beautiful! We don't have a lot of photos, but just driving in and out of gorgeous treed valleys, with mountains all around was stunning. We got to Whistler, checked out a local bakery, and decided to stay in the campground to do some chores. We watched the sunset with our baked goods and coffee.",
      },
      [{ media: "20231016Img6264" }, { media: "20231016Img6268" }],
      [
        {
          text: "When we did laundry, it got tangled more then I have ever seen laundry get tangled before. This ball was twisted to the point of being hard, and took me over 20 minutes to get unwound.",
        },
        { media: "20231016Img6273", overrideWidth: 35 },
      ],
      [
        {
          text: 'A big thing Rachel and I wanted to do this fall was to try downhill mountain biking. We had tried to go in Revelstoke, but everything was shut down. Lucky for us, Squamish had 1 good weekend left for us! The weather was perfect. My first few runs down I may have yelled "this is terrifying!" a few times, but we made it to the bottom each time, and had an amazing day. So fun!',
        },
        { media: "20231016Img6286" },
        {
          text: "Next up for firsts (ish) was getting back into rock climbing! I hadn't rock climbed since going to the boiler room in Kingston when I was a teenager. We got a membership at the gym and it was also so fun! Squamish is definitely more of an outdoor climbing destination, so the gym was pretty small and the routes were fairly hard, but there were enough options to keep us entertained!",
        },
        { media: "20231016Ca3E38Ff6A1E4746921983Cdc7C7F0Ce" },
      ],
      [
        {
          text: "A last first for the fall, our feet were tired so we got pedicures!",
        },
        { media: "20231016Img6307" },
        {
          text: "Best coin machine ever, even if the laundromat was the most expensive ever :P",
        },
        { media: "20231016Img6308" },
      ],

      [
        {
          text: "Keeping our batteries topped up during Squamish fall rainy weather was an ongoing battle, which I definitely lost here. I actually made it to 0% 🙈",
        },
        { media: "20231016Img6309" },
        { media: "20231016Img7891" },
      ],
      [
        {
          text: "It was a pretty low key fall and start to winter, but we had some amazing views, fun times, and new experiences!",
        },
        { media: "20231016Img6367" },
      ],
    ],
  },
  {
    published: false,
    title: "Across canada",
    createdDate: "June 22, 2024",
    eventDate: ["November 23, 2023", "November 23, 2023"],
    chunks: [],
  },
  {
    published: false,
    title: "Ontario part 1",
    createdDate: "June 22, 2024",
    eventDate: ["November 24, 2023", "November 24, 2023"],
    chunks: [],
  },
  {
    published: false,
    title: "baha",
    createdDate: "June 22, 2024",
    eventDate: ["November 25, 2023", "November 25, 2023"],
    chunks: [],
  },
  {
    published: false,
    title: "Ontario part 2",
    createdDate: "June 22, 2024",
    eventDate: ["November 26, 2023", "November 26, 2023"],
    chunks: [],
  },
  {
    published: false,
    title: "Back west",
    createdDate: "June 22, 2024",
    eventDate: ["November 27, 2023", "November 27, 2023"],
    chunks: [],
  },
  {
    published: false,
    title: "Calgary with foot",
    createdDate: "June 22, 2024",
    eventDate: ["November 28, 2023", "November 28, 2023"],
    chunks: [],
  },
  {
    published: false,
    title: "Calgary without foot",
    createdDate: "June 22, 2024",
    eventDate: ["November 29, 2023", "November 29, 2023"],
    chunks: [],
  },
];

const whatToShow = "next" as
  | "all"
  | "published"
  | "next"
  | "next-only"
  | "none";
const nextCount = 1;
let foundNext = 0;
export const publishedBlogs = allBlogs
  .filter((blog) => {
    if (process.env.NODE_ENV === "development") {
      if (whatToShow === "all") {
        return true;
      } else if (whatToShow === "next") {
        if (!blog.published && foundNext < nextCount) {
          foundNext += 1;
          return true;
        }
      } else if (whatToShow === "next-only") {
        if (blog.published) {
          return false;
        } else if (foundNext < nextCount) {
          foundNext += 1;
          return true;
        }
      } else if (whatToShow === "none") {
        return false;
      }
    }

    return blog.published;
  })
  .reverse();

export function getPostHash({ eventDate }: BlogItem) {
  return (Array.isArray(eventDate) ? eventDate : [eventDate])
    .map((date) =>
      typeof date === "symbol"
        ? "intro"
        : date.toLowerCase().replace(/[ ,]+/g, "-")
    )
    .join("-");
}
export const blogCount = { blogCount: publishedBlogs.length };
